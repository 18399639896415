import { AccordionItem, Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { withLoadingHandler } from 'components/integration-wrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BundledProductsUi } from './ui/BundledProductsUi';
import { useBundledProducts } from './useBundledProducts';

const BundledProductsWithHandlers = withLoadingHandler(BundledProductsUi);

export type BundledProductsProps = {
    contractId: string;
};

export const BundledProducts: React.FC<BundledProductsProps> = ({ contractId }) => {
    const { t } = useTranslation('bundled-products');
    const { t: contractT } = useTranslation('contracts');

    const { data, isLoading, loadingError } = useBundledProducts(contractId);

    return (
        <AccordionItem title={t('headline')}>
            {!!loadingError ? (
                <Notification
                    status={NotificationStatus.error}
                    headline={contractT('contract-details-error.headline')}
                    text={contractT('contract-details-error.text')}
                />
            ) : (
                <BundledProductsWithHandlers
                    isLoading={isLoading}
                    bundledProducts={data?.products}
                    contractType={data?.contractType}
                />
            )}
        </AccordionItem>
    );
};
