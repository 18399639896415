import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const registrationStep1FormValidationSchema = (
    t: TFunction,
    type: 'private' | 'business',
    withCustomerId: boolean,
) => {
    const isPrivateForm = type === 'private';
    const identityCodeRegEx = isPrivateForm
        ? RegExp(
              '^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})|([0-9]{11})$',
          )
        : RegExp('^[0-9]{11}$');
    const identityCodeLength = isPrivateForm ? 16 : 11;

    return Yup.object().shape({
        name: Yup.string()
            .trim()
            .required(t(`form.errors.${type}.name-missing`)),
        surname: isPrivateForm
            ? Yup.string()
                  .trim()
                  .required(t('form.errors.surname-missing'))
            : Yup.mixed().notRequired(),
        identityCode: Yup.string()
            .trim()
            .required(t(`form.errors.${type}.identity-code.missing`))
            .length(identityCodeLength, t(`form.errors.${type}.identity-code.validation`))
            .matches(identityCodeRegEx, t(`form.errors.${type}.identity-code.validation`)),
        customerId: withCustomerId
            ? Yup.string()
                  .required(t('form.errors.customer-id.missing'))
                  .matches(RegExp('^[0-9]{5,7}$'), t('form.errors.customer-id.validation'))
            : Yup.mixed().notRequired(),
        contractNumber: !withCustomerId
            ? Yup.string()
                  .required(t('form.errors.contract-number.missing'))
                  .matches(RegExp('^[0-9]{6,7}$'), t('form.errors.contract-number.validation'))
            : Yup.mixed().notRequired(),
        confirmPrivacyPolicy: Yup.bool().oneOf([true], t('form.errors.privacy-policy')),
        confirmLegalNotice: Yup.bool().oneOf([true], t('form.errors.legal-notice')),
    });
};

export const registrationStep2FormValidationSchema = (t: TFunction) => {
    return Yup.object().shape({
        code: Yup.string()
            .required(t(`form.errors.code.missing`))
            .matches(RegExp('^[0-9]*$'), t('form.errors.code.matches'))
            .length(6, t(`form.errors.code.length`)),
    });
};
