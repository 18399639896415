import React from 'react';
import { useContract, useContracts } from 'components/contracts';
import { PostboxUi } from './ui/PostboxUi';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { usePostboxDocuments } from './usePostboxDocuments';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';

const PostboxWithHandlers = withLoadingAndNoConnectionHandler(PostboxUi);

export const Postbox: React.FC<{ contractId?: string }> = ({ contractId }) => {
    const { data: contracts, isLoading, loadingError } = useContracts();
    const { data: contract } = useContract(contractId || '');
    useAnalyticsPageViewTracker('postbox', !loadingError);
    const {
        data: postboxDocuments,
        isLoading: postboxDocumentsLoading,
        loadingError: postboxDocumentsLoadingError,
    } = usePostboxDocuments();

    return (
        <div>
            <PostboxWithHandlers
                isLoading={isLoading || postboxDocumentsLoading}
                contracts={contracts || []}
                hasError={!!loadingError}
                postboxDataError={!!postboxDocumentsLoadingError}
                postboxDocuments={postboxDocuments || []}
                contractNumber={contract?.contractNumber}
            />
        </div>
    );
};
