import React from 'react';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../../components/page-header/PageHeader';
import { useContract } from '../../components/contracts';
import { withLoadingAndNoConnectionHandler } from '../../components/integration-wrapper';
import { IrapCalculationPageUi } from './ui/IrapCalculationPageUi';

const IrapCalculationWithHandlers = withLoadingAndNoConnectionHandler(IrapCalculationPageUi);

export const IrapCalculationPage: React.FC = () => {
    const { t } = useTranslation('irap-calculation');
    const { contractId } = useParams();
    const { data: contract, isLoading, loadingError } = useContract(contractId);

    return (
        <ContentSection pageWrapSize="medium">
            <PageHeader title={t('headline')} />
            <IrapCalculationWithHandlers isLoading={isLoading} contract={contract} hasError={!!loadingError} />
        </ContentSection>
    );
};
