import { useAuthentication } from '@cp-shared-8/frontend-ui';
import { idpHint } from 'config';
import { useTranslation } from 'react-i18next';
import { dashboardPagePath } from '../components/navigation/paths';
import { getAbsoluteUrlWithParams } from '../utils/urlSearchParams';

export function useLogin(pathFn: () => string = dashboardPagePath): () => Promise<void> {
    const { login } = useAuthentication();
    const { i18n } = useTranslation();
    const locale = i18n.languages[0];

    return (): Promise<void> =>
        login({
            redirectUri: getAbsoluteUrlWithParams(pathFn()),
            locale,
            idpHint,
            prompt: 'login',
        });
}
