import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import base64ToBlob from 'b64-to-blob';
import { Button } from '@vwfs-bronson/bronson-react';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { saveAs as downloadFileAs } from 'file-saver';
import { CpDataApi } from '../../../../cp-xhr';
import { UnpaidDetailsPdf } from '../../../../common';

export type DownloadLinkProps = {
    setDownloadError: Function;
    downloadLink: string;
};
export const DownloadLink: React.FC<DownloadLinkProps> = ({ downloadLink, setDownloadError }) => {
    const { t } = useTranslation('unpaid-alert');
    const [isDownloading, setIsDownloading] = useState(false);

    const downloadFile = (): void => {
        setIsDownloading(true);
        CpDataApi.get(downloadLink)
            .then(response => {
                const { filename, base64 }: UnpaidDetailsPdf = response.data;
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(base64, pdfContentType);
                downloadFileAs(pdfBlob, filename);
                setIsDownloading(false);
                setDownloadError(null);
            })
            .catch(() => {
                setIsDownloading(false);
                setDownloadError(true);
            });
    };

    return isDownloading ? (
        <Spinner small />
    ) : (
        <Button onClick={downloadFile} element="a" testId={'unpaidDetailsPdfDownloadLink'}>
            {t('link')}
        </Button>
    );
};
