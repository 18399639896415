import { CustomerType, Greeting } from '../../common';

export const GreetingWithPrivate: Greeting = {
    customerName: 'Bella',
    customerType: 'Private',
};
export const GreetingWithUndefinedCustomerName: Greeting = {
    customerName: undefined,
    customerType: 'LegalEntity',
};

const customGreeting = (newCustomerType: CustomerType): Greeting => {
    return { ...GreetingWithPrivate, customerType: newCustomerType };
};

export const GreetingWithBusinessMan: Greeting = customGreeting('BusinessMan');
export const GreetingWithLegalEntity: Greeting = customGreeting('LegalEntity');
export const GreetingWithPrivateBusinessMan: Greeting = customGreeting('PrivateBusinessMan');
