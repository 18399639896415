import React from 'react';
import moment from 'moment-timezone';

import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';

import { LegalFooter as LegalFooterUi } from '@cp-shared-8/frontend-ui';
import { useLegalFooter } from './useLegalFooter';

const LegalFooterWithHandlers = withLoadingAndNoConnectionHandler(LegalFooterUi);

export const LegalFooter: React.FC = () => {
    const { cmsContent: legalFooter, isLoading, loadingError } = useLegalFooter();

    const copyrightText = legalFooter?.copyrightText ? `${legalFooter?.copyrightText} ${moment().year()}` : undefined;
    return (
        <LegalFooterWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            footnotes={legalFooter?.footnotes}
            copyrightText={copyrightText}
            legalText={legalFooter?.legalText || ''}
            links={legalFooter?.links || []}
        />
    );
};
