import { EarlySettlement, EarlySettlementRequestResponse } from '../../common';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { useContract } from 'components/contracts';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { TotalEarlySettlementUi } from './ui/TotalEarlySettlementUi';
import { useTotalEarlySettlement } from './useTotalEarlySettlement';

const TotalEarlySettlementWithHandlers = withLoadingAndNoConnectionHandler(TotalEarlySettlementUi);

export const TotalEarlySettlement: React.FC<{ contractId: string }> = ({ contractId }) => {
    const { data, isLoading, loadingError } = useTotalEarlySettlement(contractId);
    const { data: contractData, isLoading: isLoadingContractData, loadingError: contractLoadingError } = useContract(
        contractId,
    );
    useAnalyticsPageViewTracker('earlySettlement', !!isLoading || !!contractData);

    const tesData = data as EarlySettlement;
    const tesStatus = data as EarlySettlementRequestResponse;

    return (
        <TotalEarlySettlementWithHandlers
            isLoading={isLoading || isLoadingContractData}
            earlySettlementData={tesData && tesData.product ? tesData : undefined}
            contractDetails={contractData}
            tesStatus={tesStatus && tesStatus.statusId ? tesStatus : undefined}
            hasError={!!loadingError || !!contractLoadingError}
            contractId={contractId}
        />
    );
};
