import { ErrorPage, getUnauthorizedErrorEndpoint } from '../../../common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<ErrorPage>({
    contentName: 'unauthorizedErrorContent',
    contentEndpoint: getUnauthorizedErrorEndpoint,
});

export default reducer;
export const fetchUnauthorizedError = fetchContent;
