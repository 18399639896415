import { FetchContractError, GeneralContractDetails } from '../../../common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import {
    AbstractDataState,
    hasFetchedSuccessfully,
    useGetContractBasedApiData,
} from '@cp-shared-8/frontend-integration';
import { useMemo } from 'react';
import { useContract } from '../useContract';
import { GeneralContractDetailsDataSelector } from './GeneralContractDetailsSelector';
import { fetchGeneralContractDetails } from './GeneralContractDetailsSlice';

export function useGeneralContractDetails(
    contractId: string,
): AbstractDataState<GeneralContractDetails, DefaultBusinessMarketApiErrorCode | FetchContractError> {
    const contractState = useContract(contractId);
    const generalContractDetailsState = useGetContractBasedApiData(
        contractId,
        fetchGeneralContractDetails,
        GeneralContractDetailsDataSelector,
        contractState.data?._links?.contractDetails,
    );
    return useMemo(
        () => ({
            isLoading: contractState.isLoading || generalContractDetailsState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && generalContractDetailsState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? generalContractDetailsState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: generalContractDetailsState.data,
            loadingError: hasFetchedSuccessfully(contractState)
                ? generalContractDetailsState.loadingError
                : contractState.loadingError,
        }),
        [contractState, generalContractDetailsState],
    );
}
