import React, { useEffect } from 'react';
import { ContactDetails, CustomerAddress, DashboardMarketingCards, Identification } from '../../../common';
import { MarketingCardComponent } from '../marketing-card-component';
import { useProductRouting } from '../useProductRouting';
import { ContractData } from '../utils';
import { withLoadingHandler } from 'components/integration-wrapper';

const BrandRelatedMarketingCardUiWithHandlers = withLoadingHandler(MarketingCardComponent);

type BrandRelatedMarketingCardProps = {
    contractData: ContractData;
    dashboardMarketingCard: DashboardMarketingCards;
    contactDetails?: ContactDetails;
    identification?: Identification;
    address?: CustomerAddress;
    testId?: string;
    className?: string;
    setProductRoutingLoaded: () => void;
};

export const BrandRelatedMarketingCard: React.FC<BrandRelatedMarketingCardProps> = ({
    contractData,
    dashboardMarketingCard,
    contactDetails,
    identification,
    address,
    testId,
    className,
    setProductRoutingLoaded,
}) => {
    const { data: productRouting, isLoading: productRoutingIsLoading } = useProductRouting(
        contractData,
        identification,
        contactDetails,
        address,
    );

    useEffect(() => {
        if (productRouting?.url) setProductRoutingLoaded();
    }, [productRouting, setProductRoutingLoaded]);

    const getContentKey = (): keyof Omit<DashboardMarketingCards, 'extendedWarranty' | 'googleRating'> | undefined => {
        switch (contractData?.brand.toLowerCase()) {
            case 'audi':
                return 'audiCare';
            case 'vw':
            case 'volkswagen':
                return 'vwCare';
            case 'skoda':
                return 'skodaCare';
            case 'vic volkswagen':
                return 'vicCare';
            case 'seat':
                return 'seatCare';
            default:
                return undefined;
        }
    };

    const cardContentKey = getContentKey();

    if (!cardContentKey) {
        return null;
    }

    return (
        <BrandRelatedMarketingCardUiWithHandlers
            isLoading={productRoutingIsLoading}
            content={dashboardMarketingCard[cardContentKey]}
            buttonUrl={productRouting?.url}
            testId={testId}
            className={className}
        />
    );
};
