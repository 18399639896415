import { createCmsContentSlice } from 'cms-integration';
import { ErrorsPage, getGenericErrorEndpoint } from '../../../common';

const { reducer, fetchContent } = createCmsContentSlice<ErrorsPage>({
    contentName: 'genericError',
    contentEndpoint: getGenericErrorEndpoint,
});

export default reducer;
export const fetchGenericError = fetchContent;
