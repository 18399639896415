import { ContactData } from '../../common';

export const inferEmail = (contactData: ContactData[]): string => {
    const privateEmail = contactData.find(item => item.customerType === 'Private')?.email;
    const businessEmail = contactData.find(
        item =>
            item.customerType === 'BusinessMan' ||
            item.customerType === 'LegalEntity' ||
            item.customerType === 'PrivateBusinessMan',
    )?.email;
    const anyEmail = contactData.find(
        item =>
            item.customerType === 'Private' ||
            item.customerType === 'BusinessMan' ||
            item.customerType === 'LegalEntity' ||
            item.customerType === 'PrivateBusinessMan',
    )?.email;
    if (privateEmail && businessEmail) {
        return privateEmail;
    } else {
        return anyEmail ?? '';
    }
};
