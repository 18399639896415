import { BundledProductDetails } from '../../../common';

export const BundledProductsWithMultipleEntriesAndAllValues: BundledProductDetails[] = [
    {
        serviceType: 'MAINTENANCE',
        serviceName: 'Some Service Name Value',
        companyProviderName: 'ALLIANZ',
        totalAmount: 595.44,
        installmentAmount: 11.44,
        numberOfInstallments: 12,
        serviceDuration: 36,
        startDate: '12/11/2016',
        endDate: '12/11/2022',
        serviceCode: 'MPO17',
    },
    {
        serviceType: 'OTHER_SERVICES',
        serviceName: 'Some Service Name Value',
        companyProviderName: 'COVEA',
        totalAmount: 595.44,
        installmentAmount: 11.44,
        numberOfInstallments: 12,
        serviceDuration: 36,
        startDate: '03/04/2018',
        endDate: '12/01/2022',
        serviceCode: 'M0113',
    },
    {
        serviceType: 'INSURANCE',
        serviceName: 'Some Service Name Value',
        companyProviderName: 'AXA',
        totalAmount: 595.44,
        installmentAmount: 11.44,
        numberOfInstallments: 12,
        serviceDuration: 36,
        startDate: '03/04/2013',
        endDate: '12/01/2018',
        serviceCode: 'GAP50',
    },
];
