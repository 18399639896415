import { EarlySettlement, formatAsCurrency, formatAsDate } from '../../../common';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import {
    Button,
    ButtonContainer,
    DescriptionListAccordion,
    Heading,
    InfoIcon,
    Layout,
} from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type TodaysValidQuoteProps = {
    handleBackButtonClick: () => void;
    handleContinueButtonClick: () => void;
    earlySettlementData: EarlySettlement;
};

export const TodaysValidQuote: React.FC<TodaysValidQuoteProps> = ({
    handleBackButtonClick,
    handleContinueButtonClick,
    earlySettlementData,
}) => {
    const { t } = useTranslation('total-early-settlement');

    const quoteDetailsCO = (
        <>
            <Layout.Item className="u-pt u-pb">
                <DescriptionListAccordion
                    headerSplit
                    totalLabel={
                        <>
                            {t('valid-quote.quote-details-co.total-settlement-amount.label')}
                            <InfoIcon icon="semantic-info" className="c-info-icon--fill">
                                {t('valid-quote.quote-details-co.total-settlement-amount.tooltip')}
                            </InfoIcon>
                        </>
                    }
                    totalValue={formatAsCurrency(earlySettlementData.totalSettlementAmount)}
                    totalLabelNote={
                        <>
                            {`${t('valid-quote.quote-details-co.settlement-expiration-date.label')}: ${formatAsDate(
                                formatCpDate(earlySettlementData.settlementExpirationDate).toCpDate(),
                            )}`}
                            <InfoIcon icon="semantic-info" className="c-info-icon--fill">
                                {t('valid-quote.quote-details-co.settlement-expiration-date.tooltip')}
                            </InfoIcon>
                        </>
                    }
                >
                    {earlySettlementData.outstandingTotal !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-co.outstanding-total.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.outstandingTotal)} +`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-co.outstanding-total.tooltip')}
                        />
                    )}
                    {earlySettlementData.nowScheduledInstallments !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-co.scheduled-installments.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.nowScheduledInstallments)} +`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-co.scheduled-installments.tooltip')}
                        />
                    )}
                    {earlySettlementData.latePaymentPenalties !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-co.late-payment-penalties.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.latePaymentPenalties)} +`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-co.late-payment-penalties.tooltip')}
                        />
                    )}
                    {earlySettlementData.recoveryFees !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-co.recovery-fees.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.recoveryFees)} +`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-co.recovery-fees.tooltip')}
                        />
                    )}
                    {earlySettlementData.terminationFeesCO !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-co.termination-fees.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.terminationFeesCO)} +`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-co.termination-fees.tooltip')}
                        />
                    )}
                    {earlySettlementData.advanceInterests !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-co.advance-interests.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.advanceInterests)} -`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-co.advance-interests.tooltip')}
                        />
                    )}
                    {earlySettlementData.advanceFees !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-co.advance-fees.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.advanceFees)} -`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-co.advance-fees.tooltip')}
                        />
                    )}
                    {earlySettlementData.collectionFees !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-co.collection-fees.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.collectionFees)} -`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-co.collection-fees.tooltip')}
                        />
                    )}
                    {earlySettlementData.upfrontPaidInstallments !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-co.upfront-paid-installments')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.upfrontPaidInstallments)} -`}
                        />
                    )}
                </DescriptionListAccordion>
            </Layout.Item>
            <Layout.Item className="u-pt u-pb">
                <p>{t('valid-quote.quote-details-le.info-text')}</p>
            </Layout.Item>
        </>
    );

    const quoteDetailsLE = (
        <>
            <Layout.Item className="u-pt u-pb">
                <Notification className="u-pb" status={NotificationStatus.info}>
                    <span dangerouslySetInnerHTML={{ __html: t('valid-quote.quote-details-le.info-alert') }}></span>
                </Notification>
            </Layout.Item>
            <Layout.Item className="u-pt u-pb">
                <DescriptionListAccordion
                    headerSplit
                    totalLabel={
                        <>
                            {t('valid-quote.quote-details-le.invoice-total-amount.label')}
                            <InfoIcon icon="semantic-info" className="c-info-icon--fill">
                                {t('valid-quote.quote-details-le.invoice-total-amount.tooltip')}
                            </InfoIcon>
                        </>
                    }
                    totalValue={formatAsCurrency(earlySettlementData.invoiceTotalAmount)}
                >
                    {earlySettlementData.settlementAmount !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-le.settlement-amount.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.settlementAmount)} +`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-le.settlement-amount.tooltip')}
                        />
                    )}
                    {earlySettlementData.ownershipTransferFee !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-le.ownership-transfer-fees.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.ownershipTransferFee)} +`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-le.ownership-transfer-fees.tooltip')}
                        />
                    )}
                    {earlySettlementData.vehicleOwnershipTax !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-le.vehicle-ownership-tax.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.vehicleOwnershipTax)} +`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-le.vehicle-ownership-tax.tooltip')}
                        />
                    )}
                    {earlySettlementData.taxPaymentFees !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-le.tax-payment-fees.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.taxPaymentFees)} +`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-le.tax-payment-fees.tooltip')}
                        />
                    )}
                    {earlySettlementData.superVehicleOwnershipTax !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-le.super-vehicle-ownership-tax')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.superVehicleOwnershipTax)} +`}
                        />
                    )}
                    {earlySettlementData.terminationFeesLE !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-le.termination-fees.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.terminationFeesLE)} +`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-le.termination-fees.tooltip')}
                        />
                    )}
                    {earlySettlementData.motorVeichleRegistrationFees !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-le.motor-vehicle-fees.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.motorVeichleRegistrationFees)} +`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-le.motor-vehicle-fees.tooltip')}
                        />
                    )}
                    {earlySettlementData.transferInvestigationFees !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-le.transfer-investigation-fees.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.transferInvestigationFees)} -`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-le.transfer-investigation-fees.tooltip')}
                        />
                    )}
                    {earlySettlementData.reversalCommissionsTakeOver !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-le.reversal-commissions-take-over.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.reversalCommissionsTakeOver)} -`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-le.reversal-commissions-take-over.tooltip')}
                        />
                    )}
                    {earlySettlementData.servicesAmount !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-le.services-amount.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.servicesAmount)} +`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-le.services-amount.tooltip')}
                        />
                    )}
                    {earlySettlementData.administrationCharge !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-le.administration-charge.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.administrationCharge)} +`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-le.administration-charge.tooltip')}
                        />
                    )}
                    <DescriptionListAccordion.Item nonCollapsable title="" titleSplit="" />
                    {earlySettlementData.vat !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-le.vat.label')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.vat)} +`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-le.vat.tooltip')}
                        />
                    )}
                </DescriptionListAccordion>
                <DescriptionListAccordion
                    headerSplit
                    totalLabel={
                        <>
                            {t('valid-quote.quote-details-le.total-settlement-amount.label')}
                            <InfoIcon icon="semantic-info" className="c-info-icon--fill">
                                {t('valid-quote.quote-details-le.total-settlement-amount.tooltip')}
                            </InfoIcon>
                        </>
                    }
                    totalValue={formatAsCurrency(earlySettlementData.totalSettlementAmount)}
                    totalLabelNote={
                        <>
                            {`${t('valid-quote.quote-details-le.settlement-expiration-date.label')}: ${formatAsDate(
                                formatCpDate(earlySettlementData.settlementExpirationDate).toCpDate(),
                            )}`}
                            <InfoIcon icon="semantic-info" className="c-info-icon--fill">
                                {t('valid-quote.quote-details-le.settlement-expiration-date.tooltip')}
                            </InfoIcon>
                        </>
                    }
                >
                    {earlySettlementData.unpaidInstallmentsNumber !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={
                                t('valid-quote.quote-details-le.unpaid-installments.n') +
                                earlySettlementData.unpaidInstallmentsNumber +
                                t('valid-quote.quote-details-le.unpaid-installments.label')
                            }
                            titleSplit={`${formatAsCurrency(earlySettlementData.totalUnpaidAmount)} +`}
                            icon="semantic-info"
                            tooltip={t('valid-quote.quote-details-le.unpaid-installments.tooltip')}
                        />
                    )}
                    {earlySettlementData.otherUnpaidDebts !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('valid-quote.quote-details-le.other-unpaid-debts')}
                            titleSplit={`${formatAsCurrency(earlySettlementData.otherUnpaidDebts)} +`}
                        />
                    )}
                    {earlySettlementData.latePaymentPenaltiesOnUnpaidInstallments !== undefined && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t(
                                'valid-quote.quote-details-le.late-payment-penalties-on-unpaid-installments.label',
                            )}
                            titleSplit={`${formatAsCurrency(
                                earlySettlementData.latePaymentPenaltiesOnUnpaidInstallments,
                            )} +`}
                            icon="semantic-info"
                            tooltip={t(
                                'valid-quote.quote-details-le.late-payment-penalties-on-unpaid-installments.tooltip',
                            )}
                        />
                    )}
                </DescriptionListAccordion>
            </Layout.Item>
            <Layout.Item className="u-pt u-pb">
                <p>{t('valid-quote.quote-details-le.info-text')}</p>
            </Layout.Item>
        </>
    );

    return (
        <>
            <Layout.Item>
                <Heading level={2}>{t('valid-quote.headline')}</Heading>
                {t('valid-quote.information')}
            </Layout.Item>
            {earlySettlementData.product === 'LE' ? quoteDetailsLE : quoteDetailsCO}
            <Layout.Item>
                <ButtonContainer center>
                    <Button secondary onClick={handleBackButtonClick} testId="dashboardButton" type="button">
                        {t('valid-quote.button.back')}
                    </Button>
                    <Button onClick={handleContinueButtonClick} testId="offlinePaymentButton" type="button">
                        {t('valid-quote.button.proceed')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
        </>
    );
};
