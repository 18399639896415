import { CPDate, formatCpDate } from '@cp-shared-8/common-utilities';
import moment from 'moment-timezone';
import 'moment/locale/it';
import numeral from 'numeral';
import 'numeral/locales/it';

export const IT_TIMEZONE = 'Europe/Rome';

moment.tz.setDefault(IT_TIMEZONE);

const locale = 'it';
export const numberFormat = '0,0';
export const numberFormatRoundedOne = '0,0.0';
export const numberFormatRoundedTwo = '0,0.00';
const percentageNumberFormat = '0,0.[0000000000]';
const numeralFormat = '0o';
const currencyFormat = '0,0.00';
const isoDateFormat = 'YYYY-MM-DD';
export const longDateFormat = 'DD/MM/YYYY';
export const dateFormatWithoutSeparators = 'YYYYMMDD';
export const undefinedReturnValueHyphen = '-';
export function changeLocale(locale: 'en' | 'it'): void {
    moment.locale(locale);
    numeral.locale(locale);
}

changeLocale(locale);

export function formatAsCurrency(value?: number, undefinedReturnValue = ''): string {
    return typeof value === 'number' ? `€ ${numeral(value).format(currencyFormat)}` : undefinedReturnValue;
}

export function formatAsDate(date?: CPDate): string {
    return formatCpDate(date).format(longDateFormat);
}

export function formatAsIsoDate(date?: CPDate): string {
    return formatCpDate(date).format(isoDateFormat);
}

export function parseDate(formattedDate: string, format: string = longDateFormat): CPDate {
    return formatCpDate(moment(formattedDate, format)).toCpDate();
}

export function formatAsNumber(value?: number, format: string = numberFormat): string {
    return typeof value === 'number' ? numeral(value).format(format) : '';
}

export function formatAsPercentageRate(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(percentageNumberFormat)} %` : '';
}

export function formatAsNumeral(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numeralFormat) : '';
}

export function formatAsDistance(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numberFormat) : '';
}

export function formatAsEmission(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(numberFormat)} g/km` : '';
}

export function formatAsIban(iban?: string): string {
    return (
        iban
            ?.replace(/[^\dA-Z]/g, '')
            .replace(/(.{4})/g, '$1 ')
            .trim() ?? ''
    );
}

export function formatAsFileSize(bytes: number, decimal: number): string {
    const unit = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (!bytes && '0 Bytes') || (bytes / Math.pow(1024, i)).toFixed(decimal) + ' ' + unit[i];
}
