export function getUserRegistryStatusEndpoint(): string {
    return '/user-registry-status';
}

export function getUserRegistryTransactionIdEndpoint(transactionId: string): string {
    return `/user-registry-transaction-id/${transactionId}`;
}

export function getDeleteUserRegistryTransactionIdsEndpoint(): string {
    return `/delete-user-registry-transaction-ids/`;
}
