import React from 'react';

import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';

import { ThirdPartyLicensesUi } from './ui';
import { useThirdPartyLicenses } from './useThirdPartyLicenses';
import licenses from './licenses-it-frontend-common.json';
import { License } from '../../common';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';

const ThirdPartyLicensesWithHandlers = withLoadingAndNoConnectionHandler(ThirdPartyLicensesUi);

export const ThirdPartyLicenses: React.FC = () => {
    const { cmsContent: thirdPartyLicenses, isLoading, loadingError } = useThirdPartyLicenses();
    const content: License[] = [...(licenses as License[])];

    useAnalyticsPageViewTracker('thirdPartyLicenses', !!thirdPartyLicenses);

    if (!thirdPartyLicenses) {
        return null;
    }

    return (
        <ThirdPartyLicensesWithHandlers
            isLoading={isLoading}
            thirdPartyLicenses={{
                ...thirdPartyLicenses,
                content,
            }}
            hasError={!!loadingError}
        />
    );
};
