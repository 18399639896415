import {
    getUserRegistryStatusEndpoint,
    getUserRegistryTransactionIdEndpoint,
    UserRegistryStatus,
    UserRegistryStatusEnum,
} from '../common';
import {
    AuthenticationProvider,
    AutoLogout,
    createAuthenticationComponent,
    createAuthorization,
    createDecoratedRoute,
    Spinner,
} from '@cp-shared-8/frontend-ui';
import { landingPagePath, registrationPagePath, timeOutPagePath } from 'components/navigation/paths';
import { CpDataApi } from 'cp-xhr';
import React, { FC } from 'react';
import { RedirectWithParams } from '../utils/redirectWithParams';
import { getUrlSearchParams } from '../utils/urlSearchParams';

const commonAuthorizationOptions = {
    onLoading: <Spinner center />,
    onError: <RedirectWithParams to={landingPagePath()} />,
};

const Authentication = createAuthenticationComponent({
    onMissingAuthentication: <RedirectWithParams to={landingPagePath()} />,
});

const {
    Authorization: UserRegistrationAuthorization,
    useAuthorizationFlow: useUserRegistrationFlow,
    useAuthorizationData: useUserRegistrationData,
    AuthorizationProvider: UserRegistrationProvider,
} = createAuthorization({
    displayName: 'UserRegistrationAuthorization',
    authorizationDataProvider: async () => {
        try {
            const { data: registrationData } = await CpDataApi.get<UserRegistryStatus>(getUserRegistryStatusEndpoint());
            return {
                isAuthorized: registrationData.status === UserRegistryStatusEnum.REGISTERED,
            };
        } catch (e) {
            return { isAuthorized: false };
        }
    },
    onMissingAuthorization: <RedirectWithParams to={registrationPagePath()} />,
    ...commonAuthorizationOptions,
});

type ProspectAuthorizationType = {
    transactionIds: string[];
};

const {
    Authorization: ProspectAuthorization,
    useAuthorizationFlow: useProspectFlow,
    useAuthorizationData: useProspectData,
    AuthorizationProvider: ProspectProvider,
} = createAuthorization<ProspectAuthorizationType>({
    displayName: 'ProspectAuthorization',
    authorizationDataProvider: async () => {
        try {
            const promise = CpDataApi.get<UserRegistryStatus>(getUserRegistryStatusEndpoint());
            const storedIds = await promise.then(response => response.data.transactionIds);
            const urlTransactionId = getUrlSearchParams().get('transactionId');
            if (urlTransactionId && !storedIds.includes(urlTransactionId)) {
                await CpDataApi.put(getUserRegistryTransactionIdEndpoint(urlTransactionId));
                storedIds.push(urlTransactionId);
            }

            return {
                // isAuthorized: storedIds.length > 0,
                isAuthorized: false, //not authorized as prospect
                transactionIds: storedIds,
            };
        } catch (e) {
            return {
                isAuthorized: false,
                transactionIds: [],
            };
        }
    },
    onMissingAuthorization: <RedirectWithParams to={registrationPagePath()} />,
    ...commonAuthorizationOptions,
});

const AuthProviders: FC = ({ children }) => (
    <AuthenticationProvider loadingComponent={<Spinner center />}>
        <UserRegistrationProvider>
            <ProspectProvider>
                <AutoLogout idleTimeInMinutes={30} redirectUri={window.location.origin + timeOutPagePath()}>
                    {children}
                </AutoLogout>
            </ProspectProvider>
        </UserRegistrationProvider>
    </AuthenticationProvider>
);

const AuthenticatedRoute = createDecoratedRoute('AuthenticatedRoute', Authentication);
const AuthorizedRoute = createDecoratedRoute('AuthorizedRoute', UserRegistrationAuthorization);
const ProspectOnlyRoute = createDecoratedRoute('ProspectOnlyRoute', ProspectAuthorization);
export * from './AxiosConfiguration';

export {
    AuthenticatedRoute,
    AuthorizedRoute,
    ProspectOnlyRoute,
    useProspectFlow,
    useProspectData,
    useUserRegistrationFlow,
    useUserRegistrationData,
    AuthProviders,
    UserRegistrationAuthorization,
    ProspectAuthorization,
};
