import React from 'react';
import { LeasingFinancialDetails, formatAsCurrency, undefinedReturnValueHyphen } from '../../../../../common';
import { useTranslation } from 'react-i18next';
import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';

export const OtherCostsAndStampDutyAmountSection: React.FC<{ details: LeasingFinancialDetails }> = ({ details }) => {
    const { t } = useTranslation('financial-details');

    const { preliminaryCost, collectionCosts, stampDuty, communicationCosts } = details;

    const definitionListItems: DefinitionListItem[] = [
        {
            label: t('leasing.other-costs-and-stamp-duty-section.preliminary-cost.label'),
            value: formatAsCurrency(preliminaryCost, undefinedReturnValueHyphen),
        },
        {
            tooltip: t('leasing.other-costs-and-stamp-duty-section.collection-cost.tooltip'),
            label: t('leasing.other-costs-and-stamp-duty-section.collection-cost.label'),
            value: formatAsCurrency(collectionCosts, undefinedReturnValueHyphen),
        },
        {
            tooltip: t('leasing.other-costs-and-stamp-duty-section.stamp-duty.tooltip'),
            label: t('leasing.other-costs-and-stamp-duty-section.stamp-duty.label'),
            value: formatAsCurrency(stampDuty, undefinedReturnValueHyphen),
        },
        {
            tooltip: t('leasing.other-costs-and-stamp-duty-section.communication-cost.tooltip'),
            label: t('leasing.other-costs-and-stamp-duty-section.communication-cost.label'),
            value: formatAsCurrency(communicationCosts, undefinedReturnValueHyphen),
        },
    ];

    return (
        <DataOverview withoutCardEffect={true}>
            <DefinitionListHorizontal list={definitionListItems} />
        </DataOverview>
    );
};
