import { Contract, getRequestDocumentEndpoint, RequestDocumentData, ValidationError } from '../../../common';
import { Base64File } from '@cp-shared-8/apis';
import {
    DefaultBusinessMarketApiErrorCode,
    trimAllValues,
    WithDefaultCpIntegrationErrors,
} from '@cp-shared-8/common-utilities';
import { parseErrorResponse } from '@cp-shared-8/frontend-integration';
import {
    preventSubmit,
    Spinner,
    ValidatedInput,
    ValidatedSelect,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import { Button, ButtonContainer, ContentSection, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';

import { FileUpload, getBase64, getFileType, maxFileSize, validFileFormats } from 'components/file-upload';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { CpDataApi } from '../../../cp-xhr';
import { dashboardPagePath } from '../../navigation/paths';
import { financeCategoryIds, leasingCategoryIds } from './category-id-mapping';
import { RequestDocumentFormConfirmationModal } from './confirmation-modal';

// Can not be taken from translation because has to be sent always in English.
const categories: { [key: string]: string } = {
    '3602820a580aed8de9110902a8c35bc4': 'Mortgage cancellation',
    '3602820a580aed8de9110902a02561c4': 'Bank statement',
    acd1c6efad029c81e911095b332cce9f: 'Status confirmation letter',
    acd1c6efad02a481e9110d5bc98ddd3a: 'Contract termination document',
    acd1c6efad02a581e9110e5ba7653a59: 'Regular payments letter',
    acd1c6efad0259b1e91118aa6113e5b7: 'Contract documentation',
    '3602820a580aed8de9110902007660c4': 'Invoices, Vehicle Taxes Payment, notes to the financial statements',
    '3602820a580aed8de9110902b87d69c4': 'Contract extension',
    '3602820a580aed8de91109023a2d6ac4': 'Take over management',
};

export type RequestDocumentFormProps = {
    contract: Contract;
    email: string;
};

export const RequestDocumentForm: React.FC<RequestDocumentFormProps> = ({ contract, email }) => {
    const { t } = useTranslation('request-document');
    const { t: tUpload } = useTranslation('file-upload');
    const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestCertificateSuccess');

    const [lastEnteredValues, setLastEnteredValues] = useState<RequestDocumentData>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [lastSubmissionError, setLastSubmissionError] = useState<
        WithDefaultCpIntegrationErrors<ValidationError | DefaultBusinessMarketApiErrorCode>
    >();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const history = useHistory();

    const cancel = (): void => {
        history.push(dashboardPagePath());
    };

    const openConfirmationModal = (): void => {
        setShowConfirmationModal(true);
    };

    const closeConfirmationModal = (): void => {
        setShowConfirmationModal(false);
        if (!lastSubmissionError) {
            history.push(dashboardPagePath());
        }
    };

    type FormType = {
        email: string;
        categoryId: string;
        files: File[];
    };

    const initialValues: FormType = {
        email: lastEnteredValues?.email ?? email ?? '',
        categoryId: lastEnteredValues?.categoryId ?? '',
        files: [],
    };

    const validationSchema = Yup.object().shape(
        Object.assign({
            email: Yup.string()
                .trim()
                .required(t('form.validations.email.required'))
                .email(t('form.validations.email.invalid-format')),
            categoryId: Yup.string().required(t('form.validations.category.required')),
            files: Yup.array<File>()
                .test('file-amount', tUpload('error-messages.file-amount'), files => files.length <= 4)
                .test(
                    'file-size',
                    tUpload('error-messages.file-size-sum'),
                    files => files.reduce((sum: number, file: File) => sum + (file.size || 0), 0) <= maxFileSize,
                )
                .test(
                    'file-type',
                    tUpload(' '),
                    files => !files.find((file: File) => !validFileFormats.includes(getFileType(file.name || ''))),
                ),
        }),
    );

    const onSubmit = async (formTypeValues: FormType): Promise<void> => {
        const filePromises: Promise<Base64File>[] = formTypeValues.files.map(file => getBase64(file));

        const files = await Promise.all(filePromises);

        const values: RequestDocumentData = {
            email: formTypeValues.email,
            categoryId: formTypeValues.categoryId,
            contractNumber: contract.contractNumber,
            product: contract.contractType,
            files,
        };

        const requestDocumentValues = trimAllValues<RequestDocumentData>(values);
        setIsSubmitting(true);
        setLastEnteredValues(requestDocumentValues);

        CpDataApi.put(getRequestDocumentEndpoint(), requestDocumentValues)
            .then(_ => {
                onSuccess(categories[values.categoryId]);
                setLastSubmissionError(undefined);
                setIsSubmitting(false);
                openConfirmationModal();
            })
            .catch(error => {
                const errorCode = parseErrorResponse<ValidationError | DefaultBusinessMarketApiErrorCode>(error).code;
                setLastSubmissionError(errorCode);
                setIsSubmitting(false);
                openConfirmationModal();
            });
    };

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                {(formik): JSX.Element => (
                    <Form onSubmit={preventSubmit} data-testid="requestDocumentForm">
                        <ContentSection className={'request-document-form'}>
                            <Fieldset>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item default="1/1" s="1/1">
                                            <ValidatedInput
                                                type="email"
                                                label={t('form.email.label')}
                                                name="email"
                                                testId="email"
                                                id="email"
                                                reversed={false}
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item default="1/1" s="1/1">
                                            <ValidatedSelect
                                                label={t('form.category-dropdown.label')}
                                                name="categoryId"
                                                id="categoryId"
                                                testId="categoryId"
                                                selectItems={
                                                    contract.isLeasing
                                                        ? leasingCategoryIds.map(item => {
                                                              return {
                                                                  value: item,
                                                                  label: t(`form.category-dropdown.${item}`),
                                                              };
                                                          })
                                                        : financeCategoryIds.map(item => {
                                                              return {
                                                                  value: item,
                                                                  label: t(`form.category-dropdown.${item}`),
                                                              };
                                                          })
                                                }
                                                placeholder={t('form.category-dropdown.placeholder')}
                                                disablePlaceholder={false}
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <FileUpload name={'files'} />
                                </Fieldset.Row>
                            </Fieldset>
                        </ContentSection>
                        <Fieldset>
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button
                                        secondary
                                        onClick={cancel}
                                        testId="cancelButton"
                                        type="button"
                                        disabled={isSubmitting}
                                    >
                                        {t('translation:editable-section-nav.cancel')}
                                    </Button>
                                    <Button
                                        onClick={formik.submitForm}
                                        testId="submitButton"
                                        type="button"
                                        disabled={isSubmitting}
                                    >
                                        {t('translation:editable-section-nav.send')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
            {isSubmitting && <Spinner fullPage={true} />}
            <RequestDocumentFormConfirmationModal
                shown={showConfirmationModal}
                errorStatus={lastSubmissionError}
                closeConfirmationModal={closeConfirmationModal}
            />
        </>
    );
};
