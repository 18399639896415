import { LeasingContractDetails } from '../../../../common';
import { AccordionItem, Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { Hr } from '@vwfs-bronson/bronson-react';
import { withLoadingHandler } from 'components/integration-wrapper';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BankAccountSection } from '../bank-account-section/BankAccountSection';
import { GeneralContractDetailsProps } from '../GeneralContractDetailsProps';
import { useGeneralContractDetails } from '../useGeneralContractDetails';
import { GeneralSection } from './general-section/GeneralSection';
import { InstallmentSection } from './installment-section/InstallmentSection';
import { PaymentSection } from './payment-section/PaymentSection';
import { RemainderSection } from './remainder-section/RemainderSection';

const LeasingContractDetailsUi: React.FC<{
    contractId: string;
    contractType: string;
    contractDetails?: LeasingContractDetails;
}> = ({ contractId, contractType, contractDetails }) => {
    if (!contractDetails) {
        return null;
    }

    return (
        <>
            <GeneralSection details={contractDetails} />
            <Hr className="u-mb-none" />
            <PaymentSection details={contractDetails} />
            <Hr className="u-mb-none" />
            <BankAccountSection contractId={contractId} contractType={contractType} details={contractDetails} />
            <Hr className="u-mb-none" />
            <InstallmentSection details={contractDetails} />
            <Hr className="u-mb-none" />
            <RemainderSection details={contractDetails} />
        </>
    );
};

const LeasingGeneralContractDetailsWithHandlers = withLoadingHandler(LeasingContractDetailsUi);

export const LeasingGeneralContractDetails: React.FC<GeneralContractDetailsProps> = ({
    contractId,
    contractType,
    setTesFlag,
}) => {
    const { t } = useTranslation('general-contract-details');
    const { t: contractT } = useTranslation('contracts');

    const { data: contractDetails, isLoading, loadingError } = useGeneralContractDetails(contractId);

    useEffect(() => {
        setTesFlag(contractDetails?.tesFlag ? contractDetails.tesFlag : false);
    }, [contractDetails, setTesFlag]);

    return (
        <AccordionItem title={t('headline')}>
            {!!loadingError ? (
                <Notification
                    status={NotificationStatus.error}
                    headline={contractT('contract-details-error.headline')}
                    text={contractT('contract-details-error.text')}
                />
            ) : (
                <LeasingGeneralContractDetailsWithHandlers
                    isLoading={isLoading}
                    contractId={contractId}
                    contractType={contractType}
                    contractDetails={contractDetails as LeasingContractDetails}
                />
            )}
        </AccordionItem>
    );
};
