type Category = { categoryName: string; subCategories: SubCategory[] };
type SubCategory = { subcategoryName: string; apiId: string };

const isProduction = process.env.REACT_APP_STAGE === 'prod';
const problemsWithAccountChangeApiId = isProduction
    ? 'acd1c6efad02df9dee1119c042e93083'
    : '13b9daba419b81bfef119d76675ad542';
const updatedDocumentsApiId = isProduction ? 'acd1c6efad0287aaef11282e62b18e57' : '13b9daba419b81bfef119d7642f99377';
const changeOfSendingMethodOfCommunicationsApiId = isProduction
    ? 'acd1c6efad0252baee11eb9c59f177c6'
    : '13b9daba419b82bfef119d768ece1598';

export const LECategories: Category[] = [
    {
        categoryName: 'contract-termination',
        subCategories: [
            { subcategoryName: 'early-termination-calculation', apiId: '3602820a580aed8de911090268925ec4' },
            { subcategoryName: 'refund-request', apiId: '3602820a580aed8de9110902d2e36bc4' },
        ],
    },
    {
        categoryName: 'authorizations',
        subCategories: [
            { subcategoryName: 'authorization-to-circular-abroad', apiId: '3602820a580aed8de9110902463957c4' },
            { subcategoryName: 'vehicle-registration-changes', apiId: '3602820a580aed8de9110902d2eb59c4' },
            { subcategoryName: 'tow-hook', apiId: '3602820a580aed8de911090252fc5ac4' },
            { subcategoryName: 'vehicle-registration', apiId: 'acd1c6efad02b0b0e91199541dae7617' },
        ],
    },
    {
        categoryName: 'insurance-services',
        subCategories: [
            { subcategoryName: 'information', apiId: '3602820a580aed8de9110902ec5765c4' },
            { subcategoryName: 'claims-management', apiId: '3602820a580aed8de9110902ec5765c4' },
            { subcategoryName: 'copy-of-insurance-policy', apiId: '3602820a580aed8de9110902b2876fc4' },
            { subcategoryName: 'insurance-waiver', apiId: 'acd1c6efad02b0b0e911995427ae7617' },
            { subcategoryName: 'policy-ceiling-redection', apiId: 'acd1c6efad02b0b0e911995429ae7617' },
            { subcategoryName: 'insurance-company-change', apiId: 'acd1c6efad02b0b0e91199542bae7617' },
        ],
    },
    {
        categoryName: 'personal-information-change',
        subCategories: [
            { subcategoryName: 'my-profile-information-change', apiId: '3602820a580aed8de911090226bd66c4' },
            { subcategoryName: 'iban-account-change', apiId: '3602820a580aed8de9110902286a67c4' },
            { subcategoryName: 'problems-with-account-change', apiId: problemsWithAccountChangeApiId },
            { subcategoryName: 'updated-documents', apiId: updatedDocumentsApiId },
            {
                subcategoryName: 'change-of-sending-method-of-communications',
                apiId: changeOfSendingMethodOfCommunicationsApiId,
            },
        ],
    },
    {
        categoryName: 'information',
        subCategories: [
            { subcategoryName: 'vehicle-taxes-and-invoices', apiId: '3602820a580aed8de9110902e64663c4' },
            { subcategoryName: 'others', apiId: 'f21e831deb020d82e9119196eb4bf769' },
        ],
    },
];

export const COCategories = [
    {
        categoryName: 'contract-termination',
        subCategories: [
            { subcategoryName: 'early-termination-calculation', apiId: '3602820a580aed8de91109026ae65dc4' },
            { subcategoryName: 'refund-request', apiId: '3602820a580aed8de9110902d2e36bc4' },
        ],
    },
    {
        categoryName: 'insurance-services',
        subCategories: [
            { subcategoryName: 'information', apiId: '3602820a580aed8de9110902ec5765c4' },
            { subcategoryName: 'copy-of-insurance-policy', apiId: '3602820a580aed8de9110902b2876fc4' },
            { subcategoryName: 'insurance-waiver', apiId: 'acd1c6efad02b0b0e911995427ae7617' },
            { subcategoryName: 'policy-ceiling-redection', apiId: 'acd1c6efad02b0b0e911995429ae7617' },
            { subcategoryName: 'insurance-company-change', apiId: 'acd1c6efad02b0b0e91199542bae7617' },
        ],
    },
    {
        categoryName: 'personal-information-change',
        subCategories: [
            { subcategoryName: 'my-profile-information-change', apiId: '3602820a580aed8de911090226bd66c4' },
            { subcategoryName: 'iban-account-change', apiId: '3602820a580aed8de9110902286a67c4' },
            { subcategoryName: 'installment-due-date-change', apiId: '3602820a580aed8de9110902e6cd68c4' },
            { subcategoryName: 'problems-with-account-change', apiId: problemsWithAccountChangeApiId },
            { subcategoryName: 'updated-documents', apiId: updatedDocumentsApiId },
            {
                subcategoryName: 'change-of-sending-method-of-communications',
                apiId: changeOfSendingMethodOfCommunicationsApiId,
            },
        ],
    },
    {
        categoryName: 'information',
        subCategories: [{ subcategoryName: 'others', apiId: 'f21e831deb020d82e9119196eb4bf769' }],
    },
];

function getCategoriesName(categorySet: Category[]): string[] {
    return categorySet.map((item: Category) => {
        return item.categoryName;
    });
}

function getSubCategories(categoryName: string, categorySet: Category[]): SubCategory[] {
    const filteredCategories = categorySet.filter((item: Category) => {
        return item.categoryName === categoryName;
    });
    return filteredCategories.length > 0 ? filteredCategories[0].subCategories : [];
}

export function getLECategoryNames(): string[] {
    return getCategoriesName(LECategories);
}

export function getCOCategoryNames(): string[] {
    return getCategoriesName(COCategories);
}

export function getLESubCategoryNames(categoryName: string): string[] {
    const subCategories = getSubCategories(categoryName, LECategories);
    return subCategories.map((subCategory: SubCategory) => {
        return subCategory.subcategoryName;
    });
}

export function getCOSubCategoryNames(categoryName: string): string[] {
    const subCategories = getSubCategories(categoryName, COCategories);
    return subCategories.map((subCategory: SubCategory) => {
        return subCategory.subcategoryName;
    });
}

export function getLESubCategoryApiKey(categoryName: string, subCategoryName: string): string {
    return getSubCategories(categoryName, LECategories).filter(
        subCategory => subCategory.subcategoryName === subCategoryName,
    )[0].apiId;
}

export function getCOSubCategoryApiKey(categoryName: string, subCategoryName: string): string {
    return getSubCategories(categoryName, COCategories).filter(
        subCategory => subCategory.subcategoryName === subCategoryName,
    )[0].apiId;
}

export function getCOCategoryTranslationKey(categoryName: string): string {
    return `form.co-categories.${categoryName}.name`;
}

export function getLECategoryTranslationKey(categoryName: string): string {
    return `form.le-categories.${categoryName}.name`;
}

export function getLESubcategoryTranslationKey(categoryName: string, subCategory: string): string {
    return `form.le-categories.${categoryName}.sub-categories.${subCategory}`;
}

export function getCOSubcategoryTranslationKey(categoryName: string, subCategory: string): string {
    return `form.co-categories.${categoryName}.sub-categories.${subCategory}`;
}
