import React from 'react';
import { useLandingPageVideo } from './useLandingPageVideo';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { LandingPageVideoUi } from './ui/LandingPageVideoUi';

const LandingPageVideoWithHandlers = withLoadingAndNoConnectionHandler(LandingPageVideoUi);

export const LandingPageVideo: React.FC = () => {
    const { cmsContent: landingPageVideo, isLoading, loadingError } = useLandingPageVideo();

    return (
        <LandingPageVideoWithHandlers
            isLoading={isLoading}
            landingPageVideoContent={landingPageVideo}
            hasError={!!loadingError}
        />
    );
};
