import { useMemo } from 'react';
import {
    Identification,
    ProductRoutingData,
    ContactDetails,
    CustomerAddress,
    FetchContractError,
    getProductRoutingEndpoint,
    ProductRoutingResponse,
} from '../../common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import {
    AbstractDataState,
    hasFetchedSuccessfully,
    useGetContractBasedApiData,
} from '@cp-shared-8/frontend-integration';
import { useContract } from 'components/contracts';
import { ProductRoutingDataSelector } from './productRoutingSelector';
import { fetchProductRouting } from './productRoutingSlice';
import { ContractData } from './utils';
import { useVehicleDetails } from 'components/vehicle-details';

export function useProductRouting(
    contractData: ContractData,
    identification?: Identification,
    contactDetails?: ContactDetails,
    address?: CustomerAddress,
): AbstractDataState<ProductRoutingResponse, DefaultBusinessMarketApiErrorCode | FetchContractError> {
    const contractState = useContract(contractData.contractId);
    const vehicleDetailsState = useVehicleDetails(contractData.contractId);

    const productRoutingData: ProductRoutingData = {
        firstName: identification?.privateData?.firstName,
        lastName: identification?.privateData?.lastName,
        iban: contractData?.iban,
        numberOfInstallments: contractData?.numberOfInstallments,
        address,
        contactDetails,
        vehicleDetails: vehicleDetailsState.data,
    };

    const requestConfig = {
        data: productRoutingData,
    };

    const productRoutingState = useGetContractBasedApiData(
        contractData.contractId,
        fetchProductRouting,
        ProductRoutingDataSelector,
        getProductRoutingEndpoint(),
        false,
        requestConfig,
    );
    return useMemo(
        () => ({
            isLoading: contractState.isLoading || vehicleDetailsState.isLoading || productRoutingState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : vehicleDetailsState.loadingError
                ? vehicleDetailsState.hasReceivedResponse
                : contractState.hasReceivedResponse && productRoutingState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? hasFetchedSuccessfully(vehicleDetailsState)
                    ? productRoutingState.failedLoadingAttempts
                    : vehicleDetailsState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: productRoutingState.data,
            loadingError: hasFetchedSuccessfully(contractState)
                ? hasFetchedSuccessfully(vehicleDetailsState)
                    ? productRoutingState.loadingError
                    : vehicleDetailsState.loadingError
                : contractState.loadingError,
        }),
        [contractState, vehicleDetailsState, productRoutingState],
    );
}
