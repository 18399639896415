import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';

import { ContractsUi } from './ui';
import { Contract } from '../../common';

const ContractsWithHandlers = withLoadingAndNoConnectionHandler(ContractsUi);

type ContractsProps = {
    contracts: Contract[] | undefined;
    isLoading: boolean;
    loadingError: boolean;
};

export const Contracts: React.FC<ContractsProps> = ({ contracts, isLoading, loadingError }) => {
    return (
        <div>
            <ContractsWithHandlers isLoading={isLoading} contracts={contracts} hasError={loadingError} />
        </div>
    );
};
