import { ContactDetails, getContactDetailsEndpoint } from '../../../common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { AbstractDataState, hasFetchedSuccessfully, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { useMemo } from 'react';
import { useGreeting } from '../../greeting';
import { ContactSectionDataSelector } from './ContactSectionDataSelector';
import { fetchContactDetails } from './ContactSectionSlice';

export function useContactDetailsWithGreeting(): AbstractDataState<ContactDetails, DefaultBusinessMarketApiErrorCode> {
    const greetingState = useGreeting();
    const contactDetailsState = useGetSimpleApiData(
        fetchContactDetails,
        ContactSectionDataSelector,
        false,
        {
            url: getContactDetailsEndpoint(greetingState.data?.customerType || ''),
        },
        undefined,
        !!greetingState.data?.customerType,
    );

    return useMemo(
        () => ({
            isLoading: greetingState.isLoading || contactDetailsState.isLoading,
            hasReceivedResponse: greetingState.loadingError
                ? greetingState.hasReceivedResponse
                : greetingState.hasReceivedResponse && contactDetailsState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(greetingState)
                ? contactDetailsState.failedLoadingAttempts
                : greetingState.failedLoadingAttempts,
            data: contactDetailsState.data,
            loadingError: hasFetchedSuccessfully(greetingState)
                ? contactDetailsState.loadingError
                : greetingState.loadingError,
        }),
        [greetingState, contactDetailsState],
    );
}
