import { CPDate } from '@cp-shared-8/common-utilities';

export type EarlySettlement = {
    advanceInterests?: number;
    advanceFees?: number;
    administrationCharge?: number;
    balloonFlag?: string;
    brand?: string;
    chassisNumber?: string;
    collectionFees?: number;
    contractNumber: string;
    firstInstallmentDate: CPDate;
    ibanDisp?: string;
    installmentAmount: number;
    invoiceTotalAmount?: number;
    lastDueDate?: CPDate;
    lastInstallment?: number;
    lastInstallmentDate?: CPDate;
    latePaymentPenalties?: number;
    latePaymentPenaltiesOnUnpaidInstallments?: number;
    motorVeichleRegistrationFees?: number;
    name?: string;
    nowScheduledInstallments?: number;
    numberOfInstallments: number;
    otherUnpaidDebts?: number;
    outstandingTotal?: number;
    ownershipTransferFee?: number;
    product: Product;
    plateNumber?: string;
    recoveryFees?: number;
    transferInvestigationFees?: number;
    reversalCommissionsTakeOver?: number;
    servicesAmount?: number;
    settlementAmount?: number;
    settlementExpirationDate: CPDate;
    staticIban?: string;
    superVehicleOwnershipTax?: number;
    taxPaymentFees?: number;
    terminationFeesCO?: number;
    terminationFeesLE?: number;
    totalUnpaidAmount?: number;
    totalSettlementAmount: number;
    upfrontPaidInstallments?: number;
    unpaidInstallmentsNumber?: number;
    vat?: number;
    vehicleOwnershipTax?: number;
    status: TesStatus;
};

export type EarlySettlementRequest = {
    contracId: string;
};

export type EarlySettlementRequestResponse = {
    statusId: TesResponseId;
    status?: TesStatus;
};

export enum TesStatus {
    NightRequest = 'NightRequest',
    DayBusyRequest = 'DayBusyRequest',
    DayFreeRequest = 'DayFreeRequest',
    Complete = 'Complete',
    Error = 'Error',
    ConnectionError = 'ConnectionError',
    Repeatable = 'Repeatable',
    NotFound = 'NotFound',
}

export type TesResponseId = -1 | -2 | -3 | -5 | -6 | -7 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 500 | 502;

export type Product = 'CO' | 'LE';

export type EarlySettlementPdf = {
    filename: string;
    base64: string;
};
