import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { useIrapCalculation } from './useIrapCalculation';
import { IrapCalculationUi } from './ui/IrapCalculationUi';
import { useVehicleDetails } from '../vehicle-details';
import { Contract } from '../../common';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';

const IrapCalculationWithHandlers = withLoadingAndNoConnectionHandler(IrapCalculationUi);

export const IrapCalculation: React.FC<{ contract: Contract }> = ({ contract }) => {
    const {
        data: vehicleDetails,
        isLoading: isLoadingVehicleDetails,
        loadingError: vehicleLoadingError,
    } = useVehicleDetails(contract?.contractId);

    const { data: irapCalculation, isLoading: isLoadingIrap, loadingError: irapLoadingError } = useIrapCalculation(
        contract.contractId,
    );
    useAnalyticsPageViewTracker('irapCalculation', !!isLoadingIrap || !!contract);

    return (
        <IrapCalculationWithHandlers
            isLoading={isLoadingVehicleDetails || isLoadingIrap}
            hasError={!!vehicleLoadingError || !!irapLoadingError}
            contractDetails={contract}
            irapCalculation={irapCalculation}
            vehicleDetails={vehicleDetails}
        />
    );
};
