import {
    AbstractDataState,
    hasFetchedSuccessfully,
    useGetContractBasedApiData,
} from '@cp-shared-8/frontend-integration';
import {
    FetchContractError,
    EarlySettlement,
    getEarlySettlementEndpoint,
    EarlySettlementRequestResponse,
} from '../../common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { useContract } from 'components/contracts/useContract';
import { useMemo } from 'react';
import { fetchTes } from './TotalEarlySettlementDataSlice';
import { TotalEarlySettlementDataSelector } from './TotalEarlySettlementDataSelector';

export function useTotalEarlySettlement(
    contractId: string,
): AbstractDataState<
    EarlySettlement | EarlySettlementRequestResponse,
    DefaultBusinessMarketApiErrorCode | FetchContractError
> {
    const contractState = useContract(contractId);
    const tesState = useGetContractBasedApiData(
        contractId,
        fetchTes,
        TotalEarlySettlementDataSelector,
        getEarlySettlementEndpoint(contractId),
    );
    return useMemo(
        () => ({
            isLoading: contractState.isLoading || tesState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && tesState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? tesState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: tesState.data,
            loadingError: hasFetchedSuccessfully(contractState) ? tesState.loadingError : contractState.loadingError,
        }),
        [contractState, tesState],
    );
}
