import { DefaultMarketApiErrors } from '@cp-shared-8/common-utilities';

export enum UserRegistryStatusEnum {
    REGISTERED = 'REGISTERED',
    PROSPECT = 'PROSPECT',
}

export interface UserRegistryStatus {
    /**
     * An identifier whether user is registered in CustomerPortal or is a prospect user
     */
    status: UserRegistryStatusEnum;
    transactionIds: string[];
}

export type UserRegistryStatusError = DefaultMarketApiErrors;
