import React from 'react';

import { withLoadingHandler } from 'components/integration-wrapper';

import { AmortizationTableUi } from './ui';
import { useAmortizationTableHeader } from './useAmortizationTableHeader';
import { useContract } from '../contracts';
import { AmortizationTableErrorNotification } from './error-notification';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';

const AmortizationTableWithHandlers = withLoadingHandler(AmortizationTableUi);

type AmortizationTableHeaderProps = {
    contractId: string;
};

export const AmortizationTable: React.FC<AmortizationTableHeaderProps> = ({ contractId }) => {
    const { data: contractState, isLoading: isContractLoading, loadingError: contractloadingError } = useContract(
        contractId,
    );
    const {
        data: amortizationTableHeader,
        isLoading: isTableLoading,
        loadingError: tableLoadingError,
    } = useAmortizationTableHeader(contractId);

    useAnalyticsPageViewTracker('amortizationTable', !!(contractState && amortizationTableHeader));

    const isLoading = isContractLoading || isTableLoading;
    const loadingError = contractloadingError || tableLoadingError;
    const component = loadingError ? (
        <AmortizationTableErrorNotification />
    ) : (
        <AmortizationTableWithHandlers
            isLoading={isLoading}
            contractId={contractId}
            amortizationTableHeader={amortizationTableHeader}
            contractDataLinks={contractState?._links}
        />
    );

    return component;
};
