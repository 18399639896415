import React from 'react';
import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { ConsumerCreditFinancialDetails, formatAsCurrency, undefinedReturnValueHyphen } from '../../../../../common';

export const GrossLoanInterestSection: React.FC<{ details: ConsumerCreditFinancialDetails }> = ({ details }) => {
    const { t } = useTranslation('financial-details');

    const { effectiveInterestAmount, grossLoanAmount } = details;

    const definitionListItems: DefinitionListItem[] = [
        {
            tooltip: t('consumer-credit.gross-loan-interest-section.effective-interest-amount.tooltip'),
            label: t('consumer-credit.gross-loan-interest-section.effective-interest-amount.label'),
            value: formatAsCurrency(effectiveInterestAmount, undefinedReturnValueHyphen),
        },
        {
            tooltip: t('consumer-credit.gross-loan-interest-section.gross-loan-amount.tooltip'),
            label: t('consumer-credit.gross-loan-interest-section.gross-loan-amount.label'),
            value: formatAsCurrency(grossLoanAmount, undefinedReturnValueHyphen),
        },
    ];

    return (
        <DataOverview withoutCardEffect={true}>
            <DefinitionListHorizontal list={definitionListItems} />
        </DataOverview>
    );
};
