import React from 'react';
import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { ConsumerCreditFinancialDetails, formatAsCurrency, undefinedReturnValueHyphen } from '../../../../../common';

export const SharedRefundSection: React.FC<{ details: ConsumerCreditFinancialDetails }> = ({ details }) => {
    const { t } = useTranslation('financial-details');
    const { sharedServices, totalToBeRefunded } = details;

    const definitionListItems: DefinitionListItem[] = [
        {
            tooltip: t('consumer-credit.shared-refund-section.shared-services-amount.tooltip'),
            label: t('consumer-credit.shared-refund-section.shared-services-amount.label'),
            value: formatAsCurrency(sharedServices, undefinedReturnValueHyphen),
        },
        {
            tooltip: t('consumer-credit.shared-refund-section.refund-amount.tooltip'),
            label: t('consumer-credit.shared-refund-section.refund-amount.label'),
            value: formatAsCurrency(totalToBeRefunded, undefinedReturnValueHyphen),
        },
    ];

    return (
        <DataOverview withoutCardEffect={true}>
            <DefinitionListHorizontal list={definitionListItems} />
        </DataOverview>
    );
};
