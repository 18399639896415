import { HeroImage, useAnalyticsErrorPageTracker } from '@cp-shared-8/frontend-ui';
import { useLogin } from 'auth/useLogin';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React, { useCallback } from 'react';
import { useUnauthorizedErrorPage } from './useUnauthorizedErrorPage';

export const UnauthorizedErrorPage: React.FC = () => {
    const { cmsContent: unauthorizedErrorContent, isLoading, loadingError } = useUnauthorizedErrorPage();

    const login = useLogin();
    const onClick = useCallback(() => {
        login();
    }, [login]);

    const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

    useAnalyticsErrorPageTracker('unauthorizedError', !!unauthorizedErrorContent);

    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            pretitle={unauthorizedErrorContent?.pretitle}
            title={unauthorizedErrorContent?.title || ''}
            subTitle={unauthorizedErrorContent?.subTitle}
            buttonText={unauthorizedErrorContent?.buttonText}
            clickHandler={onClick}
            inverted
        />
    );
};
