import React, { FC } from 'react';

import { CustomerType } from '../../../common';
import { AddressSectionUi } from './ui';
import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';
import { useCustomerAddress } from './useCustomerAddress';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';

export type AddressSectionProps = {
    customerType: CustomerType;
    isProspect: boolean;
    isTabsPresent?: boolean;
};

const AddressSectionWithHandlers = withLoadingAndNoConnectionHandler(AddressSectionUi);

export const AddressSection: FC<AddressSectionProps> = ({ customerType, isProspect, isTabsPresent }) => {
    const { t } = useTranslation('my-profile');
    const { data: address, isLoading, loadingError } = useCustomerAddress(
        isTabsPresent ? 'PrivateBusinessMan' : customerType,
        isProspect,
    );

    return loadingError ? (
        <Notification
            status={NotificationStatus.error}
            headline={t('address.error.title')}
            text={t('address.error.text')}
            testId={'my-profile-address-section-error'}
        />
    ) : (
        <AddressSectionWithHandlers
            isLoading={isLoading}
            customerType={customerType}
            address={address}
            hasError={!!loadingError}
        />
    );
};
