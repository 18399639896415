import { IT_TIMEZONE } from './common';
import MockAdapter from 'axios-mock-adapter';
import { CpContentApi, CpDataApi } from './cp-xhr';
import './localization/i18n';
import moment from 'moment-timezone';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';

const render = () => {
    const App = require('./App').default;
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root'),
    );
};

if (process.env.NODE_ENV === 'development') {
    // Swap the value to enable local mocks
    const cpDataApiMock = new MockAdapter(CpDataApi);
    const cpContentApiMock = new MockAdapter(CpContentApi);
    cpContentApiMock.onAny().passThrough();
    cpDataApiMock.onAny().passThrough();
}

moment.tz.setDefault(IT_TIMEZONE);
render();

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App', render);
}
