import { ContactDetails } from '../../../common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { AxiosRequestConfig } from 'axios';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<ContactDetails, DefaultBusinessMarketApiErrorCode>({
    dataName: 'contactDetails',
    fetchCallback(requestConfig?: AxiosRequestConfig) {
        const url: string = requestConfig ? (requestConfig.url ? requestConfig.url : '') : '';
        return CpDataApi.get(url).then(response => response.data);
    },
});

export default reducer;
export const fetchContactDetails = fetchData;
