import { BundledProductDetails, FetchContractError } from '../../../common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import {
    AbstractDataState,
    hasFetchedSuccessfully,
    useGetContractBasedApiData,
} from '@cp-shared-8/frontend-integration';
import { useMemo } from 'react';
import { useContract } from '../useContract';
import { fetchBundledProducts } from './BundledProductSlice';
import { BundledProductsSelector } from './BundledProductsSelector';

type BundledProducts = {
    products: BundledProductDetails[] | undefined;
    contractType: string;
};

export function useBundledProducts(
    contractId: string,
): AbstractDataState<BundledProducts, DefaultBusinessMarketApiErrorCode | FetchContractError> {
    const contractState = useContract(contractId);
    const contractType = contractState.data?.isLeasing ? 'leasing' : 'consumer-credit';

    const bundledProductsState = useGetContractBasedApiData(
        contractId,
        fetchBundledProducts,
        BundledProductsSelector,
        contractState.data?._links?.bundledProductDetails,
    );
    return useMemo(
        () => ({
            isLoading: contractState.isLoading || bundledProductsState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && bundledProductsState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? bundledProductsState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: { products: bundledProductsState.data, contractType: contractType },
            loadingError: hasFetchedSuccessfully(contractState)
                ? bundledProductsState.loadingError
                : contractState.loadingError,
        }),
        [contractState, bundledProductsState, contractType],
    );
}
