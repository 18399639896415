export function getProspectProfileIdentificationEndpoint(): string {
    return '/prospect-profile-identification';
}

export function getProspectProfileAddressEndpoint(): string {
    return '/prospect-profile-address';
}

export function getProspectProfileContactEndpoint(): string {
    return '/prospect-profile-contact-details';
}

export function getProspectProfileMarketingPreferencesEndpoint(): string {
    return '/prospect-profile-marketing-preferences';
}

export function getProspectGreetingEndpoint(): string {
    return '/prospect-greeting';
}
