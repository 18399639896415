import React from 'react';
import { Contract, CustomerType } from '../../common';
import { withLoadingHandler } from 'components/integration-wrapper';
import { useCustomerAddress } from 'components/my-profile/address-section/useCustomerAddress';
import { useContactDetails } from 'components/my-profile/contact-section';
import { useIdentification } from 'components/my-profile/identification-section';
import { DashboardMarketingCardUi } from './ui';
import { useDashboardMarketingCard } from './useDashboardMarketingCard';
import { useMostRecentContractsForEachBrand, useShouldShowExtendedWarranty } from './utils';

const DashboardMarketingCardUiWithHandlers = withLoadingHandler(DashboardMarketingCardUi);

type DashboardMarketingCardProps = {
    customerType: CustomerType;
    isProspect: boolean;
    contracts: Contract[];
};

export const DashboardMarketingCard: React.FC<DashboardMarketingCardProps> = ({
    contracts,
    isProspect,
    customerType,
}) => {
    const {
        cmsContent: dashboardMarketingCard,
        isLoading: isLoadingContent,
        loadingError: loadingContentError,
    } = useDashboardMarketingCard();

    const { contractsData, isLoading: isLoadingContractsData } = useMostRecentContractsForEachBrand(contracts);

    const {
        data: identification,
        isLoading: isLoadingIdentification,
        loadingError: loadingIdentificationError,
    } = useIdentification(customerType, isProspect);

    const {
        data: contactDetails,
        isLoading: isLoadingContactDetails,
        loadingError: loadingContactDetailsError,
    } = useContactDetails(customerType, isProspect);

    const { data: address, isLoading: isLoadingAddress, loadingError: loadingAddressError } = useCustomerAddress(
        customerType,
        isProspect,
    );

    const showExtendedWarrantyCard = useShouldShowExtendedWarranty(contracts);

    if (!!loadingContentError || !!loadingIdentificationError || !!loadingContactDetailsError || !!loadingAddressError)
        return null;

    return (
        <DashboardMarketingCardUiWithHandlers
            isLoading={
                isLoadingContent ||
                isLoadingContractsData ||
                isLoadingIdentification ||
                isLoadingContactDetails ||
                isLoadingAddress
            }
            dashboardMarketingCard={dashboardMarketingCard}
            showGoogleRatingCard={!!contracts.length}
            showExtendedWarrantyCard={showExtendedWarrantyCard}
            mostRecentContractsForEachBrand={contractsData}
            contactDetails={contactDetails}
            identification={identification}
            address={address}
        />
    );
};
