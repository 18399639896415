import React from 'react';
import { LeasingFinancialDetails } from '../../../../../common';
import { Hr } from '@vwfs-bronson/bronson-react';
import { VehiclePriceSection } from '../vehicle-price-section';
import { CapitalPrincipalSection } from '../captial-principal-amount-section';
import { RedeemLeaseAmountSection } from '../redeem-lease-amount-section';
import { ServiceAmountSection } from '../service-amount-section/ServiceAmountSection';
import { OtherCostsAndStampDutyAmountSection } from '../other-cost-stamp-duty-amount-section';

export const LeasingFinancialDetailsUi: React.FC<{ leasingFinancialDetails?: LeasingFinancialDetails }> = ({
    leasingFinancialDetails,
}) => {
    if (!leasingFinancialDetails) {
        return null;
    }

    return (
        <>
            <VehiclePriceSection details={leasingFinancialDetails} />
            <Hr className="u-mb-none" />
            <CapitalPrincipalSection details={leasingFinancialDetails} />
            <Hr className="u-mb-none" />
            <RedeemLeaseAmountSection details={leasingFinancialDetails} />
            <Hr className="u-mb-none" />
            <ServiceAmountSection details={leasingFinancialDetails} />
            <Hr className="u-mb-none" />
            <OtherCostsAndStampDutyAmountSection details={leasingFinancialDetails} />
        </>
    );
};
