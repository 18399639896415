import { ContractLinks, LeasingContract as LeasingContractType } from '../../../../common';
import {
    Accordion,
    ContractActionItemType,
    ContractActions,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import { Card } from '@vwfs-bronson/bronson-react';
import { BundledProducts } from 'components/contracts/bundled-products/BundledProducts';
import { LeasingGeneralContractDetails } from 'components/contracts/general-contract-details/leasing/LeasingGeneralContractDetails';
import { VehicleDetails } from 'components/vehicle-details';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    amortizationTablePath,
    earlySettlementPath,
    irapCalculationPath,
    postboxPagePathWithContractId,
    requestDocumentPath,
    taxesTablePagePath,
} from '../../../navigation/paths';
import { LeasingFinancialDetails } from '../../financial-details/leasing-financial-details';
import { UnpaidAlert } from '../../unpaid-alert/UnpaidAlert';
import { LeasingContractSummary } from '../summary';

export type LeasingContractProps = {
    contract: LeasingContractType;
    links?: ContractLinks;
    defaultExpanded?: boolean;
    isActive: boolean;
};

export const LeasingContract: React.FC<LeasingContractProps> = ({ contract, links, defaultExpanded, isActive }) => {
    const { onAction } = useAnalyticsActionTracker('onOpenContractAccordion');
    const { t } = useTranslation('contracts');
    const [tesFlag, setTesFlag] = useState(false);

    const contractActionItems: ContractActionItemType[] = [
        {
            iconName: 'calendar',
            pageUrl: amortizationTablePath(contract.contractId),
            label: t('contract-actions.amortization-table'),
            iconReversed: true,
        },
        {
            iconName: 'document-lines-checkmark',
            pageUrl: taxesTablePagePath(contract.contractId),
            label: t('contract-actions.taxes-table'),
            iconReversed: true,
        },
        {
            iconName: 'semantic-file',
            pageUrl: requestDocumentPath(contract.contractId),
            label: t('contract-actions.request-document'),
            iconReversed: true,
        },
        {
            iconName: 'semantic-download',
            pageUrl: postboxPagePathWithContractId(contract.contractId),
            label: t('contract-actions.postbox'),
            iconReversed: true,
        },
    ];

    if (tesFlag) {
        contractActionItems.push({
            iconName: 'semantic-calculator',
            pageUrl: earlySettlementPath(contract.contractId),
            label: t('contract-actions.tes'),
            iconReversed: true,
        });
    }

    contractActionItems.push({
        iconName: 'semantic-accessoires',
        pageUrl: irapCalculationPath(contract.contractId),
        label: t('contract-actions.irap-calculation'),
        iconReversed: true,
    });

    const getViewChange = (currentViewName: string) => {
        switch (currentViewName) {
            case t('general-contract-details:headline'):
                return 'General contract';
            case t('vehicle-details:headline'):
                return 'Vehicle details';
            case t('bundled-products:headline'):
                return 'Bundled Products';
            case t('financial-details:headline'):
                return 'Financial details';
        }
    };

    let openItems = [];

    const handleOnChange = (refs: string[]): void => {
        if (openItems.length > refs.length || !refs.length) {
            openItems = [...refs];
            return;
        }
        openItems = [...refs];
        const viewChange = getViewChange(refs[refs.length - 1]);
        onAction(viewChange);
    };

    const contractUnpaids = links?.unpaidDetailsPdfDownload && (
        <UnpaidAlert downloadLink={links.unpaidDetailsPdfDownload} />
    );

    const contractSummary = <LeasingContractSummary contract={contract} isActive={isActive} />;

    const contractBody = links ? (
        <Accordion onChange={handleOnChange} lazyRender={true}>
            <>
                <ContractActions contractActionItems={contractActionItems} />
                <br />
                {links.contractDetails && (
                    <LeasingGeneralContractDetails
                        contractId={contract.contractId}
                        contractType={contract.contractType}
                        setTesFlag={setTesFlag}
                    />
                )}
                {links.financialDetails && <LeasingFinancialDetails contractId={contract.contractId} />}
                {links.bundledProductDetails && <BundledProducts contractId={contract.contractId} />}
                {links.vehicleDetails && <VehicleDetails contractId={contract.contractId} />}
            </>
        </Accordion>
    ) : null;

    const { contractType, contractNumber } = contract;

    return (
        <Card
            element="article"
            expandable
            title={`${contractType} ${contractNumber}`}
            defaultExpanded={defaultExpanded}
            contentLarge={contractBody}
            className={'u-mb'}
        >
            {contractSummary}
            {contractUnpaids && (
                <>
                    {contractUnpaids}
                    <br />
                </>
            )}
        </Card>
    );
};
