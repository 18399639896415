import React from 'react';
import { ConsumerCreditFinancialDetails } from '../../../../../common';
import { VehiclePriceSection } from '../vehicle-price-section';
import { Hr } from '@vwfs-bronson/bronson-react';
import { PreliminaryCapitalSection } from '../preliminary-capital-section/PreliminaryCapitalSection';
import { GrossLoanInterestSection } from '../gross-loan-interest-section';
import { SharedRefundSection } from '../shared-refund-section/SharedRefundSection';
import { CollectionDueSection } from '../collection-due-section/CollectionDueSection';
import { StampDutyCommunicationSection } from '../stampduty-communication-section/StampDutyCommunicationSection';

export const ConsumerCreditFinancialDetailsUi: React.FC<{
    consumerCreditFinancialDetails?: ConsumerCreditFinancialDetails;
}> = ({ consumerCreditFinancialDetails }) => {
    if (!consumerCreditFinancialDetails) {
        return null;
    }

    return (
        <>
            <VehiclePriceSection details={consumerCreditFinancialDetails} />
            <Hr className="u-mb-none" />
            <PreliminaryCapitalSection details={consumerCreditFinancialDetails} />
            <Hr className="u-mb-none" />
            <GrossLoanInterestSection details={consumerCreditFinancialDetails} />
            <Hr className="u-mb-none" />
            <SharedRefundSection details={consumerCreditFinancialDetails} />
            <Hr className="u-mb-none" />
            <CollectionDueSection details={consumerCreditFinancialDetails} />
            <Hr className="u-mb-none" />
            <StampDutyCommunicationSection details={consumerCreditFinancialDetails} />
        </>
    );
};
