import React from 'react';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { MyProfile } from '../components/my-profile';
import { PageHeader } from '../components/page-header/PageHeader';

export const MyProfilePage: React.FC = () => {
    const { t } = useTranslation('my-profile');

    return (
        <ContentSection pageWrapSize="medium">
            <PageHeader title={t('headline')} />
            <MyProfile />
        </ContentSection>
    );
};
