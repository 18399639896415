import React, { useState } from 'react';
import { ContactDetails, CustomerAddress, DashboardMarketingCards, Identification } from '../../../common';
import { Carousel } from '@cp-shared-8/frontend-ui';
import { FlickityOptions } from 'react-flickity-component';
import { BrandRelatedMarketingCard } from '../brand-related-marketing-card/BrandRelatedMarketingCard';
import { MarketingCardComponent } from '../marketing-card-component';
import { ContractData } from '../utils';

type DashboardMarketingCardUiProps = {
    dashboardMarketingCard?: DashboardMarketingCards;
    mostRecentContractsForEachBrand?: ContractData[];
    contactDetails?: ContactDetails;
    identification?: Identification;
    address?: CustomerAddress;
    showExtendedWarrantyCard?: boolean;
    showGoogleRatingCard?: boolean;
};

export const DashboardMarketingCardUi: React.FC<DashboardMarketingCardUiProps> = React.memo(
    ({
        dashboardMarketingCard,
        showExtendedWarrantyCard,
        showGoogleRatingCard,
        mostRecentContractsForEachBrand = [],
        contactDetails,
        identification,
        address,
    }) => {
        const [productRoutingLoadedId, setProductRoutingLoadedId] = useState<string>('');

        const setProductRoutingLoaded = (contractId: string) => () => {
            setProductRoutingLoadedId(contractId);
        };

        if (!dashboardMarketingCard) {
            return null;
        }

        const options: FlickityOptions = {
            autoPlay: 10000,
        };

        return (
            <>
                <Carousel options={options} key={productRoutingLoadedId}>
                    {mostRecentContractsForEachBrand.map(contract => {
                        return (
                            <BrandRelatedMarketingCard
                                key={contract.contractId}
                                contractData={contract}
                                dashboardMarketingCard={dashboardMarketingCard}
                                contactDetails={contactDetails}
                                identification={identification}
                                address={address}
                                setProductRoutingLoaded={setProductRoutingLoaded(contract.contractId)}
                            />
                        );
                    })}
                    {showExtendedWarrantyCard && (
                        <MarketingCardComponent
                            content={dashboardMarketingCard.extendedWarranty}
                            buttonUrl={dashboardMarketingCard.extendedWarranty.buttonUrl}
                            testId="extended-warranty-card"
                        />
                    )}
                </Carousel>
                {showGoogleRatingCard && (
                    <MarketingCardComponent
                        className="u-mt-xlarge"
                        testId="google-rating-card"
                        content={dashboardMarketingCard.googleRating}
                        buttonUrl={dashboardMarketingCard.googleRating.buttonUrl}
                    />
                )}
            </>
        );
    },
);

DashboardMarketingCardUi.displayName = 'DashboardMarketingCardUi';
