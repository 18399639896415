import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { LeasingFinancialDetailsWithAllValues, LeasingFinancialDetailsWithMissingValues } from './ExampleData';
import { ActiveLeasingContractWithAllValues } from 'components/contracts/ExampleData';
import { getFinancialDetailsEndpoint } from '../../../../common';
import { ContractsMock } from '../contracts-mock';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: LeasingFinancialDetailsWithAllValues,
    },
    'With Missing Values': {
        status: 200,
        responseBody: LeasingFinancialDetailsWithMissingValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const LeasingFinancialDetailsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Leasing Financial Details Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getFinancialDetailsEndpoint(ActiveLeasingContractWithAllValues.contractId),
};

export const LeasingFinancialDetailsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [ContractsMock, LeasingFinancialDetailsMock]);

    return <div>{storyFn()}</div>;
};
