import React from 'react';
import { Modal } from '@vwfs-bronson/bronson-react';

type RequestModelProps = {
    t: Function;
    showSuccessDialogue: boolean;
    showErrorDialogue: boolean;
    closeConfirmationDialogue: () => void;
    closeErrorDialogue: () => void;
};

export const RequestModal: React.FC<RequestModelProps> = ({
    t,
    showSuccessDialogue,
    showErrorDialogue,
    closeConfirmationDialogue,
    closeErrorDialogue,
}) => {
    return (
        <>
            <Modal
                shown={showSuccessDialogue}
                buttonConfirmText={t('form.modal.success.buttons-label')}
                onConfirm={closeConfirmationDialogue}
                onClickOutside={closeConfirmationDialogue}
                onClose={closeConfirmationDialogue}
                testId="confirmModal"
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: t('form.modal.success.text'),
                    }}
                />
            </Modal>
            <Modal
                shown={showErrorDialogue}
                buttonCancelText={t('form.modal.error.buttons-label')}
                onCancel={closeErrorDialogue}
                onClickOutside={closeErrorDialogue}
                onClose={closeErrorDialogue}
                testId="errorModal"
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: t('form.modal.error.text'),
                    }}
                />
            </Modal>
        </>
    );
};
