import React from 'react';
import { Contract } from '../../../common';
import { NoConnectionNotification } from '../../notifications/no-connection/NoConnectionNotification';
import { RequestDocumentHeader } from '../request-document-header';
import { RequestDocumentForm } from '../request-document-form';

export type RequestDocumentUiProps = {
    contract?: Contract;
    email: string;
};

export const RequestDocumentUi: React.FC<RequestDocumentUiProps> = ({ contract, email }) => {
    if (!contract) {
        return <NoConnectionNotification />;
    }

    return (
        <>
            <RequestDocumentHeader contract={contract} />
            <RequestDocumentForm contract={contract} email={email} />
        </>
    );
};
