import React from 'react';
import { LeasingFinancialDetails, formatAsCurrency, undefinedReturnValueHyphen } from '../../../../../common';
import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';

export const VehiclePriceSection: React.FC<{ details: LeasingFinancialDetails }> = ({ details }) => {
    const { t } = useTranslation('financial-details');

    const { purchasePriceAmount, vatExemptShare, vehiclePriceIncludedVat, downPaymentAmount } = details;

    const definitionListItems: DefinitionListItem[] = [
        {
            tooltip: t('leasing.vehicle-price-section.vehicle-price-excluding-vat.tooltip'),
            label: t('leasing.vehicle-price-section.vehicle-price-excluding-vat.label'),
            value: formatAsCurrency(purchasePriceAmount, undefinedReturnValueHyphen),
        },
        {
            tooltip: t('leasing.vehicle-price-section.vat-exempt-share.tooltip'),
            label: t('leasing.vehicle-price-section.vat-exempt-share.label'),
            value: formatAsCurrency(vatExemptShare, undefinedReturnValueHyphen),
        },
        {
            tooltip: t('leasing.vehicle-price-section.vehicle-price-including-vat.tooltip'),
            label: t('leasing.vehicle-price-section.vehicle-price-including-vat.label'),
            value: formatAsCurrency(vehiclePriceIncludedVat, undefinedReturnValueHyphen),
        },
        {
            tooltip: t('leasing.vehicle-price-section.downpayment.tooltip'),
            label: t('leasing.vehicle-price-section.downpayment.label'),
            value: formatAsCurrency(downPaymentAmount, undefinedReturnValueHyphen),
        },
    ];

    return (
        <DataOverview withoutCardEffect={true}>
            <DefinitionListHorizontal list={definitionListItems} />
        </DataOverview>
    );
};
