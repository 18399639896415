import React from 'react';

import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';

import { LegalNoticeUi } from './ui';
import { useLegalNotice } from './useLegalNotice';

const LegalNoticeWithHandlers = withLoadingAndNoConnectionHandler(LegalNoticeUi);

export const LegalNotice: React.FC = () => {
    const { cmsContent: legalNotice, isLoading, loadingError } = useLegalNotice();

    useAnalyticsPageViewTracker('legalNotice', !!legalNotice);

    return <LegalNoticeWithHandlers isLoading={isLoading} legalNotice={legalNotice} hasError={!!loadingError} />;
};
