import { DataOverview } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { AddressItem, Locality, Street } from '../../../../common';

const formatStreet = (street: Street | undefined) => {
    let res = '-';

    if (street?.addressType || street?.street || street?.houseNumber) {
        res = `${street?.addressType || ''} ${street?.street || ''}`;

        if (street?.houseNumber !== '.') {
            res = `${res} ${street?.houseNumber || ''}`;
        }
    }

    return res;
};

const formatlocality = (locality: Locality | undefined) => {
    let res = '-';

    if (locality?.zipCode || locality?.optionalLine || locality?.city || locality?.province || locality?.country) {
        res = `${locality?.zipCode || ''}`;

        if (locality?.optionalLine) {
            res = `${res} Fraz. ${locality?.optionalLine}`;
        }

        res = `${res} ${locality?.city || ''}`;

        if (locality?.province) {
            res = `${res} (${locality?.province})`;
        }

        res = `${res}, ${locality?.country || ''}`;
    }

    return res;
};

export const AddressSectionEntry: React.FC<{ address: AddressItem }> = ({ address }) => {
    const { t } = useTranslation('my-profile');

    let headline;
    switch (address.addressUsage) {
        case 'Residence':
            headline = t('address.headline.residence');
            break;
        case 'Postal address':
            headline = t('address.headline.postal');
            break;
        case 'Legal entity address':
            headline = t('address.headline.legal');
            break;
        case 'Other address':
            headline = t('address.headline.other');
            break;
    }

    const addressList: DefinitionListItem[] = [
        {
            label: t('address.street'),
            value: formatStreet(address.street),
        },
        {
            label: t('address.locality'),
            value: formatlocality(address.locality),
        },
    ];

    if (address.careOf) {
        addressList.push({
            label: t('address.careOf'),
            value: address.careOf,
        });
    }

    return (
        <DataOverview title={headline}>
            <DefinitionListHorizontal list={addressList} />
        </DataOverview>
    );
};
