import { HeroImage, openInSameWindow, useAnalyticsErrorPageTracker } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';

import { useForbiddenErrorPage } from './useForbiddenErrorPage';

export const ForbiddenErrorPage: React.FC = () => {
    const { cmsContent: forbiddenErrorContent, isLoading, loadingError } = useForbiddenErrorPage();

    const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

    useAnalyticsErrorPageTracker('accessDeniedError', !!forbiddenErrorContent);

    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            pretitle={forbiddenErrorContent?.pretitle}
            title={forbiddenErrorContent?.title || ''}
            subTitle={forbiddenErrorContent?.subTitle}
            buttonText={forbiddenErrorContent?.buttonText}
            clickHandler={openInSameWindow(forbiddenErrorContent?.buttonUrl)}
            inverted
        />
    );
};
