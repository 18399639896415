import { formatAsFileSize } from '../../common';
import { FileUpload as FileUploadComponent } from '@cp-shared-8/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const maxFileSize = 4 * 1024 * 1024;
export const validFileFormats = ['pdf', 'bmp', 'gif', 'jpeg', 'jpg', 'png', 'tif', 'tiff', 'docx', 'doc'];

export const FileUpload: React.FC<{ name: string }> = ({ name }) => {
    const { t } = useTranslation('file-upload');

    const descriptionText = (
        <>
            <p className="u-hide@s">{t('upload-title.desktop')}</p>
            <p className="u-hide@s-up">{t('upload-title.mobile')}</p>
        </>
    );

    const descriptionSupplementaryText = (
        <>
            <p className="u-hide@s">{t('upload-text.desktop')}</p>
            <p className="u-hide@s-up">{t('upload-text.mobile')}</p>
        </>
    );

    return (
        <>
            <h5>{t('headline')}</h5>
            <p>{t('text-filesize')}</p>
            <p>{t('text-filetype')}</p>
            <FileUploadComponent
                name={name}
                descriptionText={descriptionText}
                descriptionSupplementaryText={descriptionSupplementaryText}
                buttonText={t('upload-button')}
                cancelLabel={t('translation:editable-section-nav.cancel')}
                fileSizeFormatter={(bytes: number): string => formatAsFileSize(bytes, 2)}
                multiple
                maxFileSize={maxFileSize}
                validFileFormats={validFileFormats}
                sizeError={t('error-messages.file-size')}
                typeError={t('error-messages.file-type')}
                sizeAndTypeError={
                    <>
                        {t('error-messages.file-size')}
                        {<br />}
                        {t('error-messages.file-type')}
                    </>
                }
            />
        </>
    );
};
