import React from 'react';
import { RegistrationFormType, RegistrationStep1FormUi } from './ui/step1/RegistrationStep1FormUi';

export type RegistrationFormProps = {
    type: RegistrationFormType;
    legalNotice: string;
    isProspect: boolean;
    onSubmitSuccess?: Function;
    onSubmitError?: Function;
};

export const RegistrationForm: React.FC<RegistrationFormProps> = props => <RegistrationStep1FormUi {...props} />;
