import { HeroImage } from '@cp-shared-8/frontend-ui';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { Accessibility } from 'common';
import React from 'react';

type AccessibilityUiProps = {
    accessibilityContent?: Accessibility;
};

export const AccessibilityUi: React.FC<AccessibilityUiProps> = ({ accessibilityContent }) => {
    const { teaser, content } = accessibilityContent || {};

    const [mainContent, alternativeContent] = content || [];

    const teaserComponent = teaser ? <HeroImage title={teaser.title} shallow /> : null;

    const accessibilityContentComponent = mainContent ? (
        <ContentSection pageWrapSize="medium">
            <div dangerouslySetInnerHTML={{ __html: mainContent }} />
        </ContentSection>
    ) : null;

    const alternativeBackgroundContentSection = alternativeContent ? (
        <ContentSection pageWrapSize="medium" altBackground>
            <div dangerouslySetInnerHTML={{ __html: alternativeContent }} />
        </ContentSection>
    ) : null;

    return (
        <>
            {teaserComponent}
            {accessibilityContentComponent}
            {alternativeBackgroundContentSection}
        </>
    );
};
