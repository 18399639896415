import React from 'react';
import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { ConsumerCreditFinancialDetails, formatAsCurrency, undefinedReturnValueHyphen } from '../../../../../common';

export const CollectionDueSection: React.FC<{ details: ConsumerCreditFinancialDetails }> = ({ details }) => {
    const { t } = useTranslation('financial-details');
    const { collectionCosts, totalDueAmount } = details;

    const definitionListItems: DefinitionListItem[] = [
        {
            tooltip: t('consumer-credit.collection-due-section.collection-amount.tooltip'),
            label: t('consumer-credit.collection-due-section.collection-amount.label'),
            value: formatAsCurrency(collectionCosts, undefinedReturnValueHyphen),
        },
        {
            tooltip: t('consumer-credit.collection-due-section.due-amount.tooltip'),
            label: t('consumer-credit.collection-due-section.due-amount.label'),
            value: formatAsCurrency(totalDueAmount, undefinedReturnValueHyphen),
        },
    ];

    return (
        <DataOverview withoutCardEffect={true}>
            <DefinitionListHorizontal list={definitionListItems} />
        </DataOverview>
    );
};
