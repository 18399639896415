import React from 'react';

import { ContentSection, Layout, Button, SectionHeading } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { NotificationStatus, Notification, Spinner } from '@cp-shared-8/frontend-ui';

type AmortizationTableContentCoProps = {
    hasError?: boolean;
    downloadPdf: (fileName: string) => void;
    isSubmitting?: boolean;
    contractNumber?: number;
};

export const AmortizationTableContentCo: React.FC<AmortizationTableContentCoProps> = ({
    hasError,
    downloadPdf,
    isSubmitting,
    contractNumber,
}) => {
    const { t } = useTranslation('amortization-table');

    const fileName = t('content.co.filename', { contractNumber });

    const content = !hasError ? (
        <Layout className={'u-mb-small'}>
            <Layout.Item className={'u-text-center'} default="1/1">
                <SectionHeading level={'3'}>{t('content.co.headline')}</SectionHeading>
            </Layout.Item>
            <Layout.Item default="1/1">
                <SectionHeading level={'5'} subtitle={t('content.co.description')}>
                    {t('content.co.sub-headline')}
                </SectionHeading>
            </Layout.Item>
            <Layout.Item default="1/1">
                {isSubmitting ? (
                    <Spinner small />
                ) : (
                    <Button onClick={() => downloadPdf(fileName)} testId="pdfDownloadLink" element="a" icon="download">
                        {t('content.co.download-link')}
                    </Button>
                )}
            </Layout.Item>
        </Layout>
    ) : (
        <Layout className={'u-mb-small'}>
            <Layout.Item default="1/1">
                <Notification
                    status={NotificationStatus.warning}
                    headline={t('error.pdf-download.title')}
                    text={t('error.pdf-download.description')}
                    testId={'pdf-download-error-notification'}
                />
            </Layout.Item>
        </Layout>
    );

    return <ContentSection className={'u-pb-small'}>{content}</ContentSection>;
};
