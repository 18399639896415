import React from 'react';
import { LicensePlate as LicensePlateShared } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export type LicensePlateProps = {
    licensePlateNumber?: string;
    size?: 'large' | 'medium' | 'small' | 'xsmall';
    className?: string;
};

export const LicensePlate: React.FC<LicensePlateProps> = ({ licensePlateNumber, size = 'medium', className }) => {
    const { t } = useTranslation('license-plate');
    if (!licensePlateNumber) return null;
    return (
        <LicensePlateShared
            className={`u-text-base${className ? ' ' + className : ''}`}
            size={size}
            country={t('country')}
            countryCode={t('country-code')}
            registrationNumber={licensePlateNumber}
            horizontalStripEu={true}
            euStars={true}
        />
    );
};
