import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Base64File } from '@cp-shared-8/apis';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from '../../navigation/paths';
import { Contract, OpenRequestData, getRequestEndpoint, ContractType, CustomerType } from '../../../common';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import { Fieldset, Form, Button, ButtonContainer, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';

import { FileUpload, getBase64 } from 'components/file-upload';

import {
    EmailInput,
    ContractSelect,
    COCategorySelect,
    LECategorySelect,
    COSubCategorySelect,
    LESubCategorySelect,
    MessageInput,
    getCOSubcategoryTranslationKey,
    getLESubcategoryTranslationKey,
    getCOSubCategoryApiKey,
    getLESubCategoryApiKey,
    getCOCategoryTranslationKey,
    getLECategoryTranslationKey,
    RequestModal,
} from './form';
import { FormikInputFields } from './form/FormikInputFields';
import { requestFormValidationSchema } from './form/Validations';

import { CpDataApi } from '../../../cp-xhr';
import { CustomerHappinessIndex } from 'components/customer-happiness-index/CustomerHappinessIndex';

export const RequestUi: React.FC<{ contactEmail: string; contracts?: Contract[]; customerType?: CustomerType }> = ({
    contactEmail,
    contracts,
    customerType,
}) => {
    const { t } = useTranslation('request');
    const { t: tUpload } = useTranslation('file-upload');
    const history = useHistory();
    const { onAction: onError } = useAnalyticsActionTracker('onRequestsError');
    const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestsSuccess');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessDialogue, setShowSuccessDialogue] = useState(false);
    const [showErrorDialogue, setShowErrorDialogue] = useState(false);
    const [currentSelectedContractType, setCurrentlySelectedContractType] = useState<ContractType | undefined>(
        undefined,
    );

    if (!contracts) {
        return null;
    }

    if (contracts.length === 0) {
        return (
            <Notification
                status={NotificationStatus.error}
                headline={t('error.no-contracts.title')}
                text={t('error.no-contracts.text')}
                testId={'NoContractsErrorNotification'}
            />
        );
    }

    const getContract = (contracts: Contract[], contractId: string): Contract => {
        const filteredContracts = contracts.filter(contract => {
            return contract.contractId === contractId;
        });
        return filteredContracts[0];
    };

    const redirectToDashboard = () => {
        history.push(dashboardPagePath());
    };
    const handleSubmit = async (input: FormikInputFields): Promise<void> => {
        function handleError(): void {
            setIsSubmitting(false);
            setShowErrorDialogue(true);
        }

        const currentContract = getContract(contracts, input.contractId);

        const contractNumber = currentContract?.contractNumber;
        const isConsumerCreditContract = currentContract?.isConsumerCredit;
        const contractType = currentContract.contractType;

        const subCategoryKey = isConsumerCreditContract
            ? getCOSubcategoryTranslationKey(input.coCategory, input.coSubCategory)
            : getLESubcategoryTranslationKey(input.leCategory, input.leSubCategory);

        const categoryKey = isConsumerCreditContract
            ? getCOCategoryTranslationKey(input.coCategory)
            : getLECategoryTranslationKey(input.leCategory);

        const subCategoryApiKey = isConsumerCreditContract
            ? getCOSubCategoryApiKey(input.coCategory, input.coSubCategory)
            : getLESubCategoryApiKey(input.leCategory, input.leSubCategory);

        const analyticsFormValues = `Category: ${t(categoryKey)} | Subcategory: ${t(subCategoryKey)}`;

        const filePromises: Promise<Base64File>[] = input.files.map(file => getBase64(file));

        const files = await Promise.all(filePromises);

        const submitRequestData: OpenRequestData = {
            categoryId: subCategoryApiKey,
            contractNumber: contractNumber,
            contractType,
            email: input.contactEmail,
            message: input.message,
            requestDescription: t(subCategoryKey),
            files,
        };

        setIsSubmitting(true);

        CpDataApi.put(getRequestEndpoint(), submitRequestData)
            .then(() => {
                onSuccess(analyticsFormValues);
                setIsSubmitting(false);
                setShowSuccessDialogue(true);
            })
            .catch(() => {
                onError(analyticsFormValues);
                handleError();
            });
    };
    const closeConfirmationDialogue = (): void => {
        setShowSuccessDialogue(false);
        setShowErrorDialogue(false);
    };

    const onSelectedContractChange = (contractId: string): void => {
        const currentlySelectedContractType = getContract(contracts, contractId)?.contractType;
        setCurrentlySelectedContractType(currentlySelectedContractType);
    };

    const initialValues: FormikInputFields = {
        contractId: '',
        contactEmail: contactEmail,
        coCategory: '',
        leCategory: '',
        coSubCategory: '',
        leSubCategory: '',
        message: '',
        files: [],
    };

    return (
        <>
            {isSubmitting && <Spinner fullPage={true} />}
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={requestFormValidationSchema(t, tUpload, currentSelectedContractType)}
            >
                {formik => {
                    const selectedCoCategory = formik.values.coCategory;
                    const selectedLeCategory = formik.values.leCategory;

                    const selectedCoSubCategory = formik.values.coSubCategory;
                    const selectedLeSubCategory = formik.values.leSubCategory;

                    const showCoCategorySelect = currentSelectedContractType === 'CO';
                    const showLeCategorySelect = currentSelectedContractType === 'LE';

                    const showCoSubcategorySelect = showCoCategorySelect && currentSelectedContractType === 'CO';
                    const showLeSubcategorySelect = showLeCategorySelect && currentSelectedContractType === 'LE';

                    const showMessage = !!selectedLeSubCategory || !!selectedCoSubCategory;

                    return (
                        <>
                            <Form onSubmit={preventSubmit}>
                                <Fieldset>
                                    <Fieldset.Row>
                                        <EmailInput label={t('form.email-label')} name={'contactEmail'} />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ContractSelect
                                            label={t('form.contract-label')}
                                            contracts={contracts}
                                            name={'contractId'}
                                            onChange={onSelectedContractChange}
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        {showCoCategorySelect && <COCategorySelect name={'coCategory'} />}
                                        {showLeCategorySelect && <LECategorySelect name={'leCategory'} />}
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        {showCoSubcategorySelect && (
                                            <COSubCategorySelect
                                                name={'coSubCategory'}
                                                selectedCategory={selectedCoCategory}
                                            />
                                        )}
                                        {showLeSubcategorySelect && (
                                            <LESubCategorySelect
                                                name={'leSubCategory'}
                                                selectedCategory={selectedLeCategory}
                                            />
                                        )}
                                    </Fieldset.Row>
                                    {showMessage && (
                                        <>
                                            <Fieldset.Row>
                                                <MessageInput name={'message'} />
                                            </Fieldset.Row>
                                            <Fieldset.Row>
                                                <FileUpload name={'files'} />
                                            </Fieldset.Row>
                                        </>
                                    )}
                                    <Fieldset.Row>
                                        <ButtonContainer nav>
                                            <Button
                                                secondary
                                                onClick={redirectToDashboard}
                                                testId="dashboardButton"
                                                type="button"
                                            >
                                                {t('form.button-labels.dashboard')}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    return formik.validateForm().then(() => {
                                                        formik.handleSubmit();
                                                    });
                                                }}
                                                testId="submitButton"
                                                type="button"
                                            >
                                                {t('form.button-labels.submit')}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Form>
                            <RequestModal
                                t={t}
                                showSuccessDialogue={showSuccessDialogue}
                                showErrorDialogue={showErrorDialogue}
                                closeConfirmationDialogue={redirectToDashboard}
                                closeErrorDialogue={closeConfirmationDialogue}
                            />
                        </>
                    );
                }}
            </Formik>

            <Layout className={'u-mt-large'}>
                <Layout.Item>{customerType && <CustomerHappinessIndex customerType={customerType} />}</Layout.Item>
            </Layout>
        </>
    );
};
