import { Base64File } from '@cp-shared-8/apis';
import { byteLength, fromByteArray } from 'base64-js';

export const getBase64 = (file: File): Promise<Base64File> => {
    return file.arrayBuffer().then((buffer: ArrayBuffer) => {
        const base64: string = fromByteArray(new Uint8Array(buffer));
        return { filename: file.name, base64: base64, size: byteLength(base64), mimeType: file.type };
    });
};

export const getFileType = (name: string): string => {
    const lastDot = name.lastIndexOf('.');
    return name.substring(lastDot + 1).toLowerCase() || '';
};
