import React from 'react';
import { useLogin } from '../../auth/useLogin';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import {
    HeroImage as TimeOutPageTeaserUi,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
    useAuthentication,
} from '@cp-shared-8/frontend-ui';
import { useTimeOutPageTeaser } from './useTimeOutPageTeaser';

export const TimeOutPageTeaser: React.FC = () => {
    const { cmsContent: timeOutTeaser, isLoading, loadingError } = useTimeOutPageTeaser();
    const { isAuthenticated } = useAuthentication();
    useAnalyticsPageViewTracker('onAutoLogout', !!timeOutTeaser, isAuthenticated);
    const { onAction } = useAnalyticsActionTracker('login');
    const login = useLogin();
    const onClick = () => {
        onAction();
        login();
    };

    const TimeOutPageTeaserWithHandlers = withLoadingAndNoConnectionHandler(TimeOutPageTeaserUi);

    return (
        <TimeOutPageTeaserWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            title={timeOutTeaser?.title || ''}
            imageUrl={timeOutTeaser?.imageUrl || ''}
            subTitle={timeOutTeaser?.subTitle}
            text={timeOutTeaser?.text}
            buttonText={timeOutTeaser?.buttonText}
            clickHandler={onClick}
            inverted={!!timeOutTeaser?.isInverted}
            shallow
        />
    );
};
