import { ContractDataWithLinks, FetchContractError, getContractsEndpoint } from '../../common';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<ContractDataWithLinks[], FetchContractError>({
    dataName: 'contracts',
    fetchCallback() {
        return CpDataApi.get(getContractsEndpoint()).then(response => response.data);
    },
});

export default reducer;
export const fetchContracts = fetchData;
