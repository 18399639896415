import React, { FC } from 'react';

import { CustomerType } from '../../../common';
import { ContactSectionUi } from './ui';
import { withLoadingHandler } from '../../integration-wrapper';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { useContactDetails } from './useContactDetails';

export type IdentificationSectionProps = {
    customerType: CustomerType;
    isProspect: boolean;
    isTabsPresent?: boolean;
};

const ContactSectionWithHandlers = withLoadingHandler(ContactSectionUi);

export const ContactSection: FC<IdentificationSectionProps> = ({ customerType, isProspect, isTabsPresent }) => {
    const { t } = useTranslation('my-profile');

    const { data: contactDetails, isLoading, loadingError } = useContactDetails(
        isTabsPresent ? 'PrivateBusinessMan' : customerType,
        isProspect,
    );

    return loadingError ? (
        <Notification
            status={NotificationStatus.error}
            headline={t('contact.error.title')}
            text={t('contact.error.text')}
            testId={'my-profile-contact-error'}
        />
    ) : (
        <ContactSectionWithHandlers isLoading={isLoading} customerType={customerType} contactDetails={contactDetails} />
    );
};
