import { CpDataApi } from 'cp-xhr';
import React from 'react';
import MockAdapter from 'axios-mock-adapter';

import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import {
    FetchContractError,
    getContractsEndpoint,
    getAmortizationTableBundledProductsDownloadLinks,
} from '../../../../common';

import { ContractsWithMultipleMixedEntries as Contracts } from 'components/contracts/ExampleData';
import { getBundledProductsDownloadLinksExampleData } from './ExampleData';

const productId = '1234';

const contract = {
    ...Contracts[0],
    _links: {
        amortizationTableBundledProductsDownloadLinks: getAmortizationTableBundledProductsDownloadLinks(
            Contracts[0].contractId,
        ),
    },
};

const mockOptionsBundledProductLinks: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: getBundledProductsDownloadLinksExampleData('123', '1234'),
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const BundledProductsDownloadLinksMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Download Links Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsBundledProductLinks,
    url: contract._links.amortizationTableBundledProductsDownloadLinks,
};

const mockOptionsContracts: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: [contract],
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const ContractsMock: EndpointMockDescription<FetchContractError> = {
    name: 'Contract Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsContracts,
    url: getContractsEndpoint(),
};

export const BundledProductsDownloadLinksResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [ContractsMock, BundledProductsDownloadLinksMock]);

    return <div>{storyFn()}</div>;
};

export function fetchBundledProductsDownloadLinksMocks(
    cpDataApiMock: MockAdapter,
    contractId: string,
    withSuccessfulResponses = true,
): void {
    if (withSuccessfulResponses) {
        cpDataApiMock.onGet(getContractsEndpoint()).reply(200, Contracts);
        cpDataApiMock
            .onGet(getAmortizationTableBundledProductsDownloadLinks(contractId))
            .reply(200, getBundledProductsDownloadLinksExampleData(contractId, productId));
    } else {
        cpDataApiMock.onGet(getContractsEndpoint()).reply(500);
    }
}
