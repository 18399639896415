import React from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatedTextarea } from '@cp-shared-8/frontend-ui';

export const MessageInput: React.FC<{ name: string }> = ({ name }) => {
    const { t } = useTranslation('request');
    const maxCharacters = 3000;

    return (
        <>
            <ValidatedTextarea
                name={name}
                id={'message'}
                testId={'message'}
                maxLength={maxCharacters}
                label={t('form.message.headline')}
                placeholder={t('form.message.placeholder')}
            />
        </>
    );
};
