import {
    AmortizationTableBundledProductsDownloadLinks,
    getAmortizationDetailsBundledProductsPdfDownloadEndpoint,
} from '../../../../common';

export const getBundledProductsDownloadLinksExampleData = (
    contractId: string,
    productId: string,
): AmortizationTableBundledProductsDownloadLinks[] => {
    return [
        {
            fileName: 'Insurance_123',
            linkName: 'Insurance',
            downloadEndpoint: getAmortizationDetailsBundledProductsPdfDownloadEndpoint(contractId, productId),
        },
    ];
};
