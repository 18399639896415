import {
    AbstractDataState,
    hasFetchedSuccessfully,
    useGetContractBasedApiData,
} from '@cp-shared-8/frontend-integration';
import { FetchContractError, getIrapCalculationDataEndpoint, IrapCalculationData } from '../../common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { useContract } from 'components/contracts/useContract';
import { useMemo } from 'react';
import { IrapCalculationDataSelector } from './IrapCalculationDataSelector';
import { fetchIrapCalculation } from './IrapCalculationDataSlice';

export function useIrapCalculation(
    contractId: string,
): AbstractDataState<IrapCalculationData, DefaultBusinessMarketApiErrorCode | FetchContractError> {
    const contractState = useContract(contractId);

    const irapState = useGetContractBasedApiData(
        contractId,
        fetchIrapCalculation,
        IrapCalculationDataSelector,
        getIrapCalculationDataEndpoint(contractId),
    );
    return useMemo(
        () => ({
            isLoading: contractState.isLoading || irapState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && irapState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? irapState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: irapState.data,
            loadingError: hasFetchedSuccessfully(contractState) ? irapState.loadingError : contractState.loadingError,
        }),
        [contractState, irapState],
    );
}
