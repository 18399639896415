import React from 'react';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PageHeader } from 'components/page-header/PageHeader';
import { RequestDocument } from '../components/request-document';

export const RequestDocumentPage: React.FC = () => {
    const { t } = useTranslation('request-document');
    const { contractId } = useParams();

    return (
        <ContentSection pageWrapSize="medium">
            <PageHeader title={t('headline')} />
            <RequestDocument contractId={contractId} />
        </ContentSection>
    );
};
