import { getLandingPageVideoEndpoint, LandingPageVideo } from '../../common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<LandingPageVideo>({
    contentName: 'landingPageVideo',
    contentEndpoint: getLandingPageVideoEndpoint,
});

export default reducer;
export const fetchLandingPageVideo = fetchContent;
