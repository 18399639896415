import { BaseFinancialDetails, LeasingFinancialDetails } from '../../../../common';
import { FinancialDetailsWithAllValues } from '../ExampleData';

const LeasingFinancialDetailsValues: LeasingFinancialDetails = {
    vatExemptShare: 430.98,
    vehiclePriceIncludedVat: 17700,
    leaseTotalAmount: 4922.8,
    redeemAmount: 10147.4,
    servicesInterestAmount: 1517.64,
    serviceCustomerTotalDueAmount: 1520.64,
    communicationCosts: 1,
};

const getLeasingFinancialDetails = (
    details: BaseFinancialDetails,
    areValuesMissing = true,
): LeasingFinancialDetails => {
    if (!details) {
        return details;
    }
    if (areValuesMissing) {
        return {
            ...details,
        } as LeasingFinancialDetails;
    } else {
        return {
            ...details,
            ...LeasingFinancialDetailsValues,
            serviceCustomerTotalDueAmount: 0,
        } as LeasingFinancialDetails;
    }
};

export const LeasingFinancialDetailsWithAllValues: LeasingFinancialDetails = getLeasingFinancialDetails(
    FinancialDetailsWithAllValues,
    false,
);

export const LeasingFinancialDetailsWithMissingValues: LeasingFinancialDetails = getLeasingFinancialDetails(
    FinancialDetailsWithAllValues,
);
