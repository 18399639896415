import { DataOverview, Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-8/frontend-ui';
import { ContactDetails, CustomerType } from '../../../../common';

export type IdentificationSectionUiProps = {
    customerType?: CustomerType;
    contactDetails?: ContactDetails;
};

export const ContactSectionUi: React.FC<IdentificationSectionUiProps> = ({ customerType, contactDetails }) => {
    const { t } = useTranslation('my-profile');

    if (!contactDetails || !customerType) {
        return null;
    }

    let identificationList: DefinitionListItem[] = [];
    contactDetails.items?.map(contactItem => {
        if (contactItem.customerType === customerType) {
            identificationList = [
                {
                    label: t('contact.email.label'),
                    tooltip: (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('contact.email.tooltip'),
                            }}
                        />
                    ),
                    value: contactItem.email ? contactItem.email : '-',
                },
                {
                    label: t('contact.pec.label'),
                    tooltip: (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('contact.pec.tooltip'),
                            }}
                        />
                    ),
                    value: contactItem.pec ? contactItem.pec : '-',
                },
                {
                    label: t('contact.mobile.label'),
                    tooltip: (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('contact.mobile.tooltip'),
                            }}
                        />
                    ),
                    value: contactItem.mobile ? contactItem.mobile : '-',
                },
                {
                    label: t('contact.phone.label'),
                    tooltip: (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('contact.phone.tooltip'),
                            }}
                        />
                    ),
                    value: contactItem.phone ? contactItem.phone : '-',
                },
            ];
        }
    });

    return (
        <>
            <DataOverview title={t('contact.headline')}>
                <Layout className={'u-mb-small'}>
                    <Layout.Item default="1/1">
                        <Notification
                            status={NotificationStatus.info}
                            text={t('contact.info-text')}
                            testId={'contact-info-text'}
                        />
                    </Layout.Item>
                </Layout>
                <DefinitionListHorizontal list={identificationList} />
            </DataOverview>
        </>
    );
};
