import React from 'react';
import { useTranslation } from 'react-i18next';

import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';

import { LeasingFinancialDetailsUi } from './ui';
import { AccordionItem } from '@cp-shared-8/frontend-ui';
import { useFinancialDetails } from '../useFinancialDetails';

const LeasingFinancialDetailsWithHandlers = withLoadingAndNoConnectionHandler(LeasingFinancialDetailsUi);

export const LeasingFinancialDetails: React.FC<{ contractId: string }> = ({ contractId }) => {
    const { t } = useTranslation('financial-details');

    const { data: leasingFinancialDetails, isLoading, loadingError } = useFinancialDetails(contractId);

    return (
        <AccordionItem title={t('headline')}>
            <LeasingFinancialDetailsWithHandlers
                isLoading={isLoading}
                leasingFinancialDetails={leasingFinancialDetails}
                hasError={!!loadingError}
            />
        </AccordionItem>
    );
};
