import { getContractsEndpoint } from './contracts';

export function getFinancialDetailsEndpoint(contractId: string): string {
    return `${getContractsEndpoint()}/${contractId}/financial-details`;
}

export function getIbanHoldersPath(): string {
    return `/iban-holders`;
}

export function getIbanHoldersEndpoint(contractId: string): string {
    return `${getFinancialDetailsEndpoint(contractId)}${getIbanHoldersPath()}`;
}

export function getChangeIbanPath(): string {
    return `/iban`;
}

export function getChangeIbanEndpoint(contractId: string): string {
    return `${getFinancialDetailsEndpoint(contractId)}${getChangeIbanPath()}`;
}
