import React from 'react';
import { Contract, CustomerType } from '../../common';
import { DashboardMarketingCard } from './DashboardMarketingCard';

type DashboardMarketingCardWithContractsProps = {
    customerType: CustomerType;
    isProspect: boolean;
    contracts: Contract[] | undefined;
    loadingError: boolean;
};

export const DashboardMarketingCardWithContracts: React.FC<DashboardMarketingCardWithContractsProps> = ({
    contracts,
    isProspect,
    customerType,
    loadingError,
}) => {
    if (!contracts || loadingError) return null;

    return <DashboardMarketingCard customerType={customerType} isProspect={isProspect} contracts={contracts} />;
};
