import React from 'react';
import { MediaTeaser as MediaTeaserShared, openInNewWindow } from '@cp-shared-8/frontend-ui';
import { LandingPageMarketingCard } from '../../../common';
import { ContentSection, PageWrap, Layout } from '@vwfs-bronson/bronson-react';

export const LandingPageMarketingCardsUi: React.FC<{ landingPageMarketingCards?: LandingPageMarketingCard[] }> = ({
    landingPageMarketingCards: cards,
}) => {
    if (!cards) {
        return null;
    }

    return (
        <ContentSection>
            <Layout>
                {cards.map((card, index) => (
                    <Layout.Item default="1/2" s="1/1" key={index}>
                        <PageWrap size={'medium'} key={index}>
                            <MediaTeaserShared
                                title={card?.title || ''}
                                imageUrl={card?.imageUrl || ''}
                                pretitle={card?.pretitle}
                                text={card?.text}
                                buttonLabel={card?.buttonLabel}
                                clickHandler={openInNewWindow(card?.buttonUrl)}
                                reversed={index % 2 === 1}
                                vertical
                            />
                        </PageWrap>
                    </Layout.Item>
                ))}
            </Layout>
        </ContentSection>
    );
};
