import { HeroImage, openInSameWindow, useAnalyticsErrorPageTracker } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';

import { useNotFoundErrorPage } from './useNotFoundErrorPage';
import { useLastLocation } from 'react-router-last-location';

export const NotFoundErrorPage: React.FC = () => {
    const { cmsContent: notFoundErrorContent, isLoading, loadingError } = useNotFoundErrorPage();
    const lastLocation = useLastLocation();

    const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

    const redirectPath = lastLocation ? lastLocation.pathname : notFoundErrorContent?.buttonUrl;

    useAnalyticsErrorPageTracker('notFoundError', !!notFoundErrorContent);

    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            pretitle={notFoundErrorContent?.pretitle}
            title={notFoundErrorContent?.title || ''}
            subTitle={notFoundErrorContent?.subTitle}
            buttonText={notFoundErrorContent?.buttonText}
            clickHandler={openInSameWindow(redirectPath)}
            inverted
        />
    );
};
