import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { AccessibilityUi } from './ui';
// import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { useAccessibility } from './useAccessibility';

const AccessibilityWithHandlers = withLoadingAndNoConnectionHandler(AccessibilityUi);

export const Accessibility: React.FC = () => {
    const { cmsContent: accessibilityContent, isLoading, loadingError } = useAccessibility();

    // TODO check with analytics tracker
    // useAnalyticsPageViewTracker('accessibility', !!accessibilityContent);

    if (!accessibilityContent) {
        return null;
    }

    return (
        <AccessibilityWithHandlers
            isLoading={isLoading}
            accessibilityContent={accessibilityContent}
            hasError={!!loadingError}
        />
    );
};
