import React, { useState } from 'react';
import { AmortizationTableHeaderProduct } from '../../../common';
import { AmortizationTableContentLe } from './leasing-contract';
import { AmortizationTableContentCo } from './consumer-credit-contract';
import { CpDataApi } from '../../../cp-xhr';
import base64ToBlob from 'b64-to-blob';
import { saveAs as downloadFileAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';

type AmortizationTableContentProps = {
    productType?: AmortizationTableHeaderProduct;
    link?: string;
    contractNumber?: number;
    contractId: string;
};
export const AmortizationTableContent: React.FC<AmortizationTableContentProps> = ({
    productType,
    link,
    contractNumber,
    contractId,
}) => {
    const { i18n } = useTranslation('amortization-table');
    const { onAction: onPdfdownload } = useAnalyticsActionTracker('onAmortizationTableDownload');

    const isLeasing = productType === 'Leasing';
    const currentLanguage = i18n.languages[0];

    const [hasError, setHasError] = useState(!link);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const downloadPdf = (fileName: string): void => {
        if (!link) {
            return;
        }
        setIsSubmitting(true);
        const requestBody = {
            locale: currentLanguage,
        };
        CpDataApi.get(link, { params: requestBody })
            .then(response => {
                const fileContent = response.data;
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(fileContent, pdfContentType);
                downloadFileAs(pdfBlob, fileName);
                onPdfdownload();
                setIsSubmitting(false);
            })
            .catch(() => {
                setIsSubmitting(false);
                setHasError(true);
            });
    };

    if (!isLeasing) {
        return (
            <AmortizationTableContentCo
                contractNumber={contractNumber}
                hasError={hasError}
                downloadPdf={downloadPdf}
                isSubmitting={isSubmitting}
            />
        );
    } else {
        return (
            <AmortizationTableContentLe
                contractId={contractId}
                contractNumber={contractNumber}
                hasError={hasError}
                downloadPdf={downloadPdf}
                isSubmitting={isSubmitting}
            />
        );
    }
};
