import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Contract, getPostboxDocumentDataEndpoint, PostboxDocument, PostboxDocumentData } from '../../../common';
import {
    Postbox,
    PostboxFilterProps,
    PostboxTableProps,
    Notification,
    NotificationStatus,
    Spinner,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import { getContractIdentifierFilter, getTimeFrameFilterItems, getDocumentTypeFilters } from './utils';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { uniq } from 'lodash';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import { CpDataApi } from 'cp-xhr';
import base64ToBlob from 'b64-to-blob';
import { Modal } from '@vwfs-bronson/bronson-react';
import { myRequestsPagePath } from 'components/navigation/paths';

export type PostboxProps = {
    contracts: Contract[];
    postboxDataError?: boolean;
    contractNumber?: string;
    postboxDocuments: PostboxDocument[];
};

type RequestStatus = {
    isPending?: boolean;
    isError?: boolean;
};

export const PostboxUi: React.FC<PostboxProps> = ({
    contracts,
    postboxDataError,
    contractNumber,
    postboxDocuments,
}) => {
    const { t } = useTranslation('postbox');

    const [requestStatus, setRequestStatus] = useState<RequestStatus>({});

    const documentTypes = useMemo(() => uniq(postboxDocuments?.map(doc => doc.class)), [postboxDocuments]);

    const sortedDocs = useMemo(
        () =>
            postboxDocuments.concat().sort((a, b) =>
                formatCpDate(a.date)
                    .toMoment()
                    .isBefore(b.date)
                    ? 1
                    : -1,
            ),
        [postboxDocuments],
    );

    const { onAction: onSuccess } = useAnalyticsActionTracker('onPostboxDownload');
    const { onAction: onError } = useAnalyticsActionTracker('onPostboxDownloadError');

    if (postboxDataError) {
        return <NoConnectionNotification />;
    } else if (postboxDocuments.length === 0) {
        return (
            <Notification status={NotificationStatus.error}>
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('notification.no-documents', {
                            link: `${myRequestsPagePath()}`,
                        }),
                    }}
                />
            </Notification>
        );
    }

    const downloadDoc = async (documentId: string, templateName: string) => {
        setRequestStatus({ isPending: true });
        CpDataApi.get(getPostboxDocumentDataEndpoint(documentId), {
            params: new URLSearchParams({ templateName }),
        })
            .then(response => {
                const { data }: PostboxDocumentData = response.data;
                saveAs(base64ToBlob(data, 'application/pdf'), templateName);
                onSuccess(templateName);
                setRequestStatus({ isPending: false, isError: false });
            })
            .catch(err => {
                onError(templateName, err);
                setRequestStatus({ isPending: false, isError: true });
            });
    };

    const postboxTable: PostboxTableProps = {
        overridedColumns: ['colDate', 'colDownload', 'colSubject'],
        overridedHeaders: [t('column.date'), t('column.download'), t('column.document')],
        documents: sortedDocs.map(doc => ({
            date: formatCpDate(doc.date).toMoment(),
            documentId: doc.documentId,
            documentType: doc.class,
            subject: doc.name,
            contractIdentifier: doc.contractNumber,
            onClick: event => {
                event?.preventDefault();
                downloadDoc(doc.documentId, doc.name);
            },
            read: true,
        })),
        noDocumentsSelectedErrorText: t('notification.no-filtered'),
        dateFormatter: (day, month, year) => `${day}-${month}-${year}`,
    };

    const postboxFilters: PostboxFilterProps = {
        contractIdentifierFilter: {
            label: t('filter.contract'),
            filterItems: !postboxDataError ? getContractIdentifierFilter(contracts) : [],
            allLabel: t('filter.all'),
        },
        documentTypeFilter: {
            label: t('filter.type'),
            filterItems: !postboxDataError ? getDocumentTypeFilters(documentTypes) : [],
            allLabel: t('filter.all'),
        },
        timeFrameFilter: {
            label: t('filter.timeframe'),
            filterItems: !postboxDataError ? getTimeFrameFilterItems(t) : [],
            allLabel: t('filter.all'),
        },
    };

    return (
        <>
            {!!requestStatus.isPending && <Spinner fullPage={true} />}
            <Postbox
                filters={postboxFilters}
                table={postboxTable}
                resetButtonText={t('filter.clear')}
                defaultContractIdentifier={contractNumber}
                withPaging
                itemsPerPage={10}
            />
            <Modal
                shown={!!requestStatus.isError}
                status="error"
                title={t('download-modal.title')}
                onClose={(): void => setRequestStatus({ isError: false })}
                testId={'postbox-document-download-error'}
            >
                {t('download-modal.text')}
            </Modal>
        </>
    );
};
