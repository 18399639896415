import { AmortizationTableBundledProductsDownloadLinks, FetchContractError } from '../../../../common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import {
    AbstractDataState,
    hasFetchedSuccessfully,
    useGetContractBasedApiData,
} from '@cp-shared-8/frontend-integration';
import { useContract } from 'components/contracts/useContract';
import { useMemo } from 'react';
import { BundledProductsDownloadLinksDataSelector } from './BundledProductsDownloadLinksDataSelector';
import { fetchBundledProductsDownloadLinks } from './BundledProductsDownloadLinksSlice';

export function useBundledProductsDownloadLinks(
    contractId: string,
): AbstractDataState<
    AmortizationTableBundledProductsDownloadLinks[],
    DefaultBusinessMarketApiErrorCode | FetchContractError
> {
    const contractState = useContract(contractId);
    const bundledProductsDownloadLinksState = useGetContractBasedApiData(
        contractId,
        fetchBundledProductsDownloadLinks,
        BundledProductsDownloadLinksDataSelector,
        contractState.data?._links?.amortizationTableBundledProductsDownloadLinks,
    );
    return useMemo(
        () => ({
            isLoading: contractState.isLoading || bundledProductsDownloadLinksState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && bundledProductsDownloadLinksState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? bundledProductsDownloadLinksState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: bundledProductsDownloadLinksState.data,
            loadingError: hasFetchedSuccessfully(contractState)
                ? bundledProductsDownloadLinksState.loadingError
                : contractState.loadingError,
        }),
        [contractState, bundledProductsDownloadLinksState],
    );
}
