import React, { useState } from 'react';
import { ContentSection, PageWrap, Layout, Logo, Tabs } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import {
    LegalNotice,
    RegistrationError,
    RegistrationResponse,
    ResentVerificationCodeRequest,
    getRegistrationVerificationCodeEndpoint,
} from '../../../common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-8/common-utilities';
import { Notification, NotificationStatus, ScrollToTopOnNavigation } from '@cp-shared-8/frontend-ui';
import { dashboardPagePath } from '../../navigation/paths';

import { useHistory } from 'react-router-dom';
import { RegistrationForm, RegistrationFormProspect } from './form';
import { RegistrationStep2FormUi } from './form/ui/step2/RegistrationStep2FormUi';
import { CpDataApi } from 'cp-xhr';
import { parseErrorResponse } from '@cp-shared-8/frontend-integration';
import { withLoadingHandler } from 'components/integration-wrapper';

const tabs = {
    private: 'private',
    business: 'business',
};

type RegistrationUiProps = {
    legalNotice?: LegalNotice;
    isProspect: boolean;
};

type ViewTypes = 'REGISTRATION' | 'SMS_CONFIRMATION';

const RegistrationStep2FormUiHandler = withLoadingHandler(RegistrationStep2FormUi);

export const RegistrationUi: React.FC<RegistrationUiProps> = ({ legalNotice, isProspect }) => {
    const { t, i18n } = useTranslation('registration');
    const [errorNotificationLabel, setErrorNotificationLabel] = useState('');
    const [errorNotificationStatus, setErrorNotificationStatus] = useState<NotificationStatus>(
        NotificationStatus.error,
    );
    const [isVerifyTanErrorNotification, setVerifyTanErrorNotification] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<ViewTypes>('REGISTRATION');
    const [registrationResponse, setRegistrationResponse] = useState<RegistrationResponse>();
    const [verificationCodeSent, setVerificationCodeSent] = useState<number>(Date.now());
    const [showVerificationSentAgainNotification, setVerificationSentAgainNotification] = useState<boolean>(false);
    const history = useHistory();
    const [isVerificationCodeLoading, setVerificationCodeLoading] = useState<boolean>(false);

    if (!legalNotice) {
        return null;
    }

    const showErrorMessage = (code?: WithDefaultCpIntegrationErrors<RegistrationError>): void => {
        setVerificationSentAgainNotification(false);
        switch (code) {
            case 'CUSTOMER_NOT_FOUND':
                setErrorNotificationLabel(t('error.not-found'));
                setErrorNotificationStatus(NotificationStatus.error);
                setVerifyTanErrorNotification(false);
                break;
            case 'CUSTOMER_ALREADY_REGISTERED':
                setErrorNotificationLabel(t('error.exists'));
                setErrorNotificationStatus(NotificationStatus.error);
                setVerifyTanErrorNotification(false);
                break;
            case 'CUSTOMER_INVALID_PHONE':
                setErrorNotificationLabel(t('error.no-phone'));
                setErrorNotificationStatus(NotificationStatus.warning);
                setVerifyTanErrorNotification(false);
                break;
            case 'TAN_ERROR':
                setErrorNotificationLabel(t('error.tan-error'));
                setErrorNotificationStatus(NotificationStatus.error);
                setVerifyTanErrorNotification(false);
                break;
            case 'VERIFY_TAN_ERROR':
                setErrorNotificationLabel(t('error.tan-error'));
                setErrorNotificationStatus(NotificationStatus.error);
                setVerifyTanErrorNotification(true);
                break;
            case 'SMS_ERROR':
                setErrorNotificationLabel(t('error.sms-error'));
                setErrorNotificationStatus(NotificationStatus.error);
                setVerifyTanErrorNotification(false);
                break;
            default:
                setErrorNotificationLabel(t('error.default'));
                setErrorNotificationStatus(NotificationStatus.error);
                setVerifyTanErrorNotification(false);
                break;
        }
    };

    const backToDashboard = (): void =>
        history.push({
            pathname: dashboardPagePath(),
            search: '?freshRegistration=true',
        });

    const onContractSubmitSuccess = (data: RegistrationResponse): void => {
        setCurrentStep('SMS_CONFIRMATION');
        setRegistrationResponse(data);
        setVerificationCodeSent(Date.now());
        setErrorNotificationLabel('');
    };

    const formProps = {
        legalNotice: legalNotice.legalNoticeContent || '',
        isProspect: isProspect,
        onSubmitError: showErrorMessage,
        onSubmitSuccess: onContractSubmitSuccess,
    };

    const resendVerificationCode = (): void => {
        setVerificationCodeLoading(true);
        setErrorNotificationLabel('');
        if (registrationResponse && verificationCodeSent + 10000 < Date.now()) {
            setVerificationCodeLoading(true);
            const requestBody: ResentVerificationCodeRequest = {
                encryptedId: registrationResponse.encryptedId,
                language: i18n.languages[0],
            };
            CpDataApi.post(getRegistrationVerificationCodeEndpoint(), requestBody)
                .then(() => {
                    setVerificationCodeSent(Date.now());
                    setVerificationCodeLoading(false);
                    setVerificationSentAgainNotification(true);
                })
                .catch(error => {
                    showErrorMessage(parseErrorResponse<RegistrationError>(error).code);
                    setVerificationCodeLoading(false);
                    setVerificationSentAgainNotification(true);
                });
        }
    };

    return (
        <>
            {currentStep === 'REGISTRATION' && (
                <>
                    <ContentSection>
                        <PageWrap size={'xsmall'}>
                            <Layout className={'u-mt-large'}>
                                <Layout.Item default={'1/1'}>
                                    <Logo className={'u-block-center'} />
                                </Layout.Item>
                                <Layout.Item default={'1/1'} className={'u-mb u-text-center'}>
                                    <h1>{t('head-step1.title')}</h1>
                                    <p>{t('head-step1.info')}</p>
                                    <p dangerouslySetInnerHTML={{ __html: t('head-step1.info-legal') }} />
                                    <h3>{t('head-step1.subtitle')}</h3>
                                    {errorNotificationLabel && (
                                        <ScrollToTopOnNavigation>
                                            <Notification testId={'errorNotification'} status={errorNotificationStatus}>
                                                <span dangerouslySetInnerHTML={{ __html: errorNotificationLabel }} />
                                            </Notification>
                                        </ScrollToTopOnNavigation>
                                    )}
                                </Layout.Item>
                                <Layout.Item>
                                    <Tabs defaultSelected={tabs.private} testId={'registrationTabs'}>
                                        <Tabs.Header>
                                            <Tabs.HeaderItem
                                                className={`cp-tab-button-${tabs.private}`}
                                                tabId={tabs.private}
                                            >
                                                {t(`tabs.${tabs.private}`)}
                                            </Tabs.HeaderItem>
                                            <Tabs.HeaderItem
                                                className={`cp-tab-button-${tabs.business}`}
                                                tabId={tabs.business}
                                            >
                                                {t(`tabs.${tabs.business}`)}
                                            </Tabs.HeaderItem>
                                        </Tabs.Header>
                                        <Tabs.Content>
                                            <Tabs.ContentItem tabId={tabs.private} testId={`${tabs.private}Tab`}>
                                                {isProspect ? (
                                                    <RegistrationFormProspect type={'private'} {...formProps} />
                                                ) : (
                                                    <RegistrationForm type={'private'} {...formProps} />
                                                )}
                                            </Tabs.ContentItem>
                                            <Tabs.ContentItem tabId={tabs.business} testId={`${tabs.business}Tab`}>
                                                {isProspect ? (
                                                    <RegistrationFormProspect type={'business'} {...formProps} />
                                                ) : (
                                                    <RegistrationForm type={'business'} {...formProps} />
                                                )}
                                            </Tabs.ContentItem>
                                        </Tabs.Content>
                                    </Tabs>
                                </Layout.Item>
                            </Layout>
                        </PageWrap>
                    </ContentSection>
                </>
            )}
            {currentStep === 'SMS_CONFIRMATION' && (
                <>
                    <ContentSection>
                        <PageWrap size={'xsmall'}>
                            <Layout className={'u-mt-large'}>
                                <Layout.Item default={'1/1'}>
                                    <Logo className={'u-block-center'} />
                                </Layout.Item>
                                <Layout.Item default={'1/1'} className={'u-mb u-text-center'}>
                                    <h1>{t('head-step2.title')}</h1>
                                    <p>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t('head-step2.subtitle', {
                                                    fourDigits: `${registrationResponse?.phoneFourDigits}`,
                                                }),
                                            }}
                                        />
                                    </p>
                                    {showVerificationSentAgainNotification && (
                                        <Notification
                                            testId={'infoNotification'}
                                            status={NotificationStatus.warning}
                                            text={t('verification-code-sent-again-info')}
                                        />
                                    )}
                                    {errorNotificationLabel && (
                                        <ScrollToTopOnNavigation>
                                            <Notification testId={'errorNotification'} status={errorNotificationStatus}>
                                                {isVerifyTanErrorNotification && (
                                                    <>
                                                        {t('error.verify-tan-error.p1')}
                                                        <a onClick={resendVerificationCode}>
                                                            {t('error.verify-tan-error.button')}
                                                        </a>
                                                        {t('error.verify-tan-error.p2')}
                                                    </>
                                                )}
                                                {!isVerifyTanErrorNotification && (
                                                    <span
                                                        dangerouslySetInnerHTML={{ __html: errorNotificationLabel }}
                                                    />
                                                )}
                                            </Notification>
                                        </ScrollToTopOnNavigation>
                                    )}
                                </Layout.Item>
                                <Layout.Item>
                                    <RegistrationStep2FormUiHandler
                                        isLoading={isVerificationCodeLoading}
                                        customerId={registrationResponse?.encryptedId}
                                        onSubmitSuccess={backToDashboard}
                                        onSubmitError={showErrorMessage}
                                    />
                                </Layout.Item>
                                <Layout.Item>
                                    <p>
                                        {t('head-step2.info-p1')}
                                        <a onClick={resendVerificationCode}>{t('head-step2.info-button')}</a>
                                        <span dangerouslySetInnerHTML={{ __html: t('head-step2.info-p2') }} />
                                    </p>
                                </Layout.Item>
                            </Layout>
                        </PageWrap>
                    </ContentSection>
                </>
            )}
        </>
    );
};
