import React, { useState } from 'react';
import { CustomerType, getCustomerHappinessIndexEndpoint } from 'common';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { useLocation } from 'react-router-dom';
import { TrustedShopsIntegrationCustomType } from '@cp-shared-8/apis';
import { CpDataApi } from 'cp-xhr';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { CustomerHappinessIndexUi } from './ui/CustomerHappinessIndexUi';

type CustomerHappinessIndexProps = {
    customerType: CustomerType;
};

const CustomerHappinessIndexWithLoadingHandler = withLoadingAndNoConnectionHandler(CustomerHappinessIndexUi);

export const CustomerHappinessIndex: React.FC<CustomerHappinessIndexProps> = ({ customerType }) => {
    const { onAction } = useAnalyticsActionTracker('chi');
    const location = useLocation();
    const [isConsentAccepted, setIsConsentAccepted] = useState(false);
    const [isOpeningSurvey, setIsOpeningSurvey] = useState(false);
    const [isSurveyError, setIsSurveyError] = useState(false);

    const getEntryPoint = (pathname: string): TrustedShopsIntegrationCustomType => {
        if (pathname.startsWith('/my-profile')) {
            return TrustedShopsIntegrationCustomType.CUSTOMERPORTAL_PROFILE;
        } else if (pathname.startsWith('/my-requests')) {
            return TrustedShopsIntegrationCustomType.CUSTOMERPORTAL_REQUEST;
        } else {
            return TrustedShopsIntegrationCustomType.CUSTOMERPORTAL;
        }
    };

    const handleButtonClick = (): void => {
        onAction();
        setIsOpeningSurvey(true);
        const entryPoint = getEntryPoint(location.pathname);

        CpDataApi.get(getCustomerHappinessIndexEndpoint('it', customerType, entryPoint))
            .then(response => {
                setIsOpeningSurvey(false);
                window.open(response?.data?.redirectUrl, '_blank');
            })
            .catch(() => {
                setIsOpeningSurvey(false);
                setIsSurveyError(true);
            });
    };

    return (
        <CustomerHappinessIndexWithLoadingHandler
            isLoading={isOpeningSurvey}
            hasError={isSurveyError}
            handleButtonClick={handleButtonClick}
            isConsentAccepted={isConsentAccepted}
            setIsConsentAccepted={setIsConsentAccepted}
        />
    );
};
