import React from 'react';
import { Redirect } from 'react-router-dom';
import { useUserRegistrationData } from '../auth';
import { dashboardPagePath, registrationPagePath } from '../components/navigation/paths';

export const ErrorAuthenticatedPage: React.FC = () => {
    const { isAuthorized } = useUserRegistrationData();

    return <Redirect to={isAuthorized ? dashboardPagePath() : registrationPagePath()} />;
};
