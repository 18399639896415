import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { useContracts } from '../contracts';
import { useGreeting } from '../greeting';
import { useContactDetailsWithGreeting } from '../my-profile/contact-section/useContactDetailsWithGreeting';
import { inferEmail } from './Helper';
import { RequestUi } from './ui';
import { CustomerType } from 'common';

const RequestWithHandlers = withLoadingAndNoConnectionHandler(RequestUi);

const RequestWithPrefetchedGreetings: React.FC<{ customerType?: CustomerType }> = ({ customerType }) => {
    const { data: contracts, isLoading: isLoadingContracts, loadingError: loadingErrorContracts } = useContracts();

    const {
        data: contactDetails,
        isLoading: isLoadingContactDetails,
        loadingError: loadingErrorContactDetails,
    } = useContactDetailsWithGreeting();
    useAnalyticsPageViewTracker('requests', !!(contracts && contactDetails));
    const isLoading = isLoadingContracts || isLoadingContactDetails;
    const hasError = !!loadingErrorContracts || !!loadingErrorContactDetails;

    const email = contactDetails?.items ? inferEmail(contactDetails.items) : '';

    return (
        <div>
            <RequestWithHandlers
                isLoading={isLoading}
                contracts={contracts}
                contactEmail={email}
                hasError={hasError}
                customerType={customerType}
            />
        </div>
    );
};

const RequestWithGreetingsAndHandlers = withLoadingAndNoConnectionHandler(RequestWithPrefetchedGreetings);
export const Request: React.FC = () => {
    const { data, isLoading, loadingError } = useGreeting();
    return (
        <RequestWithGreetingsAndHandlers
            isLoading={isLoading || (!data && !loadingError)}
            hasError={!!loadingError}
            customerType={data?.customerType}
        />
    );
};
