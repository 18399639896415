import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccordionItem } from '@cp-shared-8/frontend-ui';

import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { VehicleDetailsUi } from './ui';
import { useVehicleDetails } from './useVehicleDetails';

const VehicleDetailsWithHandlers = withLoadingAndNoConnectionHandler(VehicleDetailsUi);

type VehicleDetailsProps = {
    contractId: string;
};

export const VehicleDetails: React.FC<VehicleDetailsProps> = ({ contractId }) => {
    const { t } = useTranslation('vehicle-details');

    const { data: vehicleDetails, isLoading, loadingError } = useVehicleDetails(contractId);

    return (
        <>
            <AccordionItem title={t('headline')}>
                <VehicleDetailsWithHandlers
                    isLoading={isLoading}
                    vehicleDetails={vehicleDetails}
                    hasError={!!loadingError}
                />
            </AccordionItem>
        </>
    );
};
