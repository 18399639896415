import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentSection } from '@vwfs-bronson/bronson-react';

import { Request } from '../components/request';
import { PageHeader } from '../components/page-header/PageHeader';

export const RequestPage: React.FC = () => {
    const { t } = useTranslation('request');

    return (
        <ContentSection pageWrapSize="medium">
            <PageHeader title={t('headline')} />
            <Request />
        </ContentSection>
    );
};
