import { LeasingContractDetails } from '../../../../../common';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import {
    DataOverview,
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-8/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React from 'react';

export const GeneralSection: React.FC<{ details: LeasingContractDetails }> = ({ details }) => {
    const { contractStartDate, lastInstallmentDate, closeDateOfTheContract, successionDate } = details;
    const { t, f } = useTranslationWithFormatting('general-contract-details');

    const mandatoryFieldMissing = !contractStartDate || !lastInstallmentDate;
    const showCloseDateOfContract =
        closeDateOfTheContract && lastInstallmentDate
            ? !!(formatCpDate(lastInstallmentDate).unix() - formatCpDate(closeDateOfTheContract).unix())
            : false;

    const sectionFields: DefinitionListItem[] = [
        {
            label: t('general-section.contract-start-date.label'),
            value: f(contractStartDate, TranslationFormat.DATE),
            tooltip: t('general-section.contract-start-date.tooltip'),
        },
        {
            label: t('general-section.succession-date.label'),
            value: successionDate ? f(successionDate, TranslationFormat.DATE) : undefined,
        },
        {
            label: t('general-section.last-installment-date.label'),
            value: f(lastInstallmentDate, TranslationFormat.DATE),
            tooltip: t('general-section.last-installment-date.leasing.tooltip'),
        },
        {
            label: t('general-section.close-date-of-the-contract.label'),
            value: showCloseDateOfContract ? f(closeDateOfTheContract, TranslationFormat.DATE) : undefined,
            tooltip: t('general-section.close-date-of-the-contract.tooltip'),
        },
    ].filter(item => !!item.value);

    return (
        <DataOverview withoutCardEffect={true}>
            {mandatoryFieldMissing ? (
                <Notification status={NotificationStatus.error} headline={t('error.headline')} text={t('error.text')} />
            ) : (
                <DefinitionListHorizontal list={sectionFields} />
            )}
        </DataOverview>
    );
};
