import React from 'react';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { TaxesTable } from '../components/taxes-table';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../components/page-header/PageHeader';

export const TaxesTablePage: React.FC = () => {
    const { t } = useTranslation('taxes-table');
    const { contractId } = useParams();
    return (
        <ContentSection pageWrapSize="medium">
            <PageHeader title={t('headline')} />
            <TaxesTable contractId={contractId} />
        </ContentSection>
    );
};
