import { ContractType } from '../../../../common';
import { getFileType, maxFileSize, validFileFormats } from 'components/file-upload';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { FormikInputFields } from './FormikInputFields';

const maxMessageLength = 3000;

const baseValidationSchema = (t: TFunction, tUpload: TFunction): Yup.ObjectSchema => {
    const validations: Record<keyof FormikInputFields, Yup.StringSchema | Yup.ArraySchema<File>> = {
        contactEmail: Yup.string()
            .trim()
            .required(t('form.errors.email-missing'))
            .email(t('form.errors.email-missing')),
        contractId: Yup.string().required(t('form.errors.contract-missing')),
        message: Yup.string().max(maxMessageLength, t('form.errors.message-invalid')),
        coCategory: Yup.string(),
        coSubCategory: Yup.string(),
        leCategory: Yup.string(),
        leSubCategory: Yup.string(),
        files: Yup.array<File>()
            .test('file-amount', tUpload('error-messages.file-amount'), files => files.length <= 4)
            .test(
                'file-size',
                tUpload('error-messages.file-size-sum'),
                files => files.reduce((sum: number, file: File) => sum + (file.size || 0), 0) < maxFileSize,
            )
            .test(
                'file-type',
                tUpload(' '),
                files => !files.find((file: File) => !validFileFormats.includes(getFileType(file.name || ''))),
            ),
    };

    return Yup.object<FormikInputFields>().shape(validations);
};

const requestCoFormValidationSchema = (t: TFunction, tUpload: TFunction): Yup.ObjectSchema => {
    const validations: Record<keyof FormikInputFields, Yup.StringSchema | Yup.ArraySchema<File>> = {
        contactEmail: Yup.string()
            .trim()
            .required(t('form.errors.email-missing'))
            .email(t('form.errors.email-missing')),
        contractId: Yup.string().required(t('form.errors.contract-missing')),
        coCategory: Yup.string().required(t('form.errors.category-missing')),
        coSubCategory: Yup.string().required(t('form.errors.sub-category-missing')),
        leCategory: Yup.string(),
        leSubCategory: Yup.string(),
        message: Yup.string().max(maxMessageLength, t('form.errors.message-invalid')),
        files: Yup.array<File>()
            .test('file-amount', tUpload('error-messages.file-amount'), files => files.length <= 4)
            .test(
                'file-size',
                tUpload('error-messages.file-size-sum'),
                files => files.reduce((sum: number, file: File) => sum + (file.size || 0), 0) <= maxFileSize,
            )
            .test(
                'file-type',
                tUpload(' '),
                files => !files.find((file: File) => !validFileFormats.includes(getFileType(file.name || ''))),
            ),
    };

    return Yup.object<FormikInputFields>().shape(validations);
};

const requestLeFormValidationSchema = (t: TFunction, tUpload: TFunction): Yup.ObjectSchema => {
    const validations: Record<keyof FormikInputFields, Yup.StringSchema | Yup.ArraySchema<File>> = {
        contactEmail: Yup.string()
            .trim()
            .required(t('form.errors.email-missing'))
            .email(t('form.errors.email-missing')),
        contractId: Yup.string().required(t('form.errors.contract-missing')),
        leCategory: Yup.string().required(t('form.errors.category-missing')),
        leSubCategory: Yup.string().required(t('form.errors.sub-category-missing')),
        coCategory: Yup.string(),
        coSubCategory: Yup.string(),
        message: Yup.string().max(maxMessageLength, t('form.errors.message-invalid')),
        files: Yup.array<File>()
            .test('file-amount', tUpload('error-messages.file-amount'), files => files.length <= 4)
            .test(
                'file-size',
                tUpload('error-messages.file-size-sum'),
                files => files.reduce((sum: number, file: File) => sum + (file.size || 0), 0) < maxFileSize,
            )
            .test(
                'file-type',
                tUpload(' '),
                files => !files.find((file: File) => !validFileFormats.includes(getFileType(file.name || ''))),
            ),
    };

    return Yup.object<FormikInputFields>().shape(validations);
};

export const requestFormValidationSchema = (
    t: TFunction,
    tUpload: TFunction,
    contractType?: ContractType,
): Yup.ObjectSchema => {
    if (contractType === 'CO') {
        return requestCoFormValidationSchema(t, tUpload);
    }

    if (contractType === 'LE') {
        return requestLeFormValidationSchema(t, tUpload);
    }

    return baseValidationSchema(t, tUpload);
};
