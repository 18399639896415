import React from 'react';
import { ContractData } from '../../../common';
import { ContractHeader as ContractHeaderShared } from '@cp-shared-8/frontend-ui';
import { LicensePlate } from '../../license-plate';

export type ContractHeaderProps = {
    contract: ContractData;
    contractSummary?: React.ReactNode;
};

export const ContractHeader: React.FC<ContractHeaderProps> = ({ contract, contractSummary }) => {
    const { contractType, vehicleInformation, contractNumber, licensePlateNumber } = contract;

    const licensePlate = <LicensePlate licensePlateNumber={licensePlateNumber} />;

    return (
        <ContractHeaderShared
            carInformation={vehicleInformation}
            contractCategory={contractType}
            contractNumber={contractNumber}
            contractIdentifier={licensePlate}
            contractSummary={contractSummary}
        />
    );
};
