import { CpDataApi } from 'cp-xhr';
import React from 'react';

import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError } from '@cp-shared-8/common-utilities';

import { ContractsWithAllValues } from '../contracts/ExampleData';
import { FetchContractError, getContractsEndpoint } from '../../common';

const mockOptions: MockOptions<FetchContractError> = {
    Success: {
        status: 200,
        responseBody: ContractsWithAllValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const RequestMock: EndpointMockDescription<FetchContractError> = {
    name: 'Request response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getContractsEndpoint(),
};

export const RequestResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [RequestMock]);

    return <div>{storyFn()}</div>;
};
