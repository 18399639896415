import { LegalNotice } from '../../../common';
import { HeroImage } from '@cp-shared-8/frontend-ui';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';

export const LegalNoticeUi: React.FC<{ legalNotice?: LegalNotice }> = ({ legalNotice }) => {
    if (!legalNotice) {
        return null;
    }

    const { teaser, legalNoticeContent } = legalNotice;

    const teaserComponent = teaser ? (
        <HeroImage
            title={teaser.title}
            imageUrl={teaser.imageUrl}
            shallow={true}
            inverted={!!teaser.isInverted}
            focalPoint={teaser.focalPoint}
        />
    ) : null;

    const legalNoticeContentComponent = legalNoticeContent ? (
        <ContentSection pageWrapSize="medium">
            <div dangerouslySetInnerHTML={{ __html: legalNoticeContent }}></div>
        </ContentSection>
    ) : null;

    return (
        <>
            {teaserComponent}
            {legalNoticeContentComponent}
        </>
    );
};
