import { Contract } from '../../../common';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { ContractIdentifierFilterItem, DocumentTypeFilterItem, TimeFrameFilterItem } from '@cp-shared-8/frontend-ui';
import { LicensePlate } from 'components/license-plate';
import { TFunction } from 'i18next';
import React from 'react';

export type PostboxTimePeriods = {
    last3months: string;
    last6months: string;
    last12months: string;
    last2years: string;
};

export const getDocumentTypeFilters = (documentTypes: string[]): DocumentTypeFilterItem[] =>
    documentTypes.map(docType => ({
        documentType: docType,
        value: docType,
    }));

export const getContractIdentifierFilter = (contracts: Contract[]): ContractIdentifierFilterItem[] =>
    contracts.map(contract => ({
        contractIdentifier: contract.contractNumber,
        reactNode: (
            <div className="u-block">
                <LicensePlate licensePlateNumber={contract.licensePlateNumber} />
                <br />
                <strong>{`${contract.product} ${contract.contractNumber}`}</strong>
                <br />
                {contract.vehicleInformation || ''}
            </div>
        ),
        value: contract.contractNumber,
    }));

export const getTimeFrameFilterItems = (t: TFunction): TimeFrameFilterItem[] => [
    {
        from: formatCpDate()
            .subtract(3, 'months')
            .toMoment(),
        to: formatCpDate().toMoment(),
        value: t('timeframes.3-months'),
        key: '3m',
    },
    {
        from: formatCpDate()
            .subtract(6, 'months')
            .toMoment(),
        to: formatCpDate().toMoment(),
        value: t('timeframes.6-months'),
        key: '6m',
    },
    {
        from: formatCpDate()
            .subtract(12, 'months')
            .toMoment(),
        to: formatCpDate().toMoment(),
        value: t('timeframes.12-months'),
        key: '12m',
    },
    {
        from: formatCpDate()
            .subtract(2, 'years')
            .toMoment(),
        to: formatCpDate().toMoment(),
        value: t('timeframes.2-years'),
        key: '2y',
    },
];
