import { LeasingContract, TaxesTableHeader } from '../../../common';
import React from 'react';

import { TaxesTableContentUi } from './taxes-table-content';
import { TaxesTableHeaderUi } from './taxes-table-header';

export const TaxesTableUi: React.FC<{
    taxesTableHeader?: TaxesTableHeader;
    contract?: LeasingContract;
}> = ({ taxesTableHeader, contract }) => {
    if (!taxesTableHeader || !contract) {
        return null;
    }

    return (
        <>
            <TaxesTableHeaderUi taxesTableHeader={taxesTableHeader} contract={contract} />
            <TaxesTableContentUi contractId={contract.contractId} contractNumber={contract.contractNumber} />
        </>
    );
};
