import { CPDate } from '@cp-shared-8/common-utilities';

export const NUMERIC_DATE_FORMAT = 'YYYYMMDD';

export type ProspectStatus = {
    contractNumber: string;
    product: string;
    status?: string;
    data: {
        vehicleData?: {
            vin?: string;
            model?: string;
            licencePlate?: string;
        };
        financialProduct?: {
            contractStartDate?: CPDate;
            contractEndDate?: CPDate;
            durationInYears?: number;
            status?: string;
            serviceProvider?: string;
            mileage?: number;
        };
    };
};

export type ProspectMarketingPreferences = {
    email?: boolean;
    phone?: boolean;
    sms?: boolean;
    post?: boolean;
};
