import { ServiceType } from '../../../../common';
import { TFunction } from 'i18next';

/**
 * Returns the service type translation for the corresponding service type code.
 * Mapping is accounted for by the backend.
 * @param serviceType
 * @param t
 */
export const getServiceTypeTranslationMapping = (serviceType: ServiceType, t: TFunction): string => {
    return t(`service-type.${serviceType.replace('_', '-').toLowerCase()}`);
};

/**
 * Returns the service name translation for the corresponding service type code.
 * @param serviceType
 * @param serviceName
 * @param t
 */
export const getServiceNameTranslationMapping = (
    serviceType: ServiceType,
    serviceName: string,
    t: TFunction,
): string => {
    return serviceType === 'MAINTENANCE' ? t('service-name.service-plan') : serviceName;
};
