import React from 'react';

import { MyProfileUi } from './ui';
import { withLoadingHandler } from '../integration-wrapper';
import { Notification, NotificationStatus, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { useGreeting } from '../greeting';
import { useProspectData } from '../../auth';

const MyProfileWithHandlers = withLoadingHandler(MyProfileUi);

export const MyProfile: React.FC = () => {
    const { t } = useTranslation('my-profile');
    const { isAuthorized: isProspect } = useProspectData();
    const { data: greeting, isLoading, loadingError } = useGreeting(isProspect);

    useAnalyticsPageViewTracker('profile', !!greeting);

    return loadingError ? (
        <Notification
            status={NotificationStatus.error}
            headline={t('error.title')}
            text={t('error.text')}
            testId={'my-profile-customer-type-error'}
        />
    ) : (
        <MyProfileWithHandlers isLoading={isLoading} customerType={greeting?.customerType} isProspect={isProspect} />
    );
};
