import { getAccessibilityEndpoint, Accessibility } from '../../common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<Accessibility>({
    contentName: 'accessibility',
    contentEndpoint: getAccessibilityEndpoint,
});

export default reducer;
export const fetchAccessibility = fetchContent;
