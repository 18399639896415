import React from 'react';
import { Combobox, FormFieldLabel } from '@vwfs-bronson/bronson-react';
import { Contract } from '../../../../common';
import { useTranslation } from 'react-i18next';
import { LicensePlate } from '../../../license-plate';
import { useField } from 'formik';

type ContractSelectProps = {
    label: string;
    contracts: Contract[];
    name: string;
    onChange?: (optionKey: string) => void;
};

interface ComboboxOption {
    key: string;
    value: string;
    disabled?: boolean;
}

export const ContractSelect: React.FC<ContractSelectProps> = ({ label, contracts = [], name, onChange }) => {
    const { t } = useTranslation('request');
    const [, , helpers] = useField(name);
    const handlerOnChange = React.useCallback(
        (selected: ComboboxOption[]): void => {
            if (onChange) {
                onChange(selected?.[0]?.key);
                helpers.setValue(selected?.[0]?.key);
            }
        },
        [onChange], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const contractsUnifiedTypes = contracts.map(contract => (
        <Combobox.Item
            key={contract.contractNumber}
            optionValue={contract.contractNumber}
            optionKey={contract.contractId}
            testId={contract.contractId}
        >
            <div>
                {contract.licensePlateNumber && (
                    <>
                        <LicensePlate size={'xsmall'} licensePlateNumber={contract.licensePlateNumber}></LicensePlate>
                    </>
                )}
                <br />
                <strong>{contract.contractNumber}</strong>
                <br />
                <strong>{contract.contractType}</strong> {contract.isExpired ? t('form.expired-contract') : ''}
                <br />
                {contract.vehicleInformation}
            </div>
        </Combobox.Item>
    ));

    return (
        <>
            <FormFieldLabel>{label}</FormFieldLabel>
            <Combobox onChange={handlerOnChange} name={name} testId={name}>
                {contractsUnifiedTypes}
            </Combobox>
        </>
    );
};
