import { FetchContractError, TaxesTableHeader } from '../../common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import {
    AbstractDataState,
    hasFetchedSuccessfully,
    useGetContractBasedApiData,
} from '@cp-shared-8/frontend-integration';
import { useContract } from 'components/contracts/useContract';
import { useMemo } from 'react';
import { TaxesTableHeaderDataSelector } from './TaxesTableHeaderDataSelector';
import { fetchTaxesTableHeader } from './TaxesTableHeaderSlice';

export function useTaxesTableHeader(
    contractId: string,
): AbstractDataState<TaxesTableHeader, DefaultBusinessMarketApiErrorCode | FetchContractError> {
    const contractState = useContract(contractId);

    const taxesTableHeaderState = useGetContractBasedApiData(
        contractId,
        fetchTaxesTableHeader,
        TaxesTableHeaderDataSelector,
        contractState.data?._links?.taxesTableHeader,
    );

    return useMemo(
        () => ({
            isLoading: contractState.isLoading || taxesTableHeaderState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && taxesTableHeaderState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? taxesTableHeaderState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: taxesTableHeaderState.data,
            loadingError: hasFetchedSuccessfully(contractState)
                ? taxesTableHeaderState.loadingError
                : contractState.loadingError,
        }),
        [contractState, taxesTableHeaderState],
    );
}
