import { LeasingContract } from '../../common';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { useContract } from 'components/contracts';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';

import { TaxesTableUi } from './ui';
import { useTaxesTableHeader } from './useTaxesTableHeader';

const TaxesTableWithHandlers = withLoadingAndNoConnectionHandler(TaxesTableUi);

type TaxesTableProps = {
    contractId: string;
};

export const TaxesTable: React.FC<TaxesTableProps> = ({ contractId }) => {
    const {
        data: taxesTableHeader,
        isLoading: isLoadingTaxesTableHeader,
        loadingError: loadingErrorTaxesTable,
    } = useTaxesTableHeader(contractId);
    useAnalyticsPageViewTracker('onMotorTax', !!taxesTableHeader);

    const contractState = useContract(contractId);

    return (
        <>
            <TaxesTableWithHandlers
                isLoading={isLoadingTaxesTableHeader}
                taxesTableHeader={taxesTableHeader}
                contract={contractState.data as LeasingContract}
                hasError={!!loadingErrorTaxesTable}
            />
        </>
    );
};
