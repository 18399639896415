import { ContentSection, Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { DashboardMarketingCardWithContracts } from 'components/dashboard-marketing-card/DashboardMarketingCardWithContracts';
import { Greeting } from 'components/greeting/Greeting';
import { DashboardTitle } from 'components/dashboard-title/DashboardTitle';
import { Contracts, useContracts } from 'components/contracts';
import { useGreeting } from 'components/greeting';
import { useProspectData } from 'auth';
import { withLoadingHandler } from '../../components/integration-wrapper';
import { useLastLocation } from 'react-router-last-location';
import { useAnalyticsDashboardPageTracker } from '@cp-shared-8/frontend-ui';
import { CustomerHappinessIndex } from 'components/customer-happiness-index/CustomerHappinessIndex';
import { registrationPagePath, registrationAnalyticsPath, loginAnalyticsPath } from 'components/navigation/paths';
import { useLocation } from 'react-router';

const DashboardMarketingCardWithContractsAndHandlers = withLoadingHandler(DashboardMarketingCardWithContracts);

export const DashboardPage: React.FC = () => {
    const { data: contracts, isLoading: isLoadingContracts, loadingError: loadingContractsError } = useContracts();
    const { data: greeting, isLoading: isLoadingGreeting, loadingError: loadingGreetingError } = useGreeting();
    const { isAuthorized: isProspect } = useProspectData();
    const unpaid = contracts && contracts.some(contract => contract.unpaidAlert);
    const { search } = useLocation();
    const lastLocation = useLastLocation();
    const lastPathname = lastLocation === null ? loginAnalyticsPath() : lastLocation?.pathname;
    let submitLastPath = true;

    const queryParams = new URLSearchParams(search);
    const freshRegistration = queryParams.get('freshRegistration');
    if (lastPathname === registrationPagePath() && freshRegistration !== 'true') {
        submitLastPath = false;
    }
    useAnalyticsDashboardPageTracker(
        !!contracts?.length,
        !!unpaid,
        submitLastPath ? lastPathname : registrationAnalyticsPath(),
    );

    return (
        <ContentSection pageWrapSize="medium">
            <DashboardTitle />
            <Greeting greeting={greeting} isLoading={isLoadingGreeting} loadingError={!!loadingGreetingError} />
            <Layout>
                <Layout.Item default={'2/3'} m={'1/1'}>
                    <Contracts
                        contracts={contracts}
                        isLoading={isLoadingContracts}
                        loadingError={!!loadingContractsError}
                    />
                    {greeting?.customerType && <CustomerHappinessIndex customerType={greeting.customerType} />}
                </Layout.Item>
                <Layout.Item default={'1/3'} m={'1/1'}>
                    {greeting?.customerType && (
                        <DashboardMarketingCardWithContractsAndHandlers
                            customerType={greeting.customerType}
                            isProspect={isProspect}
                            contracts={contracts}
                            isLoading={isLoadingContracts || isLoadingGreeting}
                            loadingError={!!loadingContractsError || !!loadingGreetingError}
                        />
                    )}
                </Layout.Item>
            </Layout>
        </ContentSection>
    );
};
