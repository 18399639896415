export function getRegistrationEndpoint(): string {
    return '/verify-register';
}

export function getRegistrationVerificationCodeEndpoint(): string {
    return '/send-reigstration-verification-code';
}

export function getVerifyRegistrationCodeEndpoint(): string {
    return '/verify-reigstration-code';
}
