import React from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';
import { getCOCategoryNames, getCOCategoryTranslationKey } from './categories';
import { CategorySelectProps } from './CategorySelectProps';

export const COCategorySelect: React.FC<CategorySelectProps> = ({ name }) => {
    const { t } = useTranslation('request');

    const categoryNames: string[] = getCOCategoryNames();

    const categoriesSelectItems: ValidatedSelectItem[] = categoryNames.map((category: string) => {
        const categoryEntryText = t(getCOCategoryTranslationKey(category));
        return {
            label: categoryEntryText,
            value: category,
        };
    });

    return (
        <>
            <ValidatedSelect
                label={t(`form.category.headline`)}
                name={name}
                selectItems={categoriesSelectItems}
                testId={name}
                placeholder={t('form.category.placeholder')}
                disablePlaceholder={true}
            />
        </>
    );
};
