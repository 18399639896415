import { CpDataApi } from 'cp-xhr';
import React from 'react';

import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';

import { getGreetingEndpoint, Greeting } from '../../common';

import { GreetingWithPrivate, GreetingWithUndefinedCustomerName } from './ExampleData';
import MockAdapter from 'axios-mock-adapter';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: GreetingWithPrivate,
    },
    'Undefined values': {
        status: 200,
        responseBody: GreetingWithUndefinedCustomerName,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const GreetingMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Greeting response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getGreetingEndpoint(),
};

export const GreetingResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [GreetingMock]);

    return <div>{storyFn()}</div>;
};

export function mockGreetingResponses(httpResponse: number, greeting?: Greeting, cpApiMock?: MockAdapter): void {
    let apiMock = cpApiMock;
    if (!apiMock) {
        apiMock = new MockAdapter(CpDataApi);
        apiMock.reset();
    }
    apiMock.onGet(getGreetingEndpoint()).reply(httpResponse, greeting);
}
