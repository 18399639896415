import React from 'react';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../components/page-header/PageHeader';
import { Postbox } from 'components/postbox/Postbox';
import { useParams } from 'react-router-dom';

export const PostboxPage: React.FC = () => {
    const { t } = useTranslation('postbox');
    const { contractId } = useParams();

    return (
        <ContentSection pageWrapSize="medium">
            <PageHeader title={t('headline')} />
            <Heading level={2}>{t('sub-headline')}</Heading>
            <p>{t('information')}</p>
            <Postbox contractId={contractId} />
        </ContentSection>
    );
};
