import { AbstractDataState, useGetContractBasedApiData } from '@cp-shared-8/frontend-integration';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { VehicleServiceDetailsDataSelector } from './VehicleServiceDetailsDataSelector';
import { getVehicleServiceEndpoint, VehicleServiceResponse } from '@cp-shared-8/apis';
import { fetchVehicleServiceDetails } from './VehicleServiceDetailsSlice';

export function useVehicleServiceDetails(
    encryptedVin: string,
): AbstractDataState<VehicleServiceResponse, DefaultBusinessMarketApiErrorCode> {
    return useGetContractBasedApiData(
        encryptedVin,
        fetchVehicleServiceDetails,
        VehicleServiceDetailsDataSelector,
        getVehicleServiceEndpoint(encryptedVin, 'en'),
    );
}
