import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { VehicleImageServiceResponse, VehicleImageView, VehicleServiceResponse } from '@cp-shared-8/apis';
import { useTranslation } from 'react-i18next';
import { DefinitionListHorizontal, DefinitionListItem, Skeleton } from '@cp-shared-8/frontend-ui';
import {
    capitalizeWord,
    getBrandTranslation,
    getColorFinishTranslation,
    getColorTranslation,
    getTransmissionTranslation,
} from './helpers';
import { TFunction } from 'i18next';
import { compact } from 'lodash';
import { LicensePlate } from '../../../license-plate';
import { Heading, ProductInfo } from '@vwfs-bronson/bronson-react';
import { CollapsibleVehicleDetails } from './collapsible-vehicle-details/CollapsibleVehicleDetails';

export const VehicleDetailsAndImageUi: React.FC<{
    vin: string;
    brand: string;
    vehicleInformation: string;
    licensePlate?: string;
    vehicleDetails?: VehicleServiceResponse;
    isLoadingVehicleDetails?: boolean;
    vehicleDetailsLoadingError: boolean;
    vehicleImages?: VehicleImageServiceResponse;
    isLoadingVehicleImage?: boolean;
    vehicleImageLoadingError: boolean;
    defaultView: VehicleImageView;
}> = ({
    vin,
    brand,
    vehicleInformation,
    licensePlate,
    vehicleDetails,
    isLoadingVehicleDetails,
    vehicleDetailsLoadingError,
    vehicleImages,
    isLoadingVehicleImage,
    vehicleImageLoadingError,
    defaultView,
}) => {
    const { t } = useTranslation('vehicle-details-and-image');
    const [title, setTitle] = useState<React.ReactNode | string>(<Skeleton height={40} />);
    const [subtitle, setSubtitle] = useState('');

    useEffect(() => {
        if (vehicleDetailsLoadingError) {
            setTitle(vehicleInformation);
        }
        if (vehicleDetails) {
            const {
                brand = '',
                modelName = '',
                modelVariant = '',
                transmission = '',
                bodyColorName = '',
                bodyColorFinish = '',
                trim = '',
            } = vehicleDetails || {};
            setTitle(
                [getBrandTranslation(brand, t), modelName, modelVariant, trim]
                    .filter(Boolean)
                    .map(capitalizeWord)
                    .join(' ') || vehicleInformation,
            );
            setSubtitle(
                [
                    getTransmissionTranslation(transmission, t),
                    getColorTranslation(bodyColorName, t),
                    getColorFinishTranslation(bodyColorFinish, t),
                ]
                    .filter(Boolean)
                    .map(capitalizeWord)
                    .join(' | '),
            );
        }
    }, [vehicleDetails, vehicleDetailsLoadingError, vehicleInformation, t]);

    const getBasicDetails = useCallback((t: TFunction, vin: string, licensePlate?: string): DefinitionListItem[] => {
        return compact([
            {
                label: t('license-plate'),
                value: <LicensePlate licensePlateNumber={licensePlate} />,
                testId: 'license-plate',
            },
            {
                label: t('vin'),
                value: vin,
                testId: 'vin',
            },
        ]);
    }, []);

    const itemList: DefinitionListItem[] = useMemo(() => getBasicDetails(t, vin, licensePlate), [
        getBasicDetails,
        t,
        vin,
        licensePlate,
    ]);

    const getImageSrc = () => {
        const image = vehicleImages?.images?.find(({ view }) => view === defaultView);
        const showDefault = vehicleImageLoadingError || (!isLoadingVehicleImage && !image);
        if (!isLoadingVehicleImage && image) {
            return image.url;
        }
        if (showDefault) {
            switch (brand) {
                case 'Audi':
                    return '/contents/vwfs/audi_default.svg';
                case 'Seat':
                    return '/contents/vwfs/seat_default.svg';
                case 'SKODA':
                    return '/contents/vwfs/skoda_default.svg';
                case 'VW':
                    return '/contents/vwfs/vw_default.svg';
                default:
                    return '/contents/vwfs/vw_default.svg';
            }
        }
        return '';
    };

    return (
        <>
            <Heading level={'3'} className={'c-product-info__title u-mb-none'}>
                {title}
            </Heading>
            <ProductInfo titleSuffix={subtitle} media={<img alt="Vehicle" src={getImageSrc()} />} className={'u-mb'}>
                <div className={'u-mt-large u-hide@s'} />
                <DefinitionListHorizontal list={itemList} testId={'basic-details'} />
                <CollapsibleVehicleDetails details={vehicleDetails} isLoading={isLoadingVehicleDetails} />
            </ProductInfo>
        </>
    );
};
