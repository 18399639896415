import { ScrollToTopOnNavigation, Spinner } from '@cp-shared-8/frontend-ui';
import {
    amortizationTablePath,
    dashboardPagePath,
    earlySettlementPath,
    errorPagePath,
    errorAuthenticatedPagePath,
    faqPagePath,
    forbiddenPagePath,
    irapCalculationPath,
    landingPagePath,
    legalNoticePath,
    logoutPagePath,
    myProfilePagePath,
    myRequestsPagePath,
    notAuthorizedPagePath,
    notFoundPagePath,
    offlinePaymentPath,
    postboxPagePath,
    postboxPagePathWithContractId,
    registrationPagePath,
    requestDocumentPath,
    taxesTablePagePath,
    thirdPartyLicensesPagePath,
    timeOutPagePath,
    accessibilityPagePath,
} from 'components/navigation/paths';
import { ConnectedRouter } from 'connected-react-router';
import { PostboxPage } from 'pages/PostboxPage';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { AuthenticatedRoute, AuthorizedRoute, AuthProviders, AxiosConfiguration } from './auth';
import {
    AmortizationTablePage,
    DashboardPage,
    EarlySettlementPage,
    ErrorAuthenticatedPage,
    ErrorPage,
    FaqPage,
    ForbiddenPage,
    LandingPage,
    LegalNoticePage,
    LogoutPage,
    MasterPage,
    MyProfilePage,
    NotAuthorizedPage,
    NotFoundPage,
    RegistrationPage,
    RequestDocumentPage,
    RequestPage,
    TaxesTablePage,
    TimeOutPage,
} from './pages';
import { ThirdPartyLicensesPage } from './pages/third-party-licenses-page';
import { AccessibilityPage } from './pages/accessibility-page';
import { history } from './store/rootReducer';
import { IrapCalculationPage } from './pages/irap-calculation-page/IrapCalculationPage';

const scrollToTopExceptions = [
    { from: earlySettlementPath('[\\w-]+'), to: offlinePaymentPath('[\\w-]+') },
    { from: offlinePaymentPath('[\\w-]+'), to: earlySettlementPath('[\\w-]+') },
];

const App: React.FC = () => {
    return (
        <div className="App">
            <Suspense fallback={<Spinner center />}>
                <AuthProviders>
                    <AxiosConfiguration>
                        <ConnectedRouter history={history}>
                            <LastLocationProvider>
                                <ScrollToTopOnNavigation exceptions={scrollToTopExceptions} />
                                <MasterPage>
                                    <Switch>
                                        <Route path="*">
                                            <Switch>
                                                <Route exact path={landingPagePath()}>
                                                    <LandingPage />
                                                </Route>
                                                <Route path={faqPagePath()}>
                                                    <FaqPage />
                                                </Route>
                                                <Route path={legalNoticePath()}>
                                                    <LegalNoticePage />
                                                </Route>
                                                <Route path={thirdPartyLicensesPagePath()}>
                                                    <ThirdPartyLicensesPage />
                                                </Route>
                                                <Route path={accessibilityPagePath()}>
                                                    <AccessibilityPage />
                                                </Route>
                                                <AuthorizedRoute path={dashboardPagePath()}>
                                                    <DashboardPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={myProfilePagePath()}>
                                                    <MyProfilePage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={myRequestsPagePath()}>
                                                    <RequestPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={amortizationTablePath()}>
                                                    <AmortizationTablePage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={taxesTablePagePath()}>
                                                    <TaxesTablePage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={earlySettlementPath()}>
                                                    <EarlySettlementPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={irapCalculationPath()}>
                                                    <IrapCalculationPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={requestDocumentPath()}>
                                                    <RequestDocumentPage />
                                                </AuthorizedRoute>
                                                <AuthenticatedRoute path={registrationPagePath()}>
                                                    <RegistrationPage />
                                                </AuthenticatedRoute>
                                                <AuthorizedRoute path={postboxPagePath()}>
                                                    <PostboxPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={postboxPagePathWithContractId()}>
                                                    <PostboxPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={errorAuthenticatedPagePath()}>
                                                    <ErrorAuthenticatedPage />
                                                </AuthorizedRoute>
                                                <Route path={logoutPagePath()}>
                                                    <LogoutPage />
                                                </Route>
                                                <Route path={timeOutPagePath()}>
                                                    <TimeOutPage />
                                                </Route>
                                                <Route path={errorPagePath()}>
                                                    <ErrorPage />
                                                </Route>
                                                <Route path={forbiddenPagePath()}>
                                                    <ForbiddenPage />
                                                </Route>
                                                <Route path={notAuthorizedPagePath()}>
                                                    <NotAuthorizedPage />
                                                </Route>
                                                <Route path={notFoundPagePath()}>
                                                    <NotFoundPage />
                                                </Route>
                                                <Route path="*">
                                                    <Redirect to={notFoundPagePath()} />
                                                </Route>
                                            </Switch>
                                        </Route>
                                    </Switch>
                                </MasterPage>
                            </LastLocationProvider>
                        </ConnectedRouter>
                    </AxiosConfiguration>
                </AuthProviders>
            </Suspense>
        </div>
    );
};

export default App;
