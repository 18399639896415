import React from 'react';
import { CpDataApi } from 'cp-xhr';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getIrapCalculationDataEndpoint } from '../../common';
import { IrapCalculationWithAllValues } from './ExampleData';
import { ContractsWithAllValues } from '../contracts/ExampleData';

const mockIrapCalculation: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: IrapCalculationWithAllValues,
        delay: 1000,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const irapCalculationMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'IRAP Calculation Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockIrapCalculation,
    url: getIrapCalculationDataEndpoint(ContractsWithAllValues[0].contractId),
};

export const IrapCalculationResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [irapCalculationMock]);

    return <div>{storyFn()}</div>;
};
