import { AbstractContractBasedDataState } from '@cp-shared-8/frontend-integration';

const KNOWN_BRANDS = ['audi', 'vw', 'volkswagen', 'skoda', 'seat', 'vic volkswagen'];

export const isKnownBrand = (brand: string): boolean => KNOWN_BRANDS.includes(brand.toLowerCase());

export const areContractDetailsLoading = <T>(
    contractDetails: AbstractContractBasedDataState<T, 'MARKET_API_DEFAULT_BUSINESS_ERROR'>,
): boolean => {
    return Object.values(contractDetails)
        .map(item => item.isLoading)
        .some(Boolean);
};
