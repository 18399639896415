import React from 'react';
import { LeasingFinancialDetails, formatAsCurrency, undefinedReturnValueHyphen } from '../../../../../common';
import { useTranslation } from 'react-i18next';
import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';

export const ServiceAmountSection: React.FC<{ details: LeasingFinancialDetails }> = ({ details }) => {
    const { t } = useTranslation('financial-details');

    const { services, servicesInterestAmount, serviceCustomerTotalDueAmount } = details;

    const definitionListItems: DefinitionListItem[] = [
        {
            tooltip: t('leasing.service-amount-section.services.tooltip'),
            label: t('leasing.service-amount-section.services.label'),
            value: formatAsCurrency(services, undefinedReturnValueHyphen),
        },
        {
            tooltip: t('leasing.service-amount-section.service-interest-amount.tooltip'),
            label: t('leasing.service-amount-section.service-interest-amount.label'),
            value: formatAsCurrency(servicesInterestAmount, undefinedReturnValueHyphen),
        },
        {
            tooltip: t('leasing.service-amount-section.lease-total-amount.tooltip'),
            label: t('leasing.service-amount-section.lease-total-amount.label'),
            value: formatAsCurrency(serviceCustomerTotalDueAmount, undefinedReturnValueHyphen),
        },
    ];

    return (
        <DataOverview withoutCardEffect={true}>
            <DefinitionListHorizontal list={definitionListItems} />
        </DataOverview>
    );
};
