import React from 'react';
import { LeasingFinancialDetails, formatAsCurrency, undefinedReturnValueHyphen } from '../../../../../common';
import { useTranslation } from 'react-i18next';
import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';

export const CapitalPrincipalSection: React.FC<{ details: LeasingFinancialDetails }> = ({ details }) => {
    const { t } = useTranslation('financial-details');
    const { capitalAmount, effectiveInterestAmount, grossLoanAmount } = details;

    const definitionListItems: DefinitionListItem[] = [
        {
            tooltip: t('leasing.capital-principal-section.capital-amount.tooltip'),
            label: t('leasing.capital-principal-section.capital-amount.label'),
            value: formatAsCurrency(capitalAmount, undefinedReturnValueHyphen),
        },
        {
            tooltip: t('leasing.capital-principal-section.interest-amount.tooltip'),
            label: t('leasing.capital-principal-section.interest-amount.label'),
            value: formatAsCurrency(effectiveInterestAmount, undefinedReturnValueHyphen),
        },
        {
            tooltip: t('leasing.capital-principal-section.gross-loan-amount.tooltip'),
            label: t('leasing.capital-principal-section.gross-loan-amount.label'),
            value: formatAsCurrency(grossLoanAmount, undefinedReturnValueHyphen),
        },
    ];

    return (
        <DataOverview withoutCardEffect={true}>
            <DefinitionListHorizontal list={definitionListItems} />
        </DataOverview>
    );
};
