import { ConsumerCreditContract as ConsumerCreditContractType, ContractLinks } from '../../../../common';
import {
    Accordion,
    ContractActionItemType,
    ContractActions,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import { Card } from '@vwfs-bronson/bronson-react';
import { BundledProducts } from 'components/contracts/bundled-products/BundledProducts';
import { ConsumerCreditGeneralContractDetails } from 'components/contracts/general-contract-details/consumer-credit/ConsumerCreditGeneralContractDetails';
import { VehicleDetails } from 'components/vehicle-details';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { amortizationTablePath, earlySettlementPath, requestDocumentPath } from '../../../navigation/paths';
import { ConsumerCreditFinancialDetails } from '../../financial-details/consumer-credit-financial-details';
import { UnpaidAlert } from '../../unpaid-alert/UnpaidAlert';
import { ConsumerCreditContractSummary } from '../summary';

export type ConsumerCreditContractProps = {
    contract: ConsumerCreditContractType;
    links?: ContractLinks;
    defaultExpanded?: boolean;
};

export const ConsumerCreditContract: React.FC<ConsumerCreditContractProps> = ({ contract, links, defaultExpanded }) => {
    const { onAction } = useAnalyticsActionTracker('onOpenContractAccordion');
    const { t } = useTranslation('contracts');
    const [tesFlag, setTesFlag] = useState(false);

    const contractActionItems: ContractActionItemType[] = [
        {
            iconName: 'semantic-calendar',
            pageUrl: amortizationTablePath(contract.contractId),
            label: t('contract-actions.amortization-table'),
            iconReversed: true,
        },
        {
            iconName: 'semantic-file',
            pageUrl: requestDocumentPath(contract.contractId),
            label: t('contract-actions.request-document'),
            iconReversed: true,
        },
    ];

    if (tesFlag) {
        contractActionItems.push({
            iconName: 'semantic-calculator',
            pageUrl: earlySettlementPath(contract.contractId),
            label: t('contract-actions.tes'),
            iconReversed: true,
        });
    }

    const getViewChange = (currentViewName: string) => {
        switch (currentViewName) {
            case t('general-contract-details:headline'):
                return 'General contract';
            case t('vehicle-details:headline'):
                return 'Vehicle details';
            case t('bundled-products:headline'):
                return 'Bundled Products';
            case t('financial-details:headline'):
                return 'Financial details';
        }
    };

    let openItems = [];

    const handleOnChange = (refs: string[]): void => {
        if (openItems.length > refs.length || !refs.length) {
            openItems = [...refs];
            return;
        }
        openItems = [...refs];
        const viewChange = getViewChange(refs[refs.length - 1]);
        onAction(viewChange);
    };

    const contractUnpaids = links?.unpaidDetailsPdfDownload && (
        <UnpaidAlert downloadLink={links?.unpaidDetailsPdfDownload} />
    );
    const contractSummary = <ConsumerCreditContractSummary contract={contract} />;

    const contractBody = links ? (
        <Accordion onChange={handleOnChange} lazyRender={true}>
            <>
                <ContractActions contractActionItems={contractActionItems} />
                <br />
                {links.contractDetails && (
                    <ConsumerCreditGeneralContractDetails
                        contractId={contract.contractId}
                        contractType={contract.contractType}
                        setTesFlag={setTesFlag}
                    />
                )}
                {links.financialDetails && <ConsumerCreditFinancialDetails contractId={contract.contractId} />}
                {links.bundledProductDetails && <BundledProducts contractId={contract.contractId} />}
                {links.vehicleDetails && <VehicleDetails contractId={contract.contractId} />}
            </>
        </Accordion>
    ) : null;

    const { contractType, contractNumber } = contract;

    return (
        <Card
            element="article"
            expandable
            title={`${contractType} ${contractNumber}`}
            defaultExpanded={defaultExpanded}
            contentLarge={contractBody}
            className={'u-mb'}
        >
            {contractSummary}
            {contractUnpaids && (
                <>
                    {contractUnpaids}
                    <br />
                </>
            )}
        </Card>
    );
};
