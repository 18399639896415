import React from 'react';
import { ValidationError } from '../../../../common';
import { WithDefaultCpIntegrationErrors, WithDefaultMarketApiErrors } from '@cp-shared-8/common-utilities';
import { useTranslation } from 'react-i18next';
import { Modal } from '@vwfs-bronson/bronson-react';

export type RequestDocumentFormConfirmationModalProps = {
    shown: boolean;
    errorStatus?: WithDefaultMarketApiErrors<WithDefaultCpIntegrationErrors<ValidationError>>;
    closeConfirmationModal: () => void;
};

export const RequestDocumentFormConfirmationModal: React.FC<RequestDocumentFormConfirmationModalProps> = ({
    shown,
    errorStatus,
    closeConfirmationModal,
}) => {
    const { t } = useTranslation('request-document');
    let title, text, buttonConfirmText: string;
    if (errorStatus) {
        switch (errorStatus) {
            case 'VALIDATION_ERROR':
            case 'MARKET_API_DEFAULT_BUSINESS_ERROR':
                title = t('form.confirmation-modal.error.validation.title');
                text = t('form.confirmation-modal.error.validation.text');
                buttonConfirmText = t('translation:confirmation-modal.ok-button');
                break;
            default:
                title = t('form.confirmation-modal.error.other.title');
                text = t('form.confirmation-modal.error.other.text');
                buttonConfirmText = t('translation:confirmation-modal.ok-button');
                break;
        }
    } else {
        title = t('form.confirmation-modal.success.title');
        text = t('form.confirmation-modal.success.text');
        buttonConfirmText = t('translation:editable-section-nav.dashboard');
    }

    return (
        <Modal
            shown={shown}
            buttonConfirmText={buttonConfirmText}
            onConfirm={closeConfirmationModal}
            onClose={closeConfirmationModal}
            testId="confirmModal"
            title={title}
        >
            <div dangerouslySetInnerHTML={{ __html: text }} />
        </Modal>
    );
};
