import { ConsumerCreditContract, formatAsCurrency, formatAsDate } from '../../../../common';
import { ContractSummary, ContractSummaryItemProps } from '@cp-shared-8/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type ConsumerCreditSummaryProps = {
    contract?: ConsumerCreditContract;
};

export const ConsumerCreditContractSummary: React.FC<ConsumerCreditSummaryProps> = ({ contract }) => {
    const { t } = useTranslation('contracts');
    const items: ContractSummaryItemProps[] = [
        {
            tooltip: t('consumer-credit.next-due-date.tooltip'),
            label: t('consumer-credit.next-due-date.label'),
            value: contract?.nextDueDate ? formatAsDate(contract.nextDueDate) : '-',
            testClass: 'nextDueDate',
        },
        {
            tooltip: t('consumer-credit.next-due-amount.tooltip'),
            label: t('consumer-credit.next-due-amount.label'),
            value: contract?.nextDueAmount ? formatAsCurrency(contract.nextDueAmount) : '-',
            testClass: 'nextDueAmount',
        },
        contract?.closeDateOfTheContract
            ? {
                  tooltip: t('consumer-credit.close-date-of-the-contract.tooltip'),
                  label: t('consumer-credit.close-date-of-the-contract.label'),
                  value: formatAsDate(contract.closeDateOfTheContract),
                  testClass: 'closeDateOfTheContract',
              }
            : {
                  tooltip: t('consumer-credit.last-installment-date.tooltip'),
                  label: t('consumer-credit.last-installment-date.label'),
                  value: contract?.lastInstallmentDate ? formatAsDate(contract.lastInstallmentDate) : '-',
                  testClass: 'lastInstallmentDate',
              },
    ];
    return <ContractSummary items={items} />;
};
