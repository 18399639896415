import React from 'react';
import { useTranslation } from 'react-i18next';

import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';

export type AmortizationTableErrorNotificationProps = {
    testId?: string;
};

export const AmortizationTableErrorNotification: React.FC<AmortizationTableErrorNotificationProps> = ({ testId }) => {
    const { t } = useTranslation('amortization-table');
    return (
        <Notification
            status={NotificationStatus.error}
            headline={t('error.title')}
            text={t('error.text')}
            testId={testId}
        />
    );
};
