import { getContractsEndpoint } from './contracts';

export function getAmortizationTableHeaderEndpoint(contractId: string): string {
    return `${getContractsEndpoint()}/${contractId}/amortization-table/header`;
}

export function getAmortizationTablePdfDownloadEndpoint(contractId: string): string {
    return `${getContractsEndpoint()}/${contractId}/amortization-table/download-pdf`;
}

export function getAmortizationTableBundledProductsDownloadLinks(contractId: string): string {
    return `${getContractsEndpoint()}/${contractId}/amortization-table/bundled-products-download-links`;
}

export function getAmortizationDetailsBundledProductsPdfDownloadEndpoint(
    contractId: string,
    serviceCode: string,
): string {
    return `${getContractsEndpoint()}/${contractId}/amortization-table/bundled-products/${serviceCode}/download-pdf`;
}
