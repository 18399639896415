export enum FetchContentError {
    DEFAULT_ERROR = 'DEFAULT_ERROR',
}

export type ContentEndpoint = (locale: string, brand: string) => string;

function getContentEndpoint(locale: string, brand: string, contentType: string): string {
    return `/${brand}/${locale}/${contentType}.json`;
}

export const getLandingPageTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'landing-page-teaser');
};

export const getFaqEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'faq');
};

export const getGenericErrorEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'generic-error');
};

export const getNotFoundErrorEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'not-found-error');
};

export const getForbiddenErrorEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'forbidden-error');
};

export const getUnauthorizedErrorEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'unauthorized-error');
};

export const getLandingPageMarketingCardEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'landing-page-marketing-cards');
};

export const getDashboardMarketingCardEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'dashboard-marketing-card');
};

export const getLegalNoticeEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'legal-notice');
};

export const getCookieWarningEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'cookie-warning');
};

export const getIconFooterEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'icon-footer');
};

export const getLegalFooterEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'legal-footer');
};

export const getThirdPartyLicensesEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'third-party-licenses');
};

export const getLandingPageVideoEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'landing-page-video');
};

export const getLogoutPageTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'logout-page-teaser');
};

export const getTimeOutPageTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'time-out-page-teaser');
};

export const getAccessibilityEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'accessibility');
};
