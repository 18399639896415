import React, { FC } from 'react';

import { CustomerType } from '../../../common';
import { IdentificationSectionUi } from './ui';
import { withLoadingHandler } from '../../integration-wrapper';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { useIdentification } from './useIdentification';

export type IdentificationSectionProps = {
    customerType: CustomerType;
    isProspect: boolean;
    isTabsPresent?: boolean;
};

const IdentificationSectionWithHandlers = withLoadingHandler(IdentificationSectionUi);

export const IdentificationSection: FC<IdentificationSectionProps> = ({ customerType, isProspect, isTabsPresent }) => {
    const { t } = useTranslation('my-profile');
    const { data: identification, isLoading, loadingError } = useIdentification(
        isTabsPresent ? 'PrivateBusinessMan' : customerType,
        isProspect,
    );

    const component = loadingError ? (
        <Notification
            status={NotificationStatus.error}
            headline={t('identification.error.title')}
            text={t('identification.error.text')}
            testId={'my-profile-identification-error'}
        />
    ) : (
        <IdentificationSectionWithHandlers
            isLoading={isLoading}
            customerType={customerType}
            identification={identification}
        />
    );

    return component;
};
