import React from 'react';
import { useLogin } from '../../auth/useLogin';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { HeroImage as LogoutPageTeaserUi, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { useLogoutPageTeaser } from './useLogoutPageTeaser';

export const LogoutPageTeaser: React.FC = () => {
    const { cmsContent: logoutPageTeaser, isLoading, loadingError } = useLogoutPageTeaser();
    const { onAction } = useAnalyticsActionTracker('login');
    const login = useLogin();
    const onClick = () => {
        onAction();
        login();
    };
    const LogoutPageTeaserWithHandlers = withLoadingAndNoConnectionHandler(LogoutPageTeaserUi);

    return (
        <LogoutPageTeaserWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            title={logoutPageTeaser?.title || ''}
            imageUrl={logoutPageTeaser?.imageUrl || ''}
            subTitle={logoutPageTeaser?.subTitle}
            text={logoutPageTeaser?.text}
            buttonText={logoutPageTeaser?.buttonText}
            clickHandler={onClick}
            inverted={!!logoutPageTeaser?.isInverted}
            shallow
        />
    );
};
