import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import qs from 'qs';
import { errorAuthenticatedPagePath, notAuthorizedPagePath } from '../components/navigation/paths';
import { GenericErrorPage } from '../components/error-pages/generic';

export const ErrorPage: React.FC = () => {
    const location = useLocation();
    const { search } = location;
    const searchParams = qs.parse(search, { ignoreQueryPrefix: true });
    const errorType = searchParams['errorType']?.toString();

    if (errorType === 'ALREADY_LOGIN_ERR') {
        return <Redirect to={errorAuthenticatedPagePath()} />;
    }

    if (errorType === 'INVALID_SESSION_ERR') {
        return <Redirect to={notAuthorizedPagePath()} />;
    }

    return <GenericErrorPage errorType={errorType} />;
};
