import {
    hasError,
    isLoading,
    MasterPage as MasterPageShared,
    notInitiated,
    useAuthentication,
    AnalyticsProvider,
} from '@cp-shared-8/frontend-ui';
import { useProspectFlow, useUserRegistrationData } from 'auth';
import { IconFooter } from 'components/icon-footer/IconFooter';
import { LegalFooter } from 'components/legal-footer/LegalFooter';
import { NavigationBar } from 'components/navigation/NavigationBar';
import { loginAnalyticsPath, registrationPagePath } from 'components/navigation/paths';
import { currentBrand } from '../config';
import React, { useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGreeting } from 'components/greeting';

export const MasterPage: React.FC = ({ children }) => {
    const { isAuthenticated } = useAuthentication();
    const { isAuthorized } = useUserRegistrationData();
    const { state: prospectState, initiate: prospectCheck } = useProspectFlow();

    useEffect(() => {
        if (isAuthenticated && notInitiated(prospectState)) {
            prospectCheck();
        }
    }, [prospectState, prospectCheck, isAuthenticated]);

    const isProspectProbably =
        notInitiated(prospectState) ||
        isLoading(prospectState) ||
        (!hasError(prospectState) && prospectState.isAuthorized);

    return (
        <MasterPageComponent
            isAuthenticated={isAuthenticated}
            isAuthorized={isAuthorized}
            isProspectProbably={isProspectProbably}
        >
            {children}
        </MasterPageComponent>
    );
};

const MasterPageComponent: React.FC<{
    isAuthenticated: boolean;
    isAuthorized: boolean;
    isProspectProbably: boolean;
}> = memo(({ isAuthenticated, isAuthorized, isProspectProbably, children }) => {
    const { t } = useTranslation();
    const { data: greeting } = useGreeting(undefined, isAuthenticated && isAuthorized);
    const getUserGroup = (): string | undefined => {
        if (isAuthenticated) {
            switch (greeting?.customerType) {
                case 'Private':
                    return 'private';
                case 'BusinessMan':
                    return 'business (small)';
                case 'LegalEntity':
                    return 'business (corporate)';
                case 'PrivateBusinessMan':
                    return 'business (small)';
                default:
                    return 'prospect';
            }
        }
    };

    return (
        <AnalyticsProvider
            market="IT"
            isAuthenticated={isAuthenticated}
            version="7"
            releaseDate="2023-04-21"
            language={'it'}
            registrationPath={registrationPagePath()}
            loginPath={loginAnalyticsPath()}
            userGroup={getUserGroup()}
            brand={currentBrand}
        >
            <MasterPageShared
                navigationBar={<NavigationBar isAuthenticated={isAuthenticated} isProspect={isProspectProbably} />}
                iconFooter={<IconFooter />}
                legalFooter={<LegalFooter />}
                label={t('backToTop')}
            >
                {children}
            </MasterPageShared>
        </AnalyticsProvider>
    );
});

MasterPageComponent.displayName = 'MasterPageComponent';
