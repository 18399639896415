import { LeasingContractDetails } from '../../../../../common';
import {
    DataOverview,
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-8/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React from 'react';

export const InstallmentSection: React.FC<{ details: LeasingContractDetails }> = ({ details }) => {
    const { numberOfInstallments, installmentAmount, serviceFee } = details;
    const { t, f } = useTranslationWithFormatting('general-contract-details');

    const mandatoryFieldMissing = !numberOfInstallments || !installmentAmount;

    const sectionFields: DefinitionListItem[] = [
        {
            label: t('installment-section.number-of-installments.leasing.label'),
            value: numberOfInstallments,
        },
        {
            label: t('installment-section.installment-amount.leasing.label'),
            value: f(installmentAmount, TranslationFormat.CURRENCY),
        },
        {
            label: t('installment-section.service-fee'),
            value: serviceFee ? f(serviceFee, TranslationFormat.CURRENCY) : undefined,
        },
    ].filter(item => !!item.value);

    return (
        <DataOverview withoutCardEffect={true}>
            {mandatoryFieldMissing ? (
                <Notification status={NotificationStatus.error} headline={t('error.headline')} text={t('error.text')} />
            ) : (
                <DefinitionListHorizontal list={sectionFields} />
            )}
        </DataOverview>
    );
};
