import {
    NavigationBar as NavigationBarShared,
    SiteNavItemPropsList,
    SiteNavProps,
    useAuthentication,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import { useLogin } from 'auth/useLogin';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { LanguageSwitcher } from './LanguageSwitcher';
import {
    dashboardPagePath,
    faqPagePath,
    landingPagePath,
    legalNoticePath,
    myProfilePagePath,
    myRequestsPagePath,
    notAuthorizedPagePath,
    notFoundPagePath,
    postboxPagePath,
    registrationPagePath,
    logoutPagePath,
    timeOutPagePath,
    thirdPartyLicensesPagePath,
} from './paths';

const pagesWithoutStaticHeader = [
    landingPagePath(),
    faqPagePath(),
    legalNoticePath(),
    notAuthorizedPagePath(),
    notFoundPagePath(),
    registrationPagePath(),
    thirdPartyLicensesPagePath(),
    logoutPagePath(),
    timeOutPagePath(),
];

export type NavigationBarProps = {
    isAuthenticated?: boolean;
    isProspect?: boolean;
};

function publicNavigationLinks(t: TFunction, onLogin: () => void, login: () => Promise<void>): SiteNavItemPropsList {
    return [
        {
            url: landingPagePath(),
            label: t('navigation.home'),
        },
        {
            url: faqPagePath(),
            label: t('navigation.faq'),
        },
        {
            label: t('navigation.login'),
            onClick: (): void => {
                onLogin();
                login();
            },
        },
    ];
}

function privateNavigationLinks(t: TFunction, logout: () => Promise<void>): SiteNavItemPropsList {
    return [
        {
            url: dashboardPagePath(),
            label: t('navigation.dashboard'),
        },
        {
            url: myProfilePagePath(),
            label: t('navigation.profile'),
        },
        {
            url: postboxPagePath(),
            label: t('navigation.postbox'),
        },
        {
            url: myRequestsPagePath(),
            label: t('navigation.requests'),
        },
        {
            url: faqPagePath(),
            label: t('navigation.faq'),
        },
        {
            label: t('navigation.logout'),
            onClick: (): Promise<void> => logout(),
        },
    ];
}

function privateKYCNavigationLinks(t: TFunction, logout: () => Promise<void>): SiteNavItemPropsList {
    return [
        {
            label: t('navigation.logout'),
            onClick: logout,
        },
    ];
}

export const NavigationBar: React.FC<NavigationBarProps> = ({ isAuthenticated }) => {
    const { t } = useTranslation('navigation');
    const history = useHistory();
    const { onAction: onLoginAction } = useAnalyticsActionTracker('login');
    const { onAction: onLogoutAction } = useAnalyticsActionTracker('logout');
    const login = useLogin();
    const { logout } = useAuthentication();
    const currentPathName = useLocation().pathname;
    const isRegistrationPage = history.location.pathname === registrationPagePath();

    const navigationItems = (isAuthenticated
        ? isRegistrationPage
            ? privateKYCNavigationLinks(t, () => logout({ redirectUri: window.location.origin + landingPagePath() }))
            : privateNavigationLinks(t, () => {
                  onLogoutAction(history.location.pathname.split('/')[1]);
                  return logout({ redirectUri: window.location.origin + logoutPagePath() });
              })
        : publicNavigationLinks(t, onLoginAction, login)
    ).map(navItem => ({
        ...{ isActive: history.location.pathname === navItem.url },
        ...navItem,
    }));

    const withStaticHeader = !pagesWithoutStaticHeader.includes(currentPathName);

    const navigation: SiteNavProps = {
        navigationItems,
    };
    const logoRedirectPath = isAuthenticated ? dashboardPagePath() : landingPagePath();
    const languageSwitcher = <LanguageSwitcher />;
    return (
        <NavigationBarShared
            languageSwitcher={languageSwitcher}
            logoAltText={isAuthenticated ? t('navigation.dashboard') : t('navigation.home')}
            onLogoClick={(): void => history.push(logoRedirectPath)}
            navigation={navigation}
            withStaticHeader={withStaticHeader}
        />
    );
};
