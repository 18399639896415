import { AbstractDataState, useGetContractBasedApiData } from '@cp-shared-8/frontend-integration';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { fetchVehicleImage } from './VehicleImageSlice';
import { VehicleImageDataSelector } from './VehicleImageDataSelector';
import { getVehicleImageServiceEndpoint, VehicleImageServiceResponse, VehicleImageView } from '@cp-shared-8/apis';

export function useVehicleImage(
    encryptedVin: string,
    view?: VehicleImageView,
): AbstractDataState<VehicleImageServiceResponse, DefaultBusinessMarketApiErrorCode> {
    const params = view ? { view } : {};
    return useGetContractBasedApiData(
        encryptedVin,
        fetchVehicleImage,
        VehicleImageDataSelector,
        getVehicleImageServiceEndpoint(encryptedVin),
        false,
        {
            params,
        },
    );
}
