import { LandingPageVideo } from '../../../common';
import React from 'react';
import { ContentSection, Layout, Video, PageWrap, SectionHeading } from '@vwfs-bronson/bronson-react';

export const LandingPageVideoUi: React.FC<{ landingPageVideoContent?: LandingPageVideo }> = ({
    landingPageVideoContent,
}) => {
    if (!landingPageVideoContent) {
        return null;
    }

    const { title, text, textWithLink } = landingPageVideoContent;
    const videoProps = {
        service: 'youtube' as 'youtube',
        id: 'GFNHESu4Vjw',
    };

    return (
        <>
            <ContentSection altBackground className={'u-mt-large'}>
                <PageWrap size={'medium'}>
                    <Layout>
                        <Layout.Item default="1/2" s="1/1">
                            <Video {...videoProps}></Video>
                        </Layout.Item>
                        <Layout.Item default="1/2" s="1/1">
                            <SectionHeading level="5" subtitle={text}>
                                {title}
                            </SectionHeading>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: `<strong>${textWithLink || ''}</strong>`,
                                }}
                            ></div>
                        </Layout.Item>
                    </Layout>
                </PageWrap>
            </ContentSection>
        </>
    );
};
