import { getTimeOutPageTeaserEndpoint, TimeOutPageTeaser } from '../../common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<TimeOutPageTeaser>({
    contentName: 'timeOutPageTeaser',
    contentEndpoint: getTimeOutPageTeaserEndpoint,
});

export default reducer;
export const fetchTimeOutPageTeaser = fetchContent;
