import { EarlySettlementRequestResponse } from '../../../common';
import { Notification, NotificationStatus, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { NotificationProps } from '@cp-shared-8/frontend-ui/build/components/notification/Notification';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type TesNotificationProps = {
    tesStatus?: EarlySettlementRequestResponse;
};

export const TesNotification: React.FC<TesNotificationProps> = ({ tesStatus }) => {
    const { t } = useTranslation('total-early-settlement');
    const { onAction: trackOnError } = useAnalyticsActionTracker('onConfirmIdentiyAuthFailed');
    const getNotificationProps = (): NotificationProps | undefined => {
        switch (tesStatus?.statusId) {
            case -1:
                return {
                    status: NotificationStatus.error,
                    headline: t('valid-quote.error.not-available-1.title'),
                    children: (
                        <span dangerouslySetInnerHTML={{ __html: t('valid-quote.error.not-available-1.text') }} />
                    ),
                    testId: 'tes-notification-not-available-1',
                };
            case -2:
                return {
                    status: NotificationStatus.error,
                    headline: t('valid-quote.error.not-available-2.title'),
                    children: (
                        <span dangerouslySetInnerHTML={{ __html: t('valid-quote.error.not-available-2.text') }} />
                    ),
                    testId: 'tes-notification-not-available-2',
                };
            case -3:
                return {
                    status: NotificationStatus.error,
                    headline: t('valid-quote.error.not-available-3.title'),
                    children: (
                        <span dangerouslySetInnerHTML={{ __html: t('valid-quote.error.not-available-3.text') }} />
                    ),
                    testId: 'tes-notification-not-available-3',
                };
            case -5:
                return {
                    status: NotificationStatus.error,
                    headline: t('valid-quote.error.not-available-5.title'),
                    children: (
                        <span dangerouslySetInnerHTML={{ __html: t('valid-quote.error.not-available-5.text') }} />
                    ),
                    testId: 'tes-notification-not-available-5',
                };
            case -6:
                return {
                    status: NotificationStatus.error,
                    headline: t('valid-quote.error.not-available-6.title'),
                    children: (
                        <span dangerouslySetInnerHTML={{ __html: t('valid-quote.error.not-available-6.text') }} />
                    ),
                    testId: 'tes-notification-not-available-6',
                };
            case -7:
                trackOnError();
                return {
                    status: NotificationStatus.error,
                    headline: t('valid-quote.error.not-available-7.title'),
                    children: (
                        <span dangerouslySetInnerHTML={{ __html: t('valid-quote.error.not-available-7.text') }} />
                    ),
                    testId: 'tes-notification-not-available-7',
                };
            case 1:
                return {
                    status: NotificationStatus.error,
                    children: <span dangerouslySetInnerHTML={{ __html: t('valid-quote.error.night') }} />,
                    testId: `tes-notification-night`,
                };
            case 2:
                return {
                    status: NotificationStatus.error,
                    children: <span dangerouslySetInnerHTML={{ __html: t('valid-quote.error.day-busy') }} />,
                    testId: `tes-notification-day-busy`,
                };
            case 3:
                return {
                    status: NotificationStatus.success,
                    children: <span dangerouslySetInnerHTML={{ __html: t('valid-quote.error.day-free') }} />,
                    testId: `tes-notification-day-free`,
                };
            case 4:
                return {
                    status: NotificationStatus.error,
                    headline: t('valid-quote.error.not-available-4.title'),
                    children: (
                        <span dangerouslySetInnerHTML={{ __html: t('valid-quote.error.not-available-4.text') }} />
                    ),
                    testId: 'tes-notification-not-available-4',
                };
            case 5:
                return {
                    status: NotificationStatus.error,
                    headline: t('valid-quote.error.market-error.title'),
                    children: <span dangerouslySetInnerHTML={{ __html: t('valid-quote.error.market-error.text') }} />,
                    testId: 'tes-notification-market-error-5',
                };
            case 6:
                return {
                    status: NotificationStatus.error,
                    headline: t('valid-quote.error.no-connection.title'),
                    children: <span dangerouslySetInnerHTML={{ __html: t('valid-quote.error.no-connection.text') }} />,
                    testId: 'tes-notification-market-connection-error-6',
                };
            case 500:
                return {
                    status: NotificationStatus.error,
                    headline: t('valid-quote.error.complete-contract.title'),
                    children: (
                        <span dangerouslySetInnerHTML={{ __html: t('valid-quote.error.complete-contract.text') }} />
                    ),
                    testId: `tes-notification-day-free`,
                };
            case 502:
                return {
                    status: NotificationStatus.error,
                    headline: t('valid-quote.error.no-connection.title'),
                    text: t('valid-quote.error.no-connection.text'),
                    testId: 'tes-notification-error-be',
                };
            default:
                return {
                    status: NotificationStatus.error,
                    headline: t('valid-quote.error.default-error.title'),
                    text: t('valid-quote.error.default-error.text'),
                    testId: 'test-default-error',
                };
        }
    };

    const notificationProps = getNotificationProps();
    if (!notificationProps) return null;

    return (
        <Notification
            status={notificationProps.status}
            headline={notificationProps.headline}
            testId={notificationProps.testId}
            text={notificationProps?.text}
        >
            {notificationProps?.children && notificationProps?.children}
        </Notification>
    );
};
