import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import {
    ConsumerCreditFinancialDetailsWithAllValues,
    ConsumerCreditFinancialDetailsWithMissingValues,
} from './ExampleData';
import { ActiveConsumerCreditContractWithAllValues } from 'components/contracts/ExampleData';
import { ContractsMock } from '../contracts-mock';
import { getFinancialDetailsEndpoint } from '../../../../common';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: ConsumerCreditFinancialDetailsWithAllValues,
    },
    'With Missing Values': {
        status: 200,
        responseBody: ConsumerCreditFinancialDetailsWithMissingValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const ConsumerCreditFinancialDetailsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Consumer Credit Financial Details Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getFinancialDetailsEndpoint(ActiveConsumerCreditContractWithAllValues.contractId),
};

export const ConsumerCreditFinancialDetailsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [ContractsMock, ConsumerCreditFinancialDetailsMock]);

    return <div>{storyFn()}</div>;
};
