import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { IdentificationSectionDataSelector } from './IdentificationSectionDataSelector';
import {
    CustomerType,
    getIdentificationEndpoint,
    getProspectProfileIdentificationEndpoint,
    Identification,
} from '../../../common';
import { fetchIdentification } from './IdentificationSlice';

export function useIdentification(
    customerType: CustomerType,
    isProspect: boolean,
): AbstractDataState<Identification, DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(fetchIdentification, IdentificationSectionDataSelector, false, {
        url: isProspect ? getProspectProfileIdentificationEndpoint() : getIdentificationEndpoint(customerType),
    });
}
