import React from 'react';

import { CustomerType, CustomerAddress, AddressItem } from '../../../../common';
import { AddressSectionEntry } from '../entry';

type AddressSectionUiProps = {
    address?: CustomerAddress;
    customerType: CustomerType;
};

const isAddressForCustomer = (item: AddressItem, customerType: CustomerType) => {
    switch (item.addressUsage) {
        case 'Residence':
        case 'Postal address':
            return customerType === 'Private';

        case 'Legal entity address':
        case 'Other address':
            return customerType === 'LegalEntity' || customerType === 'BusinessMan';
    }
};

export const AddressSectionUi: React.FC<AddressSectionUiProps> = ({ address, customerType }) => {
    const filtered = address?.items.filter(item => isAddressForCustomer(item, customerType));

    return (
        <>
            {filtered?.map((item, index) => (
                <AddressSectionEntry address={item} key={`${item.addressUsage}-${index}`} />
            ))}
        </>
    );
};
