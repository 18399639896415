import { getLogoutPageTeaserEndpoint, LogoutPageTeaser } from '../../common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<LogoutPageTeaser>({
    contentName: 'logoutPageTeaser',
    contentEndpoint: getLogoutPageTeaserEndpoint,
});

export default reducer;
export const fetchLogoutPageTeaser = fetchContent;
