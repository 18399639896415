import { formatAsCurrency, formatAsDate, LeasingContract } from '../../../../common';
import { ContractSummary, ContractSummaryItemProps } from '@cp-shared-8/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type LeasingContractSummaryProps = {
    contract?: LeasingContract;
    isActive: boolean;
};

export const LeasingContractSummary: React.FC<LeasingContractSummaryProps> = ({ contract, isActive }) => {
    const { t } = useTranslation('contracts');
    const items: ContractSummaryItemProps[] = [
        !isActive && contract?.contractStartDate
            ? {
                  tooltip: t('leasing.start-date.tooltip'),
                  label: t('leasing.start-date.label'),
                  value: formatAsDate(contract.contractStartDate),
                  testClass: 'startDate',
              }
            : {
                  tooltip: t('leasing.next-due-date.tooltip'),
                  label: t('leasing.next-due-date.label'),
                  value: contract?.nextDueDate ? formatAsDate(contract.nextDueDate) : '-',
                  testClass: 'nextDueDate',
              },
        {
            tooltip: t('leasing.next-due-amount.tooltip'),
            label: t('leasing.next-due-amount.label'),
            value: contract?.nextDueAmount ? formatAsCurrency(contract.nextDueAmount) : '-',
            testClass: 'nextDueAmount',
        },
        !isActive && contract?.successionDate
            ? {
                  label: t('leasing.succession-date.label'),
                  value: formatAsDate(contract.successionDate),
                  testClass: 'startDate',
              }
            : contract?.closeDateOfTheContract
            ? {
                  tooltip: t('leasing.close-date-of-the-contract.tooltip'),
                  label: t('leasing.close-date-of-the-contract.label'),
                  value: formatAsDate(contract.closeDateOfTheContract),
                  testClass: 'closeDateOfTheContract',
              }
            : {
                  tooltip: t('leasing.last-due-date.tooltip'),
                  label: t('leasing.last-due-date.label'),
                  value: contract?.lastDueDate ? formatAsDate(contract.lastDueDate) : '-',
                  testClass: 'lastDueDate',
              },
    ];

    return <ContractSummary items={items} />;
};
