import { ConsumerCreditContractDetails } from '../../../../../common';
import {
    DataOverview,
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-8/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React from 'react';

export const InstallmentSection: React.FC<{ details: ConsumerCreditContractDetails }> = ({ details }) => {
    const { numberOfInstallments, installmentAmount, lastInstallment, sharedServicesInstallmentAmount } = details;
    const { t, f } = useTranslationWithFormatting('general-contract-details');

    const mandatoryFieldMissing = !numberOfInstallments || !installmentAmount;

    const sectionFields: DefinitionListItem[] = [
        {
            label: t('installment-section.number-of-installments.consumer-credit.label'),
            value: numberOfInstallments,
        },
        {
            label: t('installment-section.installment-amount.consumer-credit.label'),
            value: f(installmentAmount, TranslationFormat.CURRENCY),
            tooltip: t('installment-section.installment-amount.consumer-credit.tooltip'),
        },
        {
            label: t('installment-section.last-installment.label'),
            value:
                lastInstallment && !(lastInstallment === 0.0)
                    ? f(lastInstallment, TranslationFormat.CURRENCY)
                    : undefined,
            tooltip: t('installment-section.last-installment.tooltip'),
        },
        {
            label: t('installment-section.shared-services-installment-amount.label'),
            value: sharedServicesInstallmentAmount
                ? f(sharedServicesInstallmentAmount, TranslationFormat.CURRENCY)
                : undefined,
            tooltip: t('installment-section.shared-services-installment-amount.tooltip'),
        },
    ].filter(item => !!item.value);

    return (
        <DataOverview withoutCardEffect={true}>
            {mandatoryFieldMissing ? (
                <Notification status={NotificationStatus.error} headline={t('error.headline')} text={t('error.text')} />
            ) : (
                <DefinitionListHorizontal list={sectionFields} />
            )}
        </DataOverview>
    );
};
