import { TFunction } from 'i18next';

export const capitalizeWord = (word: string): string => {
    return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
};

const firstLetterToLower = (word: string): string => {
    return `${word.charAt(0).toLowerCase()}${word.slice(1)}`;
};

const toKebabCase = (value: string): string => {
    return value
        .toLowerCase()
        .split(' ')
        .join('-');
};

const toTitleCase = (value: string): string => {
    return value
        .toLowerCase()
        .split(' ')
        .map($1 => capitalizeWord($1))
        .join(' ');
};

const fromCamel = (value: string): string => {
    return value
        .split('')
        .map(x => (x.toUpperCase() === x ? ` ${x}` : x))
        .join('');
};

export const getTranslation = (value: string, keyPrefix: string, t: TFunction) => {
    const titleCase = toTitleCase(value);
    const kebabCase = toKebabCase(value);

    return t(`${keyPrefix}.${kebabCase}`, titleCase);
};

export const getBrandTranslation = (brand: string, t: TFunction) => {
    return getTranslation(brand, 'vehicle-details.brand', t);
};

export const getColorFinishTranslation = (colorFinish: string, t: TFunction) => {
    return getTranslation(colorFinish, 'vehicle-details.color-finish', t);
};

export const getColorTranslation = (color: string, t: TFunction): string => {
    return getTranslation(color, 'vehicle-details.color', t);
};

export const getTransmissionTranslation = (transmission: string, t: TFunction): string => {
    return getTranslation(transmission, 'vehicle-details.transmission', t);
};

export const getFuelTypeTranslation = (fuelType: string, t: TFunction): string => {
    const capitalCase = capitalizeWord(fuelType);
    const kebabCase = toKebabCase(fromCamel(firstLetterToLower(fuelType)));

    return t(`vehicle-details.fuel-type.${kebabCase}`, capitalCase);
};
