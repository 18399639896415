import { BankData, SignedData, getIbanValidationEndpoint } from '@cp-shared-8/apis';
import { Heading, Fieldset, Layout, ButtonContainer, Button } from '@vwfs-bronson/bronson-react';
import { Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CpDataApi } from 'cp-xhr';
import * as Yup from 'yup';
import { areDifferent, isValidLength } from './ibanValidations';
import { IbanValidationError } from './IbanValidationError';
import { getChangeIbanEndpoint, getIbanHoldersEndpoint } from 'common/apis/services/endpoints/financial-details';
import { WithDefaultBusinessMarketApiError } from '@cp-shared-8/common-utilities';
import { parseErrorResponse } from '@cp-shared-8/frontend-integration';
import {
    CleaveInput,
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
    UiBlockingSpinner,
    ValidatedCheckbox,
    ValidatedSelect,
    ValidatedSelectItem,
} from '@cp-shared-8/frontend-ui';
import { IbanHolder } from 'common';
import { head } from 'lodash';
import { EditStatus } from '../../EditStatus';

type EditViewProps = {
    previousIban?: string;
    contractId: string;
    contractType: string;
    backButton: (lastEditStatus?: EditStatus) => void;
};

export const EditView: React.FC<EditViewProps> = ({ previousIban, contractId, contractType, backButton }) => {
    const { t } = useTranslation('general-contract-details');

    const [errorMessage, setErrorMessage] = useState<string>();
    const [isValidating, setIsValidating] = useState(false);
    const [ibanHolders, setIbanHolders] = useState<IbanHolder[]>();
    const [selectedIbanHolderId, setSelectedIbanHolderId] = useState<string>();
    const [signedBankData, setSignedBankData] = useState<SignedData<BankData>>();
    const [savedIban, setSavedIban] = useState<{ iban?: string; error?: string }>({});
    const [currentView, setCurrentView] = useState<'IBAN_VALIDATION_VIEW' | 'IBAN_CONFIRM_VIEW'>(
        'IBAN_VALIDATION_VIEW',
    );

    const cleaveOptionsIban = {
        delimiter: ' ',
        blocks: [2, 4, 4, 4, 4, 4, 4, 4, 4],
        numericOnly: false,
    };

    const handleSubmit = (): void => {
        setErrorMessage('');
        setIsValidating(true);
        CpDataApi.get(getIbanHoldersEndpoint(contractId))
            .then(({ data }: { data: IbanHolder[] }) => {
                if (data && data.length > 0) {
                    setIbanHolders(data);
                    setCurrentView('IBAN_CONFIRM_VIEW');
                } else {
                    setErrorMessage(t(`bank-account-section.edit-view.api-error-message.no-connection`));
                }
            })
            .catch(() => {
                setErrorMessage(t(`bank-account-section.edit-view.api-error-message.no-connection`));
            })
            .finally(() => {
                setIsValidating(false);
            });
    };

    const handleConfirm = (): void => {
        setErrorMessage('');
        if (savedIban.iban && selectedIbanHolderId) {
            setIsValidating(true);
            CpDataApi.put(getChangeIbanEndpoint(contractId), {
                iban: savedIban.iban,
                holderId: selectedIbanHolderId,
            })
                .then(() => {
                    backButton(EditStatus.SUCCESS);
                })
                .catch(error => {
                    const marketCode = error?.response?.data?.details?.marketData?.code
                    const errorCode = parseErrorResponse<WithDefaultBusinessMarketApiError<IbanValidationError>>(error)
                        .code;
                    if (marketCode) {
                        setErrorMessage(t(`bank-account-section.edit-view.api-error-message.${marketCode}`));
                    }
                    else if (errorCode) {
                        setErrorMessage(t(`bank-account-section.edit-view.api-error-message.${errorCode}`));
                    } else {
                        setErrorMessage(t(`bank-account-section.edit-view.api-error-message.no-connection`));
                    }
                })
                .finally(() => {
                    setIsValidating(false);
                });
        }
    };

    type InitialValues = {
        iban: string;
    };

    const initialValues: InitialValues = {
        iban: '',
    };

    const ibanValidation = Yup.string()
        .required(t(`bank-account-section.edit-view.error-message.iban-required`))
        .test(
            'notSameAsPrevious',
            t(`bank-account-section.edit-view.error-message.same-iban-provided`),
            areDifferent(previousIban?.slice(2)),
        )
        .matches(RegExp('^[0-9]{2}[a-zA-Z0-9_ ]*$'), t(`bank-account-section.edit-view.error-message.invalid-iban`))
        .test('ibanLength', t(`bank-account-section.edit-view.error-message.invalid-iban`), isValidLength);

    const confirmViewValidationSchema = Yup.object().shape({
        ibanHolderSelection: Yup.string().test(
            'required',
            t('bank-account-section.edit-view.error-message.iban-holder-required'),
            (value: string) => !(ibanHolders?.length !== 1 && !value),
        ),
        holderConfirmation: Yup.bool().oneOf(
            [true],
            t('bank-account-section.edit-view.error-message.iban-checkbox-not-checked'),
        ),
    });

    const getError = (errorCode: string, iban: string): boolean | Yup.ValidationError => {
        switch (errorCode) {
            case 'BFF_API_ERROR':
            case 'SAME_BANK_ACCOUNT':
            case 'INCORRECT_IBAN':
                return false;
            default:
                return new Yup.ValidationError(
                    t(`bank-account-section.edit-view.error-message.iban-validator-unavailable`),
                    iban,
                    'iban',
                );
        }
    };

    const validationSchema = Yup.object().shape({
        iban: ibanValidation.test(
            'asyncIban',
            t(`bank-account-section.edit-view.error-message.invalid-iban`),
            async (iban: string | undefined) => {
                if (!ibanValidation.isValidSync(iban)) {
                    setSignedBankData(undefined);
                    setSavedIban({});
                    return true;
                }

                const ibanWithCountry = `IT${iban}`;

                if (savedIban.iban === ibanWithCountry) {
                    if (!savedIban.error) {
                        return true;
                    }
                    return getError(savedIban.error, savedIban.iban);
                }
                setIsValidating(true);
                return await CpDataApi.post(getIbanValidationEndpoint(), { iban: ibanWithCountry })
                    .then(({ data }: { data: SignedData<BankData> }) => {
                        const { isValid } = data.data;
                        setSignedBankData(isValid ? data : undefined);
                        setSavedIban({ iban: ibanWithCountry, error: isValid ? undefined : 'INCORRECT_IBAN' });
                        return isValid;
                    })
                    .catch(error => {
                        const errorCode = parseErrorResponse<WithDefaultBusinessMarketApiError<IbanValidationError>>(
                            error,
                        ).code;
                        setSavedIban({ iban: ibanWithCountry, error: errorCode });
                        return getError(errorCode, ibanWithCountry);
                    })
                    .finally(() => {
                        setIsValidating(false);
                    });
            },
        ),
    });

    const handlerOnIbanHolderChange = (value: string) => {
        setSelectedIbanHolderId(value);
    };

    const sectionFields = (): DefinitionListItem[] => {
        const list: DefinitionListItem[] = [
            {
                label: t('bank-account-section.edit-view.iban-label'),
                value: savedIban.iban,
            },
            {
                label: t('bank-account-section.edit-view.bank-label'),
                value: signedBankData?.data.bankDetails?.bankName,
            },
        ];

        if (ibanHolders && ibanHolders.length === 1) {
            const holder = head(ibanHolders);
            list.push({
                label: t('bank-account-section.edit-view.holder-input-label'),
                value: holder?.name,
            });
            setTimeout(() => {
                handlerOnIbanHolderChange(holder?.holderId || '');
            });
        }

        return list.filter(item => !!item.value);
    };

    const getHolderOptions = (): ValidatedSelectItem[] =>
        ibanHolders?.map((ibanHolder: IbanHolder) => {
            return {
                label: ibanHolder.name,
                value: ibanHolder.holderId,
            };
        }) || [];

    const handleChange = (formik: FormikProps<InitialValues>) => async (e: React.ChangeEvent<{ rawValue: string }>) => {
        if (ibanValidation.isValidSync(e.target.rawValue)) {
            formik.setFieldTouched('iban');
        }
    };

    return (
        <div className={'u-mb iban-edit-view'}>
            <Heading className="u-pt" level={'6'}>
                {t('bank-account-section.edit-view.title')}
            </Heading>
            <Notification testId={'warning-notification'} status={NotificationStatus.warning} className={`u-mb`}>
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('bank-account-section.edit-view.info-box'),
                    }}
                />
            </Notification>
            {errorMessage && (
                <Notification testId={'error-notification'} status={NotificationStatus.error} className={`u-mb`}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: errorMessage,
                        }}
                    />
                </Notification>
            )}
            {currentView === 'IBAN_VALIDATION_VIEW' && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    validateOnBlur={!signedBankData}
                >
                    {(formik): JSX.Element => (
                        <Form onSubmit={(e): void => e.preventDefault()} data-testid="iban-form">
                            <Fieldset>
                                <UiBlockingSpinner isBlocking={isValidating}>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item>
                                                <CleaveInput
                                                    cleaveOptions={cleaveOptionsIban}
                                                    label={t('bank-account-section.edit-view.iban-input-label')}
                                                    name="iban"
                                                    testId="iban"
                                                    addonText="IT"
                                                    reversed
                                                    stateIcon
                                                    // TODO: Remove ts-ignore with next cp-shared update. Types are resolved there.
                                                    // @ts-ignore
                                                    handleChange={handleChange(formik)}
                                                    tooltip={
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: t(
                                                                    'bank-account-section.edit-view.iban-input-tooltip',
                                                                ),
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ButtonContainer className={'u-mt'} center>
                                            <Button
                                                secondary
                                                onClick={(): void => {
                                                    backButton();
                                                }}
                                                testId="dashboard"
                                            >
                                                {t('translation:editable-section-nav.back')}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    return formik.validateForm().then(() => {
                                                        formik.handleSubmit();
                                                    });
                                                }}
                                                testId="submit-iban"
                                                type="submit"
                                            >
                                                {t('bank-account-section.edit-view.submit-iban')}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </UiBlockingSpinner>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            )}
            {currentView === 'IBAN_CONFIRM_VIEW' && (
                <Formik
                    initialValues={{
                        ibanHolderSelection: '',
                        holderConfirmation: false,
                    }}
                    validationSchema={confirmViewValidationSchema}
                    onSubmit={handleConfirm}
                >
                    {({ handleSubmit }): JSX.Element => (
                        <Form onSubmit={(e): void => e.preventDefault()} data-testid="iban-form">
                            <Fieldset>
                                <UiBlockingSpinner isBlocking={isValidating}>
                                    <Fieldset.Row>
                                        <DefinitionListHorizontal list={sectionFields()} />
                                    </Fieldset.Row>
                                    {ibanHolders && ibanHolders.length > 1 && (
                                        <Fieldset.Row>
                                            <ValidatedSelect
                                                name="ibanHolderSelection"
                                                testId="ibanHolderSelection"
                                                label={t('bank-account-section.edit-view.holder-input-label')}
                                                placeholder={t(
                                                    'bank-account-section.edit-view.holder-input-placeholder',
                                                )}
                                                disablePlaceholder={true}
                                                selectItems={getHolderOptions()}
                                                onChange={handlerOnIbanHolderChange}
                                            />
                                        </Fieldset.Row>
                                    )}
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/1">
                                                <ValidatedCheckbox
                                                    label={
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    contractType === 'CO'
                                                                        ? t(
                                                                              'bank-account-section.edit-view.holder-checkbox-label-co',
                                                                          )
                                                                        : t(
                                                                              'bank-account-section.edit-view.holder-checkbox-label-le',
                                                                          ),
                                                            }}
                                                        />
                                                    }
                                                    name="holderConfirmation"
                                                    testId="holderConfirmation"
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ButtonContainer className={'u-mt'} center>
                                            <Button
                                                secondary
                                                onClick={(): void => {
                                                    setErrorMessage('');
                                                    setCurrentView('IBAN_VALIDATION_VIEW');
                                                }}
                                                testId="backToValidationView"
                                            >
                                                {t('translation:editable-section-nav.back')}
                                            </Button>
                                            <Button onClick={() => handleSubmit()} testId="confirm-iban" type="submit">
                                                {t('bank-account-section.edit-view.confirm-iban')}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </UiBlockingSpinner>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};
