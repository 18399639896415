import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

import { VehicleDetails } from '../../../common';
import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';

import { LicensePlate as LicensePlateComponent } from 'components/license-plate';

export const VehicleDetailsUi: React.FC<{ vehicleDetails?: VehicleDetails }> = ({ vehicleDetails }) => {
    const { t } = useTranslation('vehicle-details');
    if (!vehicleDetails) {
        return null;
    }

    const { name, brand, licensePlate, chassisNumber, carImmatriculationDate } = vehicleDetails;

    const items: DefinitionListItem[] = [
        {
            label: t('details.chassis-number'),
            value: chassisNumber,
        },
        {
            label: t('details.car-immatriculation-date'),
            value: carImmatriculationDate ? carImmatriculationDate : '-',
        },
    ];
    const nameVal = name || '';
    return (
        <DataOverview title={`${brand} ${nameVal}`} withoutCardEffect>
            <Layout>
                <Layout.Item>
                    <LicensePlateComponent size="small" licensePlateNumber={licensePlate || '-'} />
                </Layout.Item>

                <Layout.Item>
                    <DefinitionListHorizontal list={items} />
                </Layout.Item>
            </Layout>
        </DataOverview>
    );
};
