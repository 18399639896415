import { getGreetingEndpoint, getProspectGreetingEndpoint, Greeting } from '../../common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { GreetingDataSelector } from './GreetingDataSelector';
import { fetchGreeting } from './GreetingSlice';

export function useGreeting(
    isProspect = false,
    isFetchingAllowed = true,
): AbstractDataState<Greeting, DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(
        fetchGreeting,
        GreetingDataSelector,
        false,
        {
            url: isProspect ? getProspectGreetingEndpoint() : getGreetingEndpoint(),
        },
        undefined,
        isFetchingAllowed,
    );
}
