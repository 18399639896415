import {
    HeroImage as LandingPageTeaserUi,
    useAuthentication,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import { useLogin } from 'auth/useLogin';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { useLandingPageTeaser } from './useLandingPageTeaser';

export const LandingPageTeaser: React.FC = () => {
    const { cmsContent: landingPageTeaser, isLoading, loadingError } = useLandingPageTeaser();
    const { isAuthenticated } = useAuthentication();
    useAnalyticsPageViewTracker('landingPage', !!landingPageTeaser, isAuthenticated);
    const { onAction } = useAnalyticsActionTracker('login');
    const login = useLogin();
    const onClick = () => {
        onAction();
        login();
    };

    const LandingPageTeaserWithHandlers = withLoadingAndNoConnectionHandler(LandingPageTeaserUi);

    return (
        <LandingPageTeaserWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            title={landingPageTeaser?.title || ''}
            imageUrl={landingPageTeaser?.imageUrl || ''}
            subTitle={landingPageTeaser?.subTitle}
            text={landingPageTeaser?.text}
            buttonText={landingPageTeaser?.buttonText}
            clickHandler={onClick}
            inverted={!!landingPageTeaser?.isInverted}
        />
    );
};
