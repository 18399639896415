import { CustomerAddress, CustomerType, getAddressEndpoint, getProspectProfileAddressEndpoint } from '../../../common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { CustomerAddressDataSelector } from './CustomerAddressDataSelector';
import { fetchCustomerAddress } from './CustomerAddressSlice';

export function useCustomerAddress(
    customerType: CustomerType,
    isProspect: boolean,
): AbstractDataState<CustomerAddress, DefaultBusinessMarketApiErrorCode> {
    const payload = { customerType };
    return useGetSimpleApiData(fetchCustomerAddress, CustomerAddressDataSelector, undefined, {
        data: payload,
        url: isProspect ? getProspectProfileAddressEndpoint() : getAddressEndpoint(customerType),
    });
}
