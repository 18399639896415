import React, { useState } from 'react';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { DownloadLink } from './download-link/DownloadLink';
import { Modal } from '@vwfs-bronson/bronson-react';

export const UnpaidAlert: React.FC<{ downloadLink: string }> = ({ downloadLink }) => {
    const { t } = useTranslation('unpaid-alert');

    const [downloadError, setDownloadError] = useState<boolean | null>(null);

    return (
        <>
            <Notification
                status={NotificationStatus.warning}
                headline={t('headline')}
                text={t('text')}
                testId={'unpaidAlert'}
            >
                {t('text-before-link')} <DownloadLink downloadLink={downloadLink} setDownloadError={setDownloadError} />
            </Notification>

            <Modal
                shown={!!downloadError}
                status="info"
                onClose={(): void => setDownloadError(null)}
                title={t('error.headline')}
                testId="downloadFailureModal"
            >
                {t('error.text')}
            </Modal>
        </>
    );
};
