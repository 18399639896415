import React from 'react';
import { useTranslation } from 'react-i18next';

import { withLoadingHandler } from 'components/integration-wrapper';

import { Greeting as GreetingUi } from '@cp-shared-8/frontend-ui';
import { Greeting as GreetingType } from '../../common';

const GreetingWithHandlers = withLoadingHandler(GreetingUi);

type GreetingProps = {
    greeting: GreetingType | undefined;
    isLoading: boolean;
    loadingError: boolean;
};

export const Greeting: React.FC<GreetingProps> = ({ greeting, isLoading, loadingError }) => {
    const { t } = useTranslation('greeting');

    const hasValidGreetingAndNoLoadingError = !loadingError && greeting;
    const fullGreetingText = t('greeting', {
        greetingName: hasValidGreetingAndNoLoadingError ? greeting?.customerName : '',
    });
    return <GreetingWithHandlers isLoading={isLoading} fullGreetingText={fullGreetingText} />;
};
