import { DataOverview } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { CustomerType, Identification } from '../../../../common';

export type IdentificationSectionUiProps = {
    customerType?: CustomerType;
    identification?: Identification;
};

export const IdentificationSectionUi: React.FC<IdentificationSectionUiProps> = ({ customerType, identification }) => {
    const { t } = useTranslation('my-profile');

    let identificationList: DefinitionListItem[];
    if (!identification) {
        return null;
    }

    switch (customerType) {
        case 'Private':
            identificationList = [
                {
                    label: t('identification.name'),
                    value: identification.privateData?.firstName,
                },
                {
                    label: t('identification.surname'),
                    value: identification.privateData?.lastName,
                },
                {
                    label: t('identification.fiscal-code'),
                    value: identification.privateData?.taxCode,
                },
            ].filter(item => !!item.value);
            break;
        case 'BusinessMan':
            identificationList = [
                {
                    label: t('identification.company-name'),
                    value: identification.businessManData?.companyName,
                },
                {
                    label: t('identification.fiscal-code'),
                    value: identification.businessManData?.taxCode,
                },
                {
                    label: t('identification.vat-number'),
                    value: identification.businessManData?.vatNumber,
                },
            ].filter(item => !!item.value);
            break;
        case 'LegalEntity':
            identificationList = [
                {
                    label: t('identification.company-name'),
                    value: identification.legalEntityData?.companyName,
                },
                {
                    label: t('identification.vat-number'),
                    value: identification.legalEntityData?.vatNumber,
                },
            ].filter(item => !!item.value);
            break;
        default:
            identificationList = [];
    }

    return (
        <DataOverview title={t('identification.headline')}>
            <DefinitionListHorizontal list={identificationList} />
        </DataOverview>
    );
};
