import { CpDataApi } from 'cp-xhr';
import React from 'react';

import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { FetchContractError, getContractsEndpoint, getVehicleDetailsEndpoint } from '../../common';

import { ContractsWithMultipleMixedEntries } from 'components/contracts/ExampleData';
import { VehicleDetailsWithAllValues } from './ExampleData';

const contract = {
    ...ContractsWithMultipleMixedEntries[0],
    _links: {
        vehicleDetails: getVehicleDetailsEndpoint(ContractsWithMultipleMixedEntries[0].contractId),
    },
};

const mockOptionsVehicleDetails: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: VehicleDetailsWithAllValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const vehicleDetailsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Vehicle Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsVehicleDetails,
    url: getVehicleDetailsEndpoint(contract.contractId),
};

const mockOptionsContracts: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: [contract],
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const contractsMock: EndpointMockDescription<FetchContractError> = {
    name: 'Contract Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsContracts,
    url: getContractsEndpoint(),
};

export const VehicleDetailsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [contractsMock, vehicleDetailsMock]);

    return <div>{storyFn()}</div>;
};
