import React from 'react';

import { AmortizationTableHeader as AmortizationTableHeaderProps, ContractLinks } from '../../../common';
import { AmortizationTableHeaderUi } from '../amortization-table-header/AmortizationTableHeaderUi';
import { AmortizationTableContent } from '../amortization-table-content/AmortizationTableContent';
import { Button, Layout } from '@vwfs-bronson/bronson-react';
import { dashboardPagePath } from '../../navigation/paths';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const AmortizationTableUi: React.FC<{
    amortizationTableHeader?: AmortizationTableHeaderProps;
    contractDataLinks?: ContractLinks;
    contractId: string;
}> = ({ amortizationTableHeader, contractDataLinks, contractId }) => {
    const { t } = useTranslation('amortization-table');
    const history = useHistory();

    const goDashboard = () => history.push(dashboardPagePath());

    return (
        <>
            <AmortizationTableHeaderUi amortizationTableHeader={amortizationTableHeader} />
            <AmortizationTableContent
                productType={amortizationTableHeader?.product}
                contractNumber={amortizationTableHeader?.contractNumber}
                link={contractDataLinks?.amortizationTablePdfDownload}
                contractId={contractId}
            />
            <Layout className={'u-mb-small'}>
                <Layout.Item className={'u-text-center'} default="1/1">
                    <Button onClick={goDashboard} testId="dashboardButton" type="button">
                        {t('dashboard-button')}
                    </Button>
                </Layout.Item>
            </Layout>
        </>
    );
};
