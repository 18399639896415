import React from 'react';
import { useTranslation } from 'react-i18next';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { ConsumerCreditFinancialDetailsUi } from './ui';
import { useFinancialDetails } from '../useFinancialDetails';
import { AccordionItem } from '@cp-shared-8/frontend-ui';

const ConsumerCreditFinancialDetailsWithHandlers = withLoadingAndNoConnectionHandler(ConsumerCreditFinancialDetailsUi);

export const ConsumerCreditFinancialDetails: React.FC<{ contractId: string }> = ({ contractId }) => {
    const { t } = useTranslation('financial-details');

    const { data: consumerCreditFinancialDetails, isLoading, loadingError } = useFinancialDetails(contractId);

    return (
        <AccordionItem title={t('headline')}>
            <ConsumerCreditFinancialDetailsWithHandlers
                isLoading={isLoading}
                consumerCreditFinancialDetails={consumerCreditFinancialDetails}
                hasError={!!loadingError}
            />
        </AccordionItem>
    );
};
