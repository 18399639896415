import {
    Contract,
    dateFormatWithoutSeparators,
    formatAsCurrency,
    formatAsDate,
    getIrapCalculationPdfEndpoint,
    IrapCalculationData,
    VehicleDetails,
} from '../../../common';
import { ProductTable, DescriptionList, Layout, LicensePlate, Button, Modal } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DividedContractHeader,
    Notification,
    NotificationStatus,
    Spinner,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import { CpDataApi } from '../../../cp-xhr';
import base64ToBlob from 'b64-to-blob';
import { saveAs as downloadFileAs } from 'file-saver';
import { formatCpDate } from '@cp-shared-8/common-utilities';

type IrapCalculationUiProps = {
    contractDetails?: Contract;
    irapCalculation?: IrapCalculationData;
    vehicleDetails?: VehicleDetails;
};

export const IrapCalculationUi: React.FC<IrapCalculationUiProps> = ({
    contractDetails,
    irapCalculation,
    vehicleDetails,
}) => {
    const { t } = useTranslation('irap-calculation');
    const { onAction } = useAnalyticsActionTracker('onIrapDownload');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDownloadError, setIsDownloadError] = useState(false);

    if (!contractDetails || !vehicleDetails || !irapCalculation) {
        return null;
    }

    const {
        contractInfo: {
            contractStartDate,
            lastInstallmentDate,
            calculationStartDate,
            calculationEndDate,
            vehiclePrice,
            redeemAmount,
            leaseTotalAmount,
        },
        irapInterests,
    } = irapCalculation;

    const rightSideContent = (
        <ProductTable>
            <ProductTable.Section>
                <DescriptionList horizontal>
                    <DescriptionList.Group termText={t('contract-card.right-section.contract-start-date')}>
                        <DescriptionList.Detail>{formatAsDate(contractStartDate)}</DescriptionList.Detail>
                    </DescriptionList.Group>
                    <DescriptionList.Group termText={t('contract-card.right-section.contract-end-date')}>
                        <DescriptionList.Detail>{formatAsDate(lastInstallmentDate)}</DescriptionList.Detail>
                    </DescriptionList.Group>
                    <DescriptionList.Group termText={t('contract-card.right-section.calculation-start-date')}>
                        <DescriptionList.Detail>{formatAsDate(calculationStartDate)}</DescriptionList.Detail>
                    </DescriptionList.Group>
                    <DescriptionList.Group termText={t('contract-card.right-section.calculation-end-date')}>
                        <DescriptionList.Detail>{formatAsDate(calculationEndDate)}</DescriptionList.Detail>
                    </DescriptionList.Group>
                    <DescriptionList.Group termText={t('contract-card.right-section.vehicle-price')}>
                        <DescriptionList.Detail>{formatAsCurrency(vehiclePrice)}</DescriptionList.Detail>
                    </DescriptionList.Group>
                    <DescriptionList.Group termText={t('contract-card.right-section.redeem-amount')}>
                        <DescriptionList.Detail>{formatAsCurrency(redeemAmount)}</DescriptionList.Detail>
                    </DescriptionList.Group>
                    <DescriptionList.Group termText={t('contract-card.right-section.lease-total-amount')}>
                        <DescriptionList.Detail>{formatAsCurrency(leaseTotalAmount)}</DescriptionList.Detail>
                    </DescriptionList.Group>
                    <DescriptionList.Group
                        title={t('contract-card.right-section.irap-interests', {
                            calculationStartDate: formatAsDate(calculationStartDate),
                            calculationEndDate: formatAsDate(calculationEndDate),
                        })}
                    >
                        <DescriptionList.Detail>{formatAsCurrency(irapInterests)}</DescriptionList.Detail>
                    </DescriptionList.Group>
                </DescriptionList>
            </ProductTable.Section>
        </ProductTable>
    );

    const { product, contractNumber, vehicleInformation, licensePlateNumber } = contractDetails;
    const { chassisNumber } = vehicleDetails;

    const downloadPdf = (fileName: string): void => {
        onAction();
        setIsSubmitting(true);
        const url = getIrapCalculationPdfEndpoint(contractDetails.contractId);
        CpDataApi.get(url)
            .then(response => {
                const fileContent = response.data.base64;
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(fileContent, pdfContentType);
                downloadFileAs(pdfBlob, fileName);
                setIsSubmitting(false);
            })
            .catch(() => {
                setIsSubmitting(false);
                setIsDownloadError(true);
            });
    };

    const fileName = `CalcoloIRAP_AnnoFiscale_${formatCpDate()
        .toMoment()
        .year()}_${contractNumber}_${formatCpDate().format(dateFormatWithoutSeparators)}`;

    return (
        <>
            <Layout>
                <Layout.Item>
                    <DividedContractHeader
                        contractIdentifier={
                            licensePlateNumber && (
                                <LicensePlate
                                    size={'medium'}
                                    country={'Italy'}
                                    countryCode={'I'}
                                    registrationNumber={licensePlateNumber || '-'}
                                    horizontalStripEu={true}
                                    euStars={true}
                                />
                            )
                        }
                        carInformation={vehicleInformation}
                        contractCategory={product}
                        contractNumber={contractNumber}
                        extraInformation={chassisNumber ? `${t('contract-card.chassis')} ${chassisNumber}` : undefined}
                        rightSideContent={rightSideContent}
                    />
                </Layout.Item>
                <Layout.Item>
                    <Notification text={t('info')} status={NotificationStatus.info} />
                </Layout.Item>
                <Layout.Item default="1/1">
                    {isSubmitting ? (
                        <Spinner small />
                    ) : (
                        <Button
                            onClick={(): void => downloadPdf(fileName)}
                            testId="pdfDownloadLink"
                            element="a"
                            icon="download"
                        >
                            {t('download-link')}
                        </Button>
                    )}
                </Layout.Item>
                )
            </Layout>
            <Modal
                shown={isDownloadError}
                status="info"
                onClose={(): void => setIsDownloadError(false)}
                title={t('modal.title')}
                testId="downloadFailureModal"
            >
                {t('modal.text')}
            </Modal>
        </>
    );
};
