import React from 'react';
import { useTranslation } from 'react-i18next';

import { IdentificationSection } from '../identification-section';
import { CustomerType } from '../../../common';
import { Layout, Tabs } from '@vwfs-bronson/bronson-react';
import { tabs } from '../availableTabs';
import { AddressSection } from '../address-section';
import { ContactSection } from '../contact-section';
import { CustomerHappinessIndex } from 'components/customer-happiness-index/CustomerHappinessIndex';

export type MyProfileProps = {
    customerType?: CustomerType;
    isProspect: boolean;
};

export const MyProfileUi: React.FC<MyProfileProps> = ({ customerType, isProspect }) => {
    const { t } = useTranslation('my-profile');

    if (!customerType) {
        return null;
    }
    const isPrivateBusinessContract = customerType === 'PrivateBusinessMan';

    return (
        <Layout className={'u-mt-large'}>
            {isPrivateBusinessContract && (
                <Layout.Item>
                    <Tabs defaultSelected={tabs.private} testId={'registrationTabs'}>
                        <Tabs.Header>
                            <Tabs.HeaderItem tabId={tabs.private} className={`cp-tab-button-${tabs.private}`}>
                                {t('tabs.private-button')}
                            </Tabs.HeaderItem>
                            <Tabs.HeaderItem tabId={tabs.business} className={`cp-tab-button-${tabs.business}`}>
                                {t('tabs.business-button')}
                            </Tabs.HeaderItem>
                        </Tabs.Header>
                        <Tabs.Content>
                            <Tabs.ContentItem tabId={tabs.private} testId={`tabContent${tabs.private}`}>
                                <IdentificationSection
                                    customerType={'Private'}
                                    isProspect={false}
                                    isTabsPresent={isPrivateBusinessContract}
                                />
                                <AddressSection
                                    customerType={'Private'}
                                    isProspect={false}
                                    isTabsPresent={isPrivateBusinessContract}
                                />
                                <ContactSection
                                    customerType={'Private'}
                                    isProspect={false}
                                    isTabsPresent={isPrivateBusinessContract}
                                />
                            </Tabs.ContentItem>
                            <Tabs.ContentItem tabId={tabs.business} testId={`tabContent${tabs.business}`}>
                                <IdentificationSection
                                    customerType={'BusinessMan'}
                                    isProspect={false}
                                    isTabsPresent={isPrivateBusinessContract}
                                />
                                <AddressSection
                                    customerType={'BusinessMan'}
                                    isProspect={false}
                                    isTabsPresent={isPrivateBusinessContract}
                                />
                                <ContactSection
                                    customerType={'BusinessMan'}
                                    isProspect={false}
                                    isTabsPresent={isPrivateBusinessContract}
                                />
                            </Tabs.ContentItem>
                        </Tabs.Content>
                    </Tabs>
                </Layout.Item>
            )}

            {!isPrivateBusinessContract && (
                <>
                    <Layout.Item>
                        <IdentificationSection isProspect={isProspect} customerType={customerType} />
                    </Layout.Item>
                    <Layout.Item>
                        <AddressSection isProspect={isProspect} customerType={customerType} />
                    </Layout.Item>
                    <Layout.Item>
                        <ContactSection isProspect={isProspect} customerType={customerType} />
                    </Layout.Item>
                </>
            )}

            <Layout.Item>{customerType && <CustomerHappinessIndex customerType={customerType} />}</Layout.Item>
        </Layout>
    );
};
