import React from 'react';
import { Card } from '@vwfs-bronson/bronson-react';
import { ContractData } from '../../../common';
import { ContractHeader } from '../../contracts/contract-header';

export const RequestDocumentHeader: React.FC<{ contract: ContractData }> = ({ contract }) => {
    return (
        <Card element="article">
            <ContractHeader contract={contract} />
        </Card>
    );
};
