import { Contract, GeneralContractDetails } from '../../../common';
import { sortBy, groupBy } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { areContractDetailsLoading, isKnownBrand } from './utils';

export type ContractData = {
    contractId: string;
    brand: string;
    contractStartDate: string;
    name?: string;
    iban?: string;
    numberOfInstallments?: number;
};

type ContractsDataResponse = {
    contractsData: ContractData[];
    isLoading: boolean;
};

export const useMostRecentContractsForEachBrand = (contracts: Contract[] = []): ContractsDataResponse => {
    const contractDetails = useSelector((state: RootState) => state.generalContractDetails);

    const isLoadingContractDetails = areContractDetailsLoading<GeneralContractDetails>(contractDetails);
    if (isLoadingContractDetails) return { isLoading: true, contractsData: [] };

    const mappedContracts: ContractData[] = contracts
        .map(({ contractId, isActive, brand = '', name = '' }) => ({
            contractId,
            isActive,
            brand,
            name,
            contractStartDate: contractDetails[contractId]?.data?.contractStartDate || '',
            iban: contractDetails[contractId]?.data?.iban,
            numberOfInstallments: contractDetails[contractId]?.data?.numberOfInstallments,
        }))
        .filter(contract => {
            if (
                contract.brand.toLowerCase() === 'seat' &&
                (contract.name.toLowerCase() === 'formentor' || contract.name.toLowerCase() === 'born')
            )
                return false;

            return (
                contract.isActive && !!contract.contractStartDate && !!contract.brand && isKnownBrand(contract.brand)
            );
        });

    const groupedByBrand = groupBy(mappedContracts, 'brand');

    const mostRecentContractsForEachBrand = Object.values(groupedByBrand).map(brandedContracts => {
        const sortedByDateAscending = sortBy(brandedContracts, item => new Date(item.contractStartDate).getTime());
        return sortedByDateAscending[sortedByDateAscending.length - 1];
    });

    return {
        isLoading: false,
        contractsData: mostRecentContractsForEachBrand,
    };
};
