import {
    Contract,
    EarlySettlement,
    EarlySettlementRequestResponse,
    formatAsCurrency,
    formatAsDate,
} from '../../../common';
import {
    DividedContractHeader,
    Notification,
    NotificationStatus,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import { ProductTable, Heading, InfoIcon, Layout, LicensePlate, DescriptionList } from '@vwfs-bronson/bronson-react';
import { dashboardPagePath, earlySettlementPath, offlinePaymentPath } from 'components/navigation/paths';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { OfflinePayment } from '../offline-payment/OfflinePayment';
import { TesNotification } from '../tes-notification/TesNotification';
import { TodaysValidQuote } from '../todays-valid-quote/TodaysValidQuote';

type TotalEarlySettlementUiProps = {
    earlySettlementData?: EarlySettlement;
    contractId: string;
    tesStatus?: EarlySettlementRequestResponse;
    contractDetails?: Contract;
};

export const TotalEarlySettlementUi: React.FC<TotalEarlySettlementUiProps> = ({
    earlySettlementData,
    contractId,
    tesStatus,
    contractDetails,
}) => {
    const { path } = useRouteMatch();
    const { t } = useTranslation('total-early-settlement');
    const history = useHistory();

    const { onAction } = useAnalyticsActionTracker('onEarlySettlementProceedToPayment');

    if (!earlySettlementData || !contractDetails) {
        return (
            <TesNotification
                tesStatus={tesStatus?.statusId ? tesStatus : contractDetails ? { statusId: 502 } : { statusId: 500 }}
            />
        );
    }
    if (earlySettlementData.status === 'Error') {
        return (
            <Notification status={NotificationStatus.error} headline={t('valid-quote.error.not-available-1.title')}>
                <span dangerouslySetInnerHTML={{ __html: t('valid-quote.error.not-available-1.text') }} />
            </Notification>
        );
    }
    if (earlySettlementData.status === 'ConnectionError') {
        return (
            <Notification status={NotificationStatus.error} headline={t('valid-quote.error.no-connection.title')}>
                <span dangerouslySetInnerHTML={{ __html: t('valid-quote.error.no-connection.text') }} />
            </Notification>
        );
    }

    if (
        !earlySettlementData.contractNumber ||
        !earlySettlementData.firstInstallmentDate ||
        earlySettlementData.installmentAmount == null ||
        earlySettlementData.numberOfInstallments == null ||
        !earlySettlementData.product ||
        !earlySettlementData.settlementExpirationDate ||
        earlySettlementData.totalSettlementAmount == null
    ) {
        return (
            <Notification
                status={NotificationStatus.error}
                headline={t('notification-error.not-all-mandatory-fields.tes.headline')}
                text={t('notification-error.not-all-mandatory-fields.tes.text')}
            />
        );
    }

    const { product, contractNumber, vehicleInformation, licensePlateNumber } = contractDetails;

    if (!product || !contractNumber || !vehicleInformation) {
        return (
            <Notification
                status={NotificationStatus.error}
                headline={t('notification-error.not-all-mandatory-fields.contract.headline')}
                text={t('notification-error.not-all-mandatory-fields.contract.text')}
            />
        );
    }

    const {
        chassisNumber,
        numberOfInstallments,
        installmentAmount,
        firstInstallmentDate,
        lastInstallmentDate,
        lastInstallment,
        balloonFlag,
        lastDueDate,
    } = earlySettlementData;

    const rightSideContentCO = (
        <ProductTable>
            <Heading level={4}>{t('valid-quote.contract-card.right-co.headline')}</Heading>
            <ProductTable.Section>
                <DescriptionList horizontal>
                    <DescriptionList.Group termText={t('valid-quote.contract-card.right-co.number-of-installments')}>
                        <DescriptionList.Detail> {numberOfInstallments}</DescriptionList.Detail>
                    </DescriptionList.Group>
                    <DescriptionList.Group
                        termText={
                            <>
                                {t('valid-quote.contract-card.right-co.installment-amount')}
                                <InfoIcon icon="semantic-info">
                                    {t('valid-quote.contract-card.right-co.installment-amount-tooltip')}
                                </InfoIcon>
                            </>
                        }
                    >
                        <DescriptionList.Detail> {formatAsCurrency(installmentAmount)}</DescriptionList.Detail>
                    </DescriptionList.Group>
                    <DescriptionList.Group
                        termText={
                            <>
                                {t('valid-quote.contract-card.right-co.first-installment')}
                                <InfoIcon icon="semantic-info">
                                    {t('valid-quote.contract-card.right-co.first-installment-tooltip')}
                                </InfoIcon>
                            </>
                        }
                    >
                        <DescriptionList.Detail> {formatAsDate(firstInstallmentDate)}</DescriptionList.Detail>
                    </DescriptionList.Group>
                    <DescriptionList.Group
                        termText={
                            <>
                                {t('valid-quote.contract-card.right-co.last-installment')}
                                <InfoIcon icon="semantic-info">
                                    {t('valid-quote.contract-card.right-co.last-installment-tooltip')}
                                </InfoIcon>
                            </>
                        }
                    >
                        <DescriptionList.Detail> {formatAsDate(lastInstallmentDate)}</DescriptionList.Detail>
                    </DescriptionList.Group>
                    {balloonFlag && (
                        <DescriptionList.Group
                            termText={
                                <>
                                    {t('valid-quote.contract-card.right-co.final-installment')}
                                    <InfoIcon icon="semantic-info">
                                        {t('valid-quote.contract-card.right-co.final-installment-tooltip')}
                                    </InfoIcon>
                                </>
                            }
                        >
                            <DescriptionList.Detail> {formatAsCurrency(lastInstallment)}</DescriptionList.Detail>
                        </DescriptionList.Group>
                    )}
                </DescriptionList>
            </ProductTable.Section>
        </ProductTable>
    );

    const rightSideContentLE = (
        <ProductTable>
            <Heading level={4}>{t('valid-quote.contract-card.right-le.headline')}</Heading>
            <ProductTable.Section>
                <DescriptionList horizontal>
                    <DescriptionList.Group termText={t('valid-quote.contract-card.right-le.number-of-installments')}>
                        <DescriptionList.Detail> {numberOfInstallments}</DescriptionList.Detail>
                    </DescriptionList.Group>
                    <DescriptionList.Group
                        termText={
                            <>
                                {t('valid-quote.contract-card.right-le.installment-amount')}
                                <InfoIcon icon="semantic-info">
                                    {t('valid-quote.contract-card.right-le.installment-amount-tooltip')}
                                </InfoIcon>
                            </>
                        }
                    >
                        <DescriptionList.Detail> {formatAsCurrency(installmentAmount)}</DescriptionList.Detail>
                    </DescriptionList.Group>

                    <DescriptionList.Group
                        termText={
                            <>
                                {t('valid-quote.contract-card.right-le.first-installment')}
                                <InfoIcon icon="semantic-info">
                                    {t('valid-quote.contract-card.right-le.first-installment-tooltip')}
                                </InfoIcon>
                            </>
                        }
                    >
                        <DescriptionList.Detail> {formatAsDate(firstInstallmentDate)}</DescriptionList.Detail>
                    </DescriptionList.Group>

                    <DescriptionList.Group
                        termText={
                            <>
                                {t('valid-quote.contract-card.right-le.last-installment')}
                                <InfoIcon icon="semantic-info">
                                    {t('valid-quote.contract-card.right-le.last-installment-tooltip')}
                                </InfoIcon>
                            </>
                        }
                    >
                        <DescriptionList.Detail> {formatAsDate(lastDueDate)}</DescriptionList.Detail>
                    </DescriptionList.Group>

                    <DescriptionList.Group
                        termText={
                            <>
                                {t('valid-quote.contract-card.right-le.end-date')}
                                <InfoIcon icon="semantic-info">
                                    {t('valid-quote.contract-card.right-le.end-date-tooltip')}
                                </InfoIcon>
                            </>
                        }
                    >
                        <DescriptionList.Detail> {formatAsDate(lastInstallmentDate)}</DescriptionList.Detail>
                    </DescriptionList.Group>
                </DescriptionList>
            </ProductTable.Section>
        </ProductTable>
    );

    const redirectToOfflinePayment = (): void => {
        onAction();
        history.push(offlinePaymentPath(contractId));
    };

    const redirectToTodaysValidQuote = (): void => {
        history.push(earlySettlementPath(contractId));
    };

    return (
        <>
            <Layout>
                <Layout.Item>
                    <DividedContractHeader
                        contractIdentifier={
                            licensePlateNumber && (
                                <LicensePlate
                                    size={'medium'}
                                    country={'Italy'}
                                    countryCode={'I'}
                                    registrationNumber={licensePlateNumber || '-'}
                                    horizontalStripEu={true}
                                    euStars={true}
                                />
                            )
                        }
                        carInformation={vehicleInformation}
                        contractCategory={product}
                        contractNumber={contractNumber}
                        extraInformation={
                            chassisNumber ? t('valid-quote.contract-card.chassis') + chassisNumber : undefined
                        }
                        rightSideContent={product === 'LE' ? rightSideContentLE : rightSideContentCO}
                    />
                </Layout.Item>
                <Layout.Item>
                    <Switch>
                        <Route path={offlinePaymentPath()}>
                            <OfflinePayment
                                handleBackButtonClick={redirectToTodaysValidQuote}
                                handleBackToDashboardButtonClick={() => history.push(dashboardPagePath())}
                                earlySettlementData={earlySettlementData}
                            />
                        </Route>
                        <Route path={path}>
                            <TodaysValidQuote
                                handleBackButtonClick={() => history.push(dashboardPagePath())}
                                handleContinueButtonClick={redirectToOfflinePayment}
                                earlySettlementData={earlySettlementData}
                            />
                        </Route>
                    </Switch>
                </Layout.Item>
            </Layout>
        </>
    );
};
