import React from 'react';
import { Contract, ContractType } from '../../../common';
import { IrapCalculation } from '../../../components/irap-calculation';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';

export const IrapCalculationPageUi: React.FC<{ contract?: Contract }> = ({ contract }) => {
    const { t } = useTranslation('irap-calculation');
    if (!contract) return null;

    if (contract.contractType === ContractType.LEASING) return <IrapCalculation contract={contract} />;

    return (
        <Notification
            text={t('invalid-contract')}
            status={NotificationStatus.error}
            testId={'invalid-contract-notification'}
        />
    );
};
