import {
    buildContract,
    ConsumerCreditContract,
    Contract,
    ContractStatus,
    ContractType,
    getAmortizationTableBundledProductsDownloadLinks,
    getAmortizationTableHeaderEndpoint,
    getAmortizationTablePdfDownloadEndpoint,
    getBundledProductDetailsEndpoint,
    getContractDetailsEndpoint,
    getFinancialDetailsEndpoint,
    getTaxesTableHeaderEndpoint,
    getVehicleDetailsEndpoint,
    LeasingContract,
} from '../../common';
import { formatCpDate, ISO_DATE_FORMAT } from '@cp-shared-8/common-utilities';

const ContractA: Contract = {
    vehicleInformation: 'VOLKSWAGEN Golf',
    contractNumber: '197333',
    contractId: '4462618',
    contractType: ContractType.LEASING,
    product: 'LE',
    licensePlateNumber: 'FV805WY',
    vin: 'abc12345678901def',
    encryptedVin: 'Encrypted-abc12345678901def',
    contractStatus: ContractStatus.ACTIVE,
    isLeasing: true,
    isConsumerCredit: false,
    nextDueDate: formatCpDate('2020-10-08', ISO_DATE_FORMAT).toCpDate(),
    nextDueAmount: 378.99,
    unpaidAlert: false,
    isActive: true,
    isExpired: false,
    brand: 'VOLKSWAGEN',
    name: 'Golf',
    _links: {
        contractDetails: getContractDetailsEndpoint('4462618'),
        financialDetails: getFinancialDetailsEndpoint('4462618'),
        bundledProductDetails: getBundledProductDetailsEndpoint('4889624'),
        amortizationTableHeader: getAmortizationTableHeaderEndpoint('4462618'),
        amortizationTablePdfDownload: getAmortizationTablePdfDownloadEndpoint('4462618'),
        taxesTableHeader: getTaxesTableHeaderEndpoint('4462618'),
        amortizationTableBundledProductsDownloadLinks: getAmortizationTableBundledProductsDownloadLinks('4462618'),
        vehicleDetails: getVehicleDetailsEndpoint('4462618'),
    },
};

const ContractB: Contract = {
    vehicleInformation: 'Ducati Streetfighter V4S',
    contractNumber: '210333',
    contractId: '4889624',
    contractType: ContractType.CONSUMER_CREDIT,
    product: 'CO',
    isLeasing: false,
    isConsumerCredit: true,
    isActive: true,
    isExpired: false,
    licensePlateNumber: 'EC56984',
    vin: 'xyz12345678901def',
    encryptedVin: 'Encrypted-xyz12345678901def',
    contractStatus: ContractStatus.ACTIVE,
    nextDueDate: formatCpDate('2020-11-08', ISO_DATE_FORMAT).toCpDate(),
    nextDueAmount: 567.2,
    unpaidAlert: false,
    brand: 'AUDI',
    name: 'A5',
    _links: {
        contractDetails: getContractDetailsEndpoint('4889624'),
        financialDetails: getFinancialDetailsEndpoint('4889624'),
        bundledProductDetails: getBundledProductDetailsEndpoint('4889624'),
        amortizationTableHeader: getAmortizationTableHeaderEndpoint('4889624'),
        amortizationTablePdfDownload: getAmortizationTablePdfDownloadEndpoint('4889624'),
        taxesTableHeader: getTaxesTableHeaderEndpoint('4889624'),
        amortizationTableBundledProductsDownloadLinks: getAmortizationTableBundledProductsDownloadLinks('4889624'),
    },
};

const EarlyClosedLeasingContract: Contract = {
    ...ContractA,
    closeDateOfTheContract: formatCpDate('2019-11-08', ISO_DATE_FORMAT).toCpDate(),
    isActive: false,
    isExpired: true,
    nextDueDate: undefined,
    nextDueAmount: undefined,
    contractStatus: ContractStatus.EXPIRED,
};

const EarlyClosedConsumerCreditContract: Contract = {
    ...ContractB,
    closeDateOfTheContract: formatCpDate('2019-11-08', ISO_DATE_FORMAT).toCpDate(),
    isActive: false,
    isExpired: true,
    nextDueDate: undefined,
    nextDueAmount: undefined,
    contractStatus: ContractStatus.EXPIRED,
};

export const ContractsWithAllValues: Contract[] = [ContractA, ContractB];

export const ContractsWithMultipleMixedEntries: Contract[] = ContractsWithAllValues;

export const ContractsWithInactiveContract: Contract[] = [
    ContractA,
    ContractB,
    {
        ...ContractA,
        isActive: false,
        isExpired: true,
        contractStatus: ContractStatus.EXPIRED,
        contractStartDate: formatCpDate('2017-11-08', ISO_DATE_FORMAT).toCpDate(),
        successionDate: formatCpDate('2019-11-08', ISO_DATE_FORMAT).toCpDate(),
    },
];

const getLeasingContract = (contract: Contract, isActive: boolean): LeasingContract => {
    if (!contract) {
        return contract;
    }
    if (isActive) {
        return {
            ...contract,
            lastDueDate: formatCpDate('2020-09-21', ISO_DATE_FORMAT).toCpDate(),
        } as LeasingContract;
    } else {
        return {
            ...contract,
            licensePlateNumber: undefined,
            contractStatus: ContractStatus.EXPIRED,
            lastDueDate: formatCpDate('2020-05-21', ISO_DATE_FORMAT).toCpDate(),
        } as LeasingContract;
    }
};

export const ActiveLeasingContractWithAllValues: LeasingContract = getLeasingContract(
    buildContract(ContractsWithMultipleMixedEntries[0]),
    true,
);

export const ExpiredLeasingContractWithAllValues: LeasingContract = getLeasingContract(
    buildContract(ContractsWithInactiveContract[2]),
    true,
);

export const LeasingContractWithEarlyClosure: LeasingContract = getLeasingContract(
    buildContract(EarlyClosedLeasingContract),
    false,
);

export const ExpiredLeasingContractWithNoLicensePLate: LeasingContract = getLeasingContract(
    buildContract(ContractsWithMultipleMixedEntries[0]),
    false,
);

const getConsumerCreditContract = (contract: Contract, isActive: boolean): ConsumerCreditContract => {
    if (!contract) {
        return contract;
    }
    if (isActive) {
        return {
            ...contract,
            lastInstallmentDate: formatCpDate('2021-10-08', ISO_DATE_FORMAT).toCpDate(),
        } as ConsumerCreditContract;
    } else {
        return {
            ...contract,
            licensePlateNumber: undefined,
            contractStatus: ContractStatus.EXPIRED,
            lastInstallmentDate: formatCpDate('2020-02-28', ISO_DATE_FORMAT).toCpDate(),
        } as ConsumerCreditContract;
    }
};

export const ActiveConsumerCreditContractWithAllValues: ConsumerCreditContract = getConsumerCreditContract(
    buildContract(ContractsWithMultipleMixedEntries[1]),
    true,
);

export const ConsumerCreditContractWithEarlyClosure: ConsumerCreditContract = getConsumerCreditContract(
    buildContract(EarlyClosedConsumerCreditContract),
    false,
);

export const ExpiredConsumerCreditContractWithNoLicensePLate: ConsumerCreditContract = getConsumerCreditContract(
    buildContract(ContractsWithMultipleMixedEntries[1]),
    false,
);

export const ContractsListEmpty: Contract[] = [];

export const ContractsWithAMissingVin: Contract[] = [
    {
        ...ContractA,
        vin: undefined,
        encryptedVin: undefined,
    },
];
