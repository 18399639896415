import { FetchContractError, VehicleDetails } from '../../common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import {
    AbstractDataState,
    hasFetchedSuccessfully,
    useGetContractBasedApiData,
} from '@cp-shared-8/frontend-integration';
import { useContract } from 'components/contracts/useContract';
import { useMemo } from 'react';
import { VehicleDetailsDataSelector } from './VehicleDetailsDataSelector';
import { fetchVehicleDetails } from './VehicleDetailsSlice';

export function useVehicleDetails(
    contractId: string,
): AbstractDataState<VehicleDetails, DefaultBusinessMarketApiErrorCode | FetchContractError> {
    const contractState = useContract(contractId);
    const vehicleDetailsState = useGetContractBasedApiData(
        contractId,
        fetchVehicleDetails,
        VehicleDetailsDataSelector,
        contractState.data?._links?.vehicleDetails,
    );
    return useMemo(
        () => ({
            isLoading: contractState.isLoading || vehicleDetailsState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && vehicleDetailsState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? vehicleDetailsState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: vehicleDetailsState.data,
            loadingError: hasFetchedSuccessfully(contractState)
                ? vehicleDetailsState.loadingError
                : contractState.loadingError,
        }),
        [contractState, vehicleDetailsState],
    );
}
