import { getTaxesTableHeaderEndpoint, getTaxesTablePdfDownloadEndpoint } from '../../common';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { ContractsMock } from 'components/contracts';
import { ContractsWithMultipleMixedEntries as Contracts } from 'components/contracts/ExampleData';
import { CpDataApi } from 'cp-xhr';
import React from 'react';

import { base64Example, TaxesTableHeaderWithAllValues } from './ExampleData';

export const contract = Contracts[0];

const taxesTabledContentPdfDownloadMockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: base64Example,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const TaxesTablePdfDownloadMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Taxes Table Pdf Download Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: taxesTabledContentPdfDownloadMockOptions,
    url: getTaxesTablePdfDownloadEndpoint(Contracts[0].contractId),
};

const taxesTableHeaderMockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: TaxesTableHeaderWithAllValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const TaxesTableHeaderMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Taxes Table Header Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: taxesTableHeaderMockOptions,
    url: getTaxesTableHeaderEndpoint(Contracts[0].contractId),
};

export const TaxesTableResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [ContractsMock, TaxesTableHeaderMock, TaxesTablePdfDownloadMock]);

    return <div>{storyFn()}</div>;
};
