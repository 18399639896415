import { DashboardMarketingCards, getDashboardMarketingCardEndpoint } from '../../common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<DashboardMarketingCards>({
    contentName: 'dashboardMarketingCard',
    contentEndpoint: getDashboardMarketingCardEndpoint,
});

export default reducer;
export const fetchDashboardMarketingCard = fetchContent;
