import { getTaxesTablePdfDownloadEndpoint } from '../../../../common';
import { Notification, NotificationStatus, Spinner, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import base64ToBlob from 'b64-to-blob';
import { ContentSection, Heading, Layout, Button } from '@vwfs-bronson/bronson-react';
import { CpDataApi } from 'cp-xhr';
import { saveAs as downloadFileAs } from 'file-saver';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type TaxesTableContentUiProps = {
    contractId?: string;
    contractNumber?: string;
};

export const TaxesTableContentUi: React.FC<TaxesTableContentUiProps> = ({ contractId, contractNumber }) => {
    const { t, i18n } = useTranslation('taxes-table');

    const currentLanguage = i18n.languages[0];

    const fileName = t('content.filename', { contractNumber: contractNumber });
    const link = contractId ? getTaxesTablePdfDownloadEndpoint(contractId) : undefined;

    const [hasError, setHasError] = useState(!link);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { onAction: onMotorTaxPdfDownload } = useAnalyticsActionTracker('onMotorTaxDownload');

    const downloadPdf = (): void => {
        if (!link) {
            return;
        }
        setIsSubmitting(true);
        const queryParams = {
            locale: currentLanguage,
        };
        CpDataApi.get(link, { params: queryParams })
            .then(response => {
                const fileContent = response.data;
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(fileContent, pdfContentType);
                downloadFileAs(pdfBlob, fileName);
                onMotorTaxPdfDownload();
                setIsSubmitting(false);
            })
            .catch(() => {
                setIsSubmitting(false);
                setHasError(true);
            });
    };

    const content = !hasError ? (
        <Layout className={'u-mb-small'}>
            <Layout.Item className={'u-text-left'} default="1/1">
                <Heading level={'3'}>{t('content.headline')}</Heading>
            </Layout.Item>
            <Layout.Item default="1/1">
                <Heading level={'5'}>{t('content.sub-headline')}</Heading>
                <div
                    dangerouslySetInnerHTML={{
                        __html: t('content.description'),
                    }}
                />
            </Layout.Item>
            <Layout.Item default="1/1">
                {isSubmitting ? (
                    <Spinner small />
                ) : (
                    <Button onClick={downloadPdf} testId="pdfDownloadLink" element="a" icon="download">
                        {t('content.download-link')}
                    </Button>
                )}
            </Layout.Item>
        </Layout>
    ) : (
        <Layout className={'u-mb-small'}>
            <Layout.Item default="1/1">
                <Notification
                    status={NotificationStatus.warning}
                    headline={t('content.error.title')}
                    text={t('content.error.description')}
                    testId={'pdf-download-error-notification'}
                />
            </Layout.Item>
        </Layout>
    );

    return <ContentSection className={'u-pb-small'}>{content}</ContentSection>;
};
