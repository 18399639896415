import { useAuthentication, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { FaqUi } from './ui/FaqUi';
import { useFaq } from './useFaq';

export const Faq: React.FC = () => {
    const { cmsContent: faq, isLoading, loadingError } = useFaq();

    const { isAuthenticated } = useAuthentication();

    const FaqWithHandlers = withLoadingAndNoConnectionHandler(FaqUi);

    useAnalyticsPageViewTracker('faq', !!faq);

    return <FaqWithHandlers hasError={!!loadingError} isLoading={isLoading} faq={faq} isLoggedIn={isAuthenticated} />;
};
