import { BaseFinancialDetails, ConsumerCreditFinancialDetails } from '../../../../common';
import { FinancialDetailsWithAllValues } from '../ExampleData';

const ConsumerCreditFinancialDetailsWithValues: ConsumerCreditFinancialDetails = {
    netLoanAmount: 18150,
    sharedServices: 0.8,
    totalDueAmount: 2320.45,
    totalToBeRefunded: 23239.2,
    vehicleValue: 523239.2,
    communicationCosts: 1,
};

const getConsumerCreditFinancialDetails = (
    details: BaseFinancialDetails,
    areValuesMissing = true,
): ConsumerCreditFinancialDetails => {
    if (!details) {
        return details;
    }
    if (areValuesMissing) {
        return {
            ...details,
        } as ConsumerCreditFinancialDetails;
    } else {
        return {
            ...details,
            ...ConsumerCreditFinancialDetailsWithValues,
        } as ConsumerCreditFinancialDetails;
    }
};

export const ConsumerCreditFinancialDetailsWithAllValues: ConsumerCreditFinancialDetails = getConsumerCreditFinancialDetails(
    FinancialDetailsWithAllValues,
    false,
);

export const ConsumerCreditFinancialDetailsWithMissingValues: ConsumerCreditFinancialDetails = getConsumerCreditFinancialDetails(
    FinancialDetailsWithAllValues,
);
