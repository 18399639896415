import { LeasingContractDetails } from '../../../../../common';
import {
    DataOverview,
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-8/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React from 'react';

export const RemainderSection: React.FC<{ details: LeasingContractDetails }> = ({ details }) => {
    const { nominalInterestRate, installmentIndexValue, effectiveInterestRate } = details;
    const { t, f } = useTranslationWithFormatting('general-contract-details');

    const mandatoryFieldMissing = !nominalInterestRate || (!effectiveInterestRate && effectiveInterestRate !== 0);

    const sectionFields: DefinitionListItem[] = [
        {
            label: t('remainder-section.nominal-interest-rate.label'),
            value: f(nominalInterestRate, TranslationFormat.PERCENTAGE_RATE),
            tooltip: t('remainder-section.nominal-interest-rate.tooltip'),
        },
        {
            label: t('remainder-section.installment-index-value.label'),
            value: installmentIndexValue
                ? t('remainder-section.installment-index-value.value.yes')
                : t('remainder-section.installment-index-value.value.no'),
        },
        {
            label: t('remainder-section.effective-interest-rate.label'),
            value: f(effectiveInterestRate, TranslationFormat.PERCENTAGE_RATE),
            tooltip: t('remainder-section.effective-interest-rate.leasing.tooltip'),
        },
    ].filter(item => !!item.value);

    return (
        <DataOverview withoutCardEffect={true}>
            {mandatoryFieldMissing ? (
                <Notification status={NotificationStatus.error} headline={t('error.headline')} text={t('error.text')} />
            ) : (
                <DefinitionListHorizontal list={sectionFields} />
            )}
        </DataOverview>
    );
};
