export function getIdentificationEndpoint(customerType: string): string {
    return `/customer/${customerType}/identification-data`;
}

export function getAddressEndpoint(customerType: string): string {
    return `/customer/${customerType}/address`;
}

export function getContactDetailsEndpoint(customerType: string): string {
    return `/customer/${customerType}/contact-details`;
}
