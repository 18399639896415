import { Faq } from '../../../common';
import { Faq as FaqShared, HeroImage, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';

export const FaqUi: React.FC<{ faq?: Faq; isLoggedIn: boolean }> = ({ faq, isLoggedIn }) => {
    const { onAction } = useAnalyticsActionTracker('onFaqQuestion');

    if (!faq) return null;

    const { teaser, content } = faq || {};
    const [publicContent, privateContent] = content || [];
    const { header, descriptionText } = publicContent || { header: '' };

    const teaserComponent = teaser ? (
        <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow={true} inverted={!!teaser.isInverted} />
    ) : null;

    let items = publicContent.items;

    if (isLoggedIn) {
        const { items: privateItems } = privateContent || { header: '', items: [] };
        items = [...(privateItems || []), ...(items || [])];
    }

    let openItems = [];

    const handleOnChange = (refs: string[]): void => {
        if (openItems.length > refs.length || !refs.length) {
            openItems = [...refs];
            return;
        }
        openItems = [...refs];
        onAction(refs[refs.length - 1]);
    };
    const contentComponent = (
        <FaqShared onChange={handleOnChange} header={header} descriptionText={descriptionText} items={items} />
    );

    return (
        <>
            {teaserComponent}
            <ContentSection pageWrapSize="medium">{contentComponent}</ContentSection>
        </>
    );
};
