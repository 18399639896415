import { BundledProductDetails } from '../../../../common';
import { DataOverview, DefinitionListHorizontal, Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React from 'react';

import { getServiceNameTranslationMapping, getServiceTypeTranslationMapping } from './BundledProductsHelper';

export const BundledProductsUi: React.FC<{
    bundledProducts?: BundledProductDetails[];
    contractType?: string;
}> = ({ bundledProducts, contractType }) => {
    const { t, f } = useTranslationWithFormatting('bundled-products');

    if (!bundledProducts || !Array.isArray(bundledProducts)) {
        return null;
    }

    if (bundledProducts.length === 0) {
        return <Notification status={NotificationStatus.info} text={t('info.not-found')} />;
    }

    return (
        <>
            <Notification
                status={NotificationStatus.info}
                text={t('info-alert')}
                testId={'bundled-product-info-text'}
            />
            {bundledProducts.map((product, index) => {
                const serviceType = getServiceTypeTranslationMapping(product.serviceType, t);
                const serviceName = getServiceNameTranslationMapping(product.serviceType, product.serviceName, t);
                return (
                    <DataOverview key={index} title={serviceType} withoutCardEffect={true}>
                        <DefinitionListHorizontal
                            list={[
                                {
                                    // NOTE: No label provided by US.
                                    label: '',
                                    value: serviceName,
                                },
                                {
                                    label: t('company-provider-name.label'),
                                    value: product.companyProviderName,
                                },
                                {
                                    label: t('start-date.label'),
                                    value: product.startDate,
                                },
                                {
                                    label: t('end-date.label'),
                                    value: product.endDate,
                                },
                                {
                                    label: t('duration.label'),
                                    value: product.serviceDuration,
                                },
                                {
                                    label: t('total-amount.label'),
                                    value: f(product.totalAmount, TranslationFormat.CURRENCY),
                                    tooltip: t('total-amount.tooltip'),
                                },
                                {
                                    label: t(`installment-amount.label.${contractType}`),
                                    value: f(product.installmentAmount, TranslationFormat.CURRENCY),
                                    tooltip: t('installment-amount.tooltip'),
                                },
                                {
                                    label: t(`number-of-installments.label.${contractType}`),
                                    value: product.numberOfInstallments,
                                },
                            ].filter(item => !!item.value)}
                        />
                    </DataOverview>
                );
            })}
        </>
    );
};
