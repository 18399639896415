import { CPDate } from '@cp-shared-8/common-utilities';
import { Product } from './early-settlement';

export type FetchContractError = 'CUSTOMER_NOT_FOUND' | 'CONTRACT_NOT_FOUND';

export enum ContractType {
    LEASING = 'LE',
    CONSUMER_CREDIT = 'CO',
}

export enum ContractStatus {
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
}
export interface BaseContract {
    /**
     * The concatenated data of brand and model received from CP BE.
     * Brand: Ducati
     * Model: Streetfighter V4S
     * @example "Ducati Streetfighter V4S"
     */
    vehicleInformation: string;
    /**
     * The data displayed to user in FE
     * @example "197333"
     */
    contractNumber: string;
    /**
     * The data used to filter and fetch subsidiaries - contract-details, finance, vehicle, bundled-products, unpaid-details, amortization table.
     * @example "4462618"
     */
    contractId: string;
    contractType: ContractType;
    licensePlateNumber?: string;
    vin?: string;
    encryptedVin?: string;
    contractStatus: ContractStatus;
    nextDueDate?: CPDate;
    nextDueAmount?: number;
    product: Product;
    unpaidAlert: boolean;
    brand: string;
    name: string;
    closeDateOfTheContract?: CPDate;
    contractStartDate?: CPDate;
    successionDate?: CPDate;
}

export interface ConsumerCreditContract extends BaseContract {
    contractType: ContractType.CONSUMER_CREDIT;
    lastInstallmentDate?: CPDate;
}

export interface LeasingContract extends BaseContract {
    contractType: ContractType.LEASING;
    lastDueDate?: CPDate;
}

export interface ContractTypeChecker {
    readonly isLeasing: boolean;
    readonly isConsumerCredit: boolean;
}

export interface ContractStatusChecker {
    readonly isActive: boolean;
    readonly isExpired: boolean;
}

export type ContractLinks = {
    unpaidDetailsPdfDownload?: string;
    contractDetails?: string;
    financialDetails?: string;
    bundledProductDetails?: string;
    vehicleDetails?: string;
    amortizationTableHeader?: string;
    amortizationTablePdfDownload?: string;
    taxesTableHeader?: string;
    amortizationTableBundledProductsDownloadLinks?: string;
};

export type ResourceLinks = {
    _links?: ContractLinks;
};

export type ContractData = ConsumerCreditContract | LeasingContract;
export type ContractDataWithLinks = ContractData & ResourceLinks;
export type Contract = ContractData & ContractTypeChecker & ContractStatusChecker & ResourceLinks;

function contractStatusCheckers(contractStatus: ContractStatus): ContractStatusChecker {
    return {
        isActive: contractStatus === ContractStatus.ACTIVE,
        isExpired: contractStatus === ContractStatus.EXPIRED,
    };
}

function contractTypeCheckers(contractType: ContractType): ContractTypeChecker {
    return {
        isLeasing: contractType === ContractType.LEASING,
        isConsumerCredit: contractType === ContractType.CONSUMER_CREDIT,
    };
}

function extractLinks(contract: ContractDataWithLinks): ResourceLinks {
    return {
        _links: contract._links,
    };
}

export function buildContract(contract: ContractDataWithLinks): Contract {
    return {
        ...contract,
        ...extractLinks(contract),
        ...contractStatusCheckers(contract.contractStatus),
        ...contractTypeCheckers(contract.contractType),
    };
}

export function buildContracts(contracts: ContractDataWithLinks[]): Contract[] {
    return contracts.map(buildContract);
}

export function enrichContract(contract: ContractDataWithLinks): Contract {
    return {
        ...contract,
        ...contractStatusCheckers(contract.contractStatus),
        ...contractTypeCheckers(contract.contractType),
    };
}

export function enrichContracts(contracts: ContractDataWithLinks[]): Contract[] {
    return contracts.map(enrichContract);
}
