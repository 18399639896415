import { Button, ButtonContainer, Callout, Checkbox, Paragraph } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type CustomerHappinessIndexTypes = {
    handleButtonClick: () => void;
    setIsConsentAccepted: React.Dispatch<React.SetStateAction<boolean>>;
    isConsentAccepted: boolean;
};

export const CustomerHappinessIndexUi: React.FC<CustomerHappinessIndexTypes> = ({
    handleButtonClick,
    setIsConsentAccepted,
    isConsentAccepted,
}) => {
    const { t } = useTranslation('customer-happiness-index');

    return (
        <>
            <Callout title={t('title')} className={'u-mb'}>
                <Paragraph className={'u-mb'}>{t('text')}</Paragraph>

                <Checkbox
                    checked={isConsentAccepted}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setIsConsentAccepted(e.target.checked);
                    }}
                >
                    {t('consent-checkbox')}
                </Checkbox>

                <ButtonContainer center className={'u-mt'}>
                    <Button onClick={handleButtonClick} disabled={!isConsentAccepted}>
                        {t('button')}
                    </Button>
                </ButtonContainer>
            </Callout>
            <span
                className={'u-font-size-fs-2'}
                dangerouslySetInnerHTML={{
                    __html: t('legal-notice'),
                }}
            />
        </>
    );
};
