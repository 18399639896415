import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Fieldset, Form } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import { CpDataApi } from 'cp-xhr';
import {
    ValidatedInput,
    preventSubmit,
    Spinner,
    useAnalyticsFormTracker,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import { parseErrorResponse } from '@cp-shared-8/frontend-integration';
import { useUserRegistrationFlow } from 'auth';
import {
    RegistrationError,
    VerifyRegistrationCodeRequest,
    getVerifyRegistrationCodeEndpoint,
} from '../../../../../../common';

import { registrationStep2FormValidationSchema } from '../../validation';
import { useTrackerOnBeforeUnload } from '../../../../../../hooks';
import { isEmpty } from 'lodash';

type Props = {
    customerId?: string;
    onSubmitSuccess?: Function;
    onSubmitError?: Function;
};

export const RegistrationStep2FormUi: React.FC<Props> = ({ customerId, onSubmitSuccess, onSubmitError }) => {
    const { t } = useTranslation('registration');
    const { confirm } = useUserRegistrationFlow();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { onAction: trackOnError } = useAnalyticsActionTracker('onConfirmIdentiyAuthFailed');
    const { onAction: trackOnSuccess } = useAnalyticsActionTracker('onConfirmIdentiyAuthSuccess');
    const { onAction } = useAnalyticsActionTracker('onFormValidationErrorConfirmIdentity');

    const { onTyping } = useAnalyticsFormTracker({
        startTyping: 'onStartTypingConfirmIdentity',
    });
    const setLastTouchedField = useTrackerOnBeforeUnload();
    const getErrors = (errors: { [k: string]: string | undefined }) => Object.keys(errors).join(`, `);
    const initialListErrors = 'code';

    type formProps = {
        code: string;
    };

    const initialValues = {
        code: '',
    };

    const handleSubmit = (values: formProps): void => {
        setIsSubmitting(true);

        const requestBody: VerifyRegistrationCodeRequest = {
            code: values.code,
            encryptedId: customerId || '',
        };
        CpDataApi.post(getVerifyRegistrationCodeEndpoint(), requestBody)
            .then(() => {
                setIsSubmitting(false);
                if (onSubmitSuccess) {
                    confirm({ isAuthorized: true });
                    onSubmitSuccess();
                    trackOnSuccess();
                }
            })
            .catch(error => {
                setIsSubmitting(false);
                if (onSubmitError) {
                    onSubmitError(parseErrorResponse<RegistrationError>(error).code);
                }
                trackOnError();
            });
    };

    return (
        <>
            {isSubmitting && <Spinner fullPage={true} />}
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={registrationStep2FormValidationSchema(t)}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <Form
                        onSubmit={preventSubmit}
                        onChange={() => {
                            onTyping(formik.errors, formik.touched);
                        }}
                    >
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedInput
                                    label={t(`form.input-labels.code`)}
                                    name="code"
                                    testId={`code`}
                                    maxLength={6}
                                    onFocus={() => setLastTouchedField(t(`form.input-labels.code`))}
                                />
                            </Fieldset.Row>
                        </Fieldset>
                        <Fieldset.Row>
                            <Button
                                full={true}
                                testId={`submitButton`}
                                onClick={() => {
                                    formik.submitForm();
                                    if (!isEmpty(formik.errors) || isEmpty(formik.touched)) {
                                        const errorToString = getErrors(formik.errors).toString();
                                        if (!errorToString) {
                                            onAction(initialListErrors);
                                        } else onAction(getErrors(formik.errors));
                                    }
                                }}
                            >
                                {t('form.button-labels.code-submit')}
                            </Button>
                        </Fieldset.Row>
                    </Form>
                )}
            </Formik>
        </>
    );
};
