import React from 'react';

import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { IconFooter as IconFooterUi, useAnalyticsActionTracker, IconFooterItem } from '@cp-shared-8/frontend-ui';
import { useIconFooter } from './useIconFooter';

const IconFooterWithHandlers = withLoadingAndNoConnectionHandler(IconFooterUi);

export const IconFooter: React.FC = () => {
    const { onAction: onCallUs } = useAnalyticsActionTracker('onClickEmailLink');
    const { cmsContent: iconFooter, isLoading, loadingError } = useIconFooter();
    const { items, title } = iconFooter || {};
    const mapItems = (items: IconFooterItem[]): IconFooterItem[] => {
        return items.map((item: IconFooterItem) => {
            return {
                ...item,
                normalLink: !!item.linkUrl,
            };
        });
    };
    return items ? (
        <IconFooterWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            title={title}
            items={mapItems(items)}
            trackingFunction={onCallUs}
        />
    ) : null;
};
