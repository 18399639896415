import { FetchContractError, getContractsEndpoint } from '../../common';
import { DefaultBusinessMarketApiError } from '@cp-shared-8/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { CpDataApi } from 'cp-xhr';
import React from 'react';

import {
    ConsumerCreditBundledProductsMock,
    LeasingBundledProductsMock,
} from './bundled-products/BundledProductsResponseMockWrapper';
import {
    ActiveConsumerCreditContractWithAllValues,
    ActiveLeasingContractWithAllValues,
    ContractsWithMultipleMixedEntries,
    ExpiredConsumerCreditContractWithNoLicensePLate,
    ExpiredLeasingContractWithNoLicensePLate,
} from './ExampleData';
import { ConsumerCreditFinancialDetailsMock } from './financial-details/consumer-credit-financial-details';
import { LeasingFinancialDetailsMock } from './financial-details/leasing-financial-details';

const mockOptions: MockOptions<FetchContractError> = {
    Success: {
        status: 200,
        responseBody: ContractsWithMultipleMixedEntries,
        delay: 500,
    },
    'Active LE Contract With All Values': {
        status: 200,
        responseBody: [ActiveLeasingContractWithAllValues],
    },
    'Expired LE Contract With No License Plate': {
        status: 200,
        responseBody: [ExpiredLeasingContractWithNoLicensePLate],
    },
    'Active CO Contract With All Values': {
        status: 200,
        responseBody: [ActiveConsumerCreditContractWithAllValues],
    },
    'Expired CO Contract With No License Plate': {
        status: 200,
        responseBody: [ExpiredConsumerCreditContractWithNoLicensePLate],
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const ContractsMock: EndpointMockDescription<FetchContractError> = {
    name: 'Contract Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getContractsEndpoint(),
};

export const ContractsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [
        ContractsMock,
        LeasingFinancialDetailsMock,
        ConsumerCreditFinancialDetailsMock,
        LeasingBundledProductsMock,
        ConsumerCreditBundledProductsMock,
    ]);
    return <div>{storyFn()}</div>;
};
