import React from 'react';
import { VehicleImageView } from '@cp-shared-8/apis';
import { useVehicleServiceDetails } from './useVehicleServiceDetails';
import { useVehicleImage } from './useVehicleImage';
import { VehicleDetailsAndImageUi } from './vehicle-details-and-image-ui/VehicleDetailsAndImageUi';

export const VehicleDetailsAndImage: React.FC<{
    vin: string;
    brand: string;
    vehicleInformation: string;
    encryptedVin: string;
    licensePlate?: string;
    defaultView?: VehicleImageView;
}> = ({
    vin,
    brand,
    vehicleInformation,
    encryptedVin,
    licensePlate,
    defaultView = VehicleImageView.EXTERIOR_FRONT_LEFT,
}) => {
    const {
        data: vehicleImages,
        isLoading: isLoadingVehicleImage,
        loadingError: vehicleImageLoadingError,
    } = useVehicleImage(encryptedVin, defaultView);
    const {
        data: vehicleDetails,
        isLoading: isLoadingVehicleDetails,
        loadingError: vehicleDetailsLoadingError,
    } = useVehicleServiceDetails(encryptedVin);

    return (
        <VehicleDetailsAndImageUi
            vin={vin}
            brand={brand}
            vehicleInformation={vehicleInformation}
            licensePlate={licensePlate}
            vehicleDetails={vehicleDetails}
            isLoadingVehicleDetails={isLoadingVehicleDetails}
            vehicleDetailsLoadingError={!!vehicleDetailsLoadingError}
            vehicleImages={vehicleImages}
            isLoadingVehicleImage={isLoadingVehicleImage}
            vehicleImageLoadingError={!!vehicleImageLoadingError}
            defaultView={defaultView}
        />
    );
};
