import { LeasingContract, TaxesTableHeader } from '../../../../common';
import {
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-8/frontend-ui';
import { Card, DataOverview, Layout } from '@vwfs-bronson/bronson-react';
import { ContractHeader } from 'components/contracts/contract-header/ContractHeader';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React from 'react';

export const TaxesTableHeaderUi: React.FC<{
    taxesTableHeader?: TaxesTableHeader;
    contract?: LeasingContract;
}> = ({ taxesTableHeader, contract }) => {
    const { t, f } = useTranslationWithFormatting('taxes-table');

    if (!taxesTableHeader || !contract) {
        return null;
    }

    const taxAmount = taxesTableHeader.motorTaxAmount;
    const mandatoryFieldIsMissing =
        (!taxAmount && taxAmount !== 0) ||
        !taxesTableHeader.motorTaxValidityDateFrom ||
        !taxesTableHeader.motorTaxValidityDateTo;

    const tiles = [
        {
            title: t('header.duration-details.headline'),
            icon: 'calendar',
            data: [
                {
                    label: t('header.duration-details.taxes-due-date'),
                    value: taxesTableHeader.motorTaxValidityDateFrom,
                },
                {
                    label: t('header.duration-details.taxes-end-date'),
                    value: taxesTableHeader.motorTaxValidityDateTo,
                },
            ],
        },
        {
            title: t('header.financial-details.headline'),
            icon: 'coins-circle-eurosign',
            data: [
                {
                    label: t('header.financial-details.tax-amount'),
                    value: f(taxesTableHeader.motorTaxAmount, TranslationFormat.CURRENCY),
                },
            ],
        },
    ];

    return mandatoryFieldIsMissing ? (
        <Notification
            status={NotificationStatus.error}
            headline={t('header.error.headline')}
            text={t('header.error.text')}
        />
    ) : (
        <Layout>
            <Layout.Item default="1/1">
                <Card element="article">
                    <ContractHeader contract={contract} />
                </Card>
            </Layout.Item>
            <Layout.Item default="1/1">
                <Layout className={'o-layout--equal-height'}>
                    {tiles.map((tile, index) => (
                        <Layout.Item key={index} default="1/2" m="1/1">
                            <DataOverview title={tile.title} icon={tile.icon}>
                                <DefinitionListHorizontal list={tile.data as DefinitionListItem[]} />
                            </DataOverview>
                        </Layout.Item>
                    ))}
                </Layout>
            </Layout.Item>
        </Layout>
    );
};
