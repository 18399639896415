import React from 'react';

import { AmortizationTableHeader } from '../../../common';
import { Card, DataOverview, Layout } from '@vwfs-bronson/bronson-react';
import { ContractHeader, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { LicensePlate } from '../../license-plate';
import { TranslationFormat, useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';

export const AmortizationTableHeaderUi: React.FC<{ amortizationTableHeader?: AmortizationTableHeader }> = ({
    amortizationTableHeader,
}) => {
    const { t, f } = useTranslationWithFormatting('amortization-table');

    if (!amortizationTableHeader) {
        return null;
    }

    const productType = amortizationTableHeader.product === 'Leasing' ? 'le' : 'co';
    const contractCategory = productType.toUpperCase();

    const tiles = [
        {
            title: t('header.duration-details.label'),
            icon: 'calendar',
            data: [
                {
                    label: t('header.duration-details.start-date.label'),
                    value: amortizationTableHeader.contractStartDate ? amortizationTableHeader.contractStartDate : '-',
                },
                {
                    label: t('header.duration-details.end-date.label'),
                    tooltip: t(`header.duration-details.end-date.tooltip.${productType}`),
                    value: amortizationTableHeader.lastInstallmentDate
                        ? amortizationTableHeader.lastInstallmentDate
                        : '-',
                },
                {
                    label: t(`header.duration-details.number-of-installments.label.${productType}`),
                    value: amortizationTableHeader.numberOfInstallments
                        ? f(amortizationTableHeader.numberOfInstallments, TranslationFormat.NUMBER)
                        : '-',
                },
            ],
        },
        {
            title: t('header.financial-details.label'),
            icon: 'coins-circle-eurosign',
            data: [
                {
                    label: t(`header.financial-details.capital-amount.${productType}.label`),
                    tooltip: t(`header.financial-details.capital-amount.${productType}.tooltip`),
                    value: amortizationTableHeader.capitalAmount
                        ? f(amortizationTableHeader.capitalAmount, TranslationFormat.CURRENCY)
                        : '-',
                },
                {
                    label: t(`header.financial-details.interest.label`),
                    tooltip: t(`header.financial-details.interest.tooltip`),
                    value: amortizationTableHeader.effectiveInterestAmount
                        ? f(amortizationTableHeader.effectiveInterestAmount, TranslationFormat.CURRENCY)
                        : '-',
                },
                {
                    label: t(`header.financial-details.principle-interest.label`),
                    tooltip: t(`header.financial-details.principle-interest.${productType}.tooltip`),
                    value: amortizationTableHeader.grossLoanAmount
                        ? f(amortizationTableHeader.grossLoanAmount, TranslationFormat.CURRENCY)
                        : '-',
                },
            ],
        },
        {
            title: t('header.fees-details.label'),
            icon: 'hand-circle-eurosign',
            data: [
                {
                    label: t('header.fees-details.stamp-duty.label'),
                    tooltip: t(`header.fees-details.stamp-duty.${productType}.tooltip`),
                    value: amortizationTableHeader.stampDuty
                        ? f(amortizationTableHeader.stampDuty, TranslationFormat.CURRENCY)
                        : '-',
                },
                {
                    label: t('header.fees-details.preliminary-cost.label'),
                    tooltip: t('header.fees-details.preliminary-cost.tooltip'),
                    value: amortizationTableHeader.preliminaryCost
                        ? f(amortizationTableHeader.preliminaryCost, TranslationFormat.CURRENCY)
                        : '-',
                },
                {
                    label: t('header.fees-details.collection-cost.label'),
                    tooltip: t(`header.fees-details.collection-cost.${productType}.tooltip`),
                    value: amortizationTableHeader.collectionCosts
                        ? f(amortizationTableHeader.collectionCosts, TranslationFormat.CURRENCY)
                        : '-',
                },
            ],
        },
    ];

    const carModel = amortizationTableHeader.name
        ? `${amortizationTableHeader.brand} ${amortizationTableHeader.name}`
        : amortizationTableHeader.brand;
    const licensePlate = amortizationTableHeader.plateNumber ? (
        <LicensePlate licensePlateNumber={amortizationTableHeader.plateNumber} />
    ) : null;

    return (
        <Layout>
            <Layout.Item default="1/1">
                <Card element="article">
                    <ContractHeader
                        carInformation={carModel}
                        contractCategory={contractCategory}
                        contractNumber={amortizationTableHeader.contractNumber.toString()}
                        contractIdentifier={licensePlate}
                    />
                </Card>
            </Layout.Item>
            <Layout.Item default="1/1">
                <Layout className={'o-layout--equal-height'}>
                    {tiles.map((tile, index) => (
                        <Layout.Item key={index} default="1/3" m="1/1">
                            <DataOverview title={tile.title} icon={tile.icon}>
                                <DefinitionListHorizontal list={tile.data as DefinitionListItem[]} />
                            </DataOverview>
                        </Layout.Item>
                    ))}
                </Layout>
            </Layout.Item>
        </Layout>
    );
};
