import { ConsumerCreditContractDetails } from '../../../../../common';
import {
    DataOverview,
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-8/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React from 'react';

export const RemainderSection: React.FC<{ details: ConsumerCreditContractDetails }> = ({ details }) => {
    const { nominalInterestRate, effectiveInterestRate, mortagage } = details;
    const { t, f } = useTranslationWithFormatting('general-contract-details');

    const mandatoryFieldMissing = !nominalInterestRate || (!effectiveInterestRate && effectiveInterestRate !== 0);

    const sectionFields: DefinitionListItem[] = [
        {
            label: t('remainder-section.nominal-interest-rate.label'),
            value: f(nominalInterestRate, TranslationFormat.PERCENTAGE_RATE),
            tooltip: t('remainder-section.nominal-interest-rate.tooltip'),
        },
        {
            label: t('remainder-section.effective-interest-rate.label'),
            value: f(effectiveInterestRate, TranslationFormat.PERCENTAGE_RATE),
            tooltip: t('remainder-section.effective-interest-rate.consumer-credit.tooltip'),
        },
        {
            label: t('remainder-section.mortagage.label'),
            value: mortagage ? t('remainder-section.mortagage.value') : undefined,
            tooltip: t('remainder-section.mortagage.tooltip'),
        },
    ].filter(item => !!item.value);

    return (
        <DataOverview withoutCardEffect={true}>
            {mandatoryFieldMissing ? (
                <Notification status={NotificationStatus.error} headline={t('error.headline')} text={t('error.text')} />
            ) : (
                <DefinitionListHorizontal list={sectionFields} />
            )}
        </DataOverview>
    );
};
