import { BaseFinancialDetails } from '../../../common';

export const FinancialDetailsWithAllValues: BaseFinancialDetails = {
    capitalAmount: 13127.3,
    collectionCosts: 188,
    communicationCosts: 1,
    downPaymentAmount: 1458.59,
    effectiveInterestAmount: 484.24,
    grossLoanAmount: 15070.2,
    preliminaryCost: 300,
    purchasePriceAmount: 14585.9,
    services: 1426.82,
    stampDuty: 16,
};
