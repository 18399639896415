import React from 'react';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { useIdentification } from 'components/my-profile/identification-section';
import { RegistrationStep1FormUi } from './ui/step1/RegistrationStep1FormUi';
import { RegistrationFormProps } from './RegistrationForm';

export const RegistrationFormProspect: React.FC<RegistrationFormProps> = props => {
    const { data: identificationData, isLoading: isLoadingIdentification } = useIdentification(
        'Private',
        props.isProspect,
    );

    return (
        <>
            {isLoadingIdentification && <Spinner fullPage={true} />}
            <RegistrationStep1FormUi {...props} identificationData={identificationData} />
        </>
    );
};
