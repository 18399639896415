import React from 'react';

import { LandingPageTeaser } from 'components/landing-page-teaser/LandingPageTeaser';
import { LandingPageMarketingCards } from 'components/landing-page-marketing-cards/LandingPageMarketingCards';
import { LandingPageVideo } from 'components/landing-page-video';

export const LandingPage: React.FC = () => {
    return (
        <div>
            <LandingPageTeaser />
            <LandingPageVideo />
            <LandingPageMarketingCards />
        </div>
    );
};
