import React, { useMemo, useCallback, useState } from 'react';
import {
    ConsumerCreditContract as ConsumerCreditContractType,
    Contract,
    LeasingContract as LeasingContractType,
} from '../../../common';
import { ExpandableContent, FormSectionGroup } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { ConsumerCreditContract } from '../consumer-credit-contract/contract';
import { LeasingContract } from '../leasing-contract/contract';
import { groupContractsByVin } from './utils';
import { VehicleDetailsAndImage } from '../vehicle-details-and-image/VehicleDetailsAndImage';
import { ErrorNotification, ErrorNotificationProps } from '../../notifications/error/ErrorNotification';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';

const InactiveContractsTracking: React.FC = () => {
    useAnalyticsPageViewTracker('dashboardWithInactiveContracts');
    return null;
};

export const ContractsUi: React.FC<{
    contracts?: Contract[];
}> = ({ contracts }) => {
    const { t } = useTranslation('contracts');
    const [inactiveOpen, setInactiveOpen] = useState(false);
    const onlyValidContractsList = useMemo(
        () =>
            (contracts || []).filter(
                ({ brand, contractNumber, contractType, isActive }) =>
                    (isActive && brand && contractNumber && contractType) ||
                    (!isActive && contractNumber && contractType),
            ),
        [contracts],
    );

    const areAllContractsValid = useMemo(() => contracts?.length === onlyValidContractsList.length, [
        contracts,
        onlyValidContractsList.length,
    ]);

    const getListOfContracts = useCallback(
        (isActive: boolean) => {
            const filteredContracts = onlyValidContractsList.filter(contract => contract.isActive === isActive);

            const groupedContractsByVin = groupContractsByVin(filteredContracts);

            return groupedContractsByVin.length ? (
                <FormSectionGroup>
                    {groupedContractsByVin.map(contracts =>
                        contracts.map((contract, contractIndex) => (
                            <React.Fragment key={contract.contractNumber}>
                                {contractIndex === 0 &&
                                    (!contract.vin || !contract.encryptedVin ? (
                                        <ErrorNotification testId={'missing-vin-error'} className={'u-mb-xsmall'} />
                                    ) : (
                                        <VehicleDetailsAndImage
                                            vin={contract.vin}
                                            encryptedVin={contract.encryptedVin}
                                            vehicleInformation={contract.vehicleInformation}
                                            brand={contract.brand}
                                            licensePlate={contract.licensePlateNumber}
                                            key={`${contract.contractNumber}-details`}
                                        />
                                    ))}
                                {contract.isLeasing ? (
                                    <LeasingContract
                                        key={contract.contractNumber}
                                        contract={contract as LeasingContractType}
                                        links={contract._links}
                                        defaultExpanded={contractIndex === 0}
                                        isActive={isActive}
                                    />
                                ) : (
                                    <ConsumerCreditContract
                                        key={contract.contractId}
                                        contract={contract as ConsumerCreditContractType}
                                        links={contract._links}
                                        defaultExpanded={contractIndex === 0}
                                    />
                                )}
                            </React.Fragment>
                        )),
                    )}
                </FormSectionGroup>
            ) : null;
        },
        [onlyValidContractsList],
    );

    const activeContracts = useMemo(() => getListOfContracts(true), [getListOfContracts]);
    const expiredContracts = useMemo(() => getListOfContracts(false), [getListOfContracts]);

    if (!contracts) {
        return null;
    }

    const getErrorNotificationProps = (): ErrorNotificationProps | undefined => {
        if (!contracts.length) {
            return {
                testId: 'no-contracts-for-account-error',
                text: t('no-contracts-for-account-error'),
            };
        }
        if (!areAllContractsValid) {
            return { testId: 'missing-contracts-fields-error', className: 'u-mb' };
        }
        return { shown: false };
    };

    return (
        <>
            <ErrorNotification {...getErrorNotificationProps()} />
            {activeContracts}
            {!!expiredContracts && (
                <div onClick={() => !inactiveOpen && setInactiveOpen(true)}>
                    <ExpandableContent
                        triggerLabel={t('show-inactive-contracts')}
                        noBackground
                        pageWrapClassName={'u-pl-none u-pr-none'}
                    >
                        <h4 className="u-text-center c-expandable-content__trigger-text">{t('inactive-contracts')}</h4>
                        {expiredContracts}
                        {inactiveOpen && <InactiveContractsTracking />}
                    </ExpandableContent>
                </div>
            )}
        </>
    );
};
