import { EarlySettlement, formatAsCurrency, formatAsDate, Product } from '../../../common';
import {
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
    withAutoScroll,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import { Button, ButtonContainer, Card, Heading, Layout, Modal } from '@vwfs-bronson/bronson-react';

import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadLink } from './download-link/DownloadLink';

type OfflinePaymentProps = {
    earlySettlementData?: EarlySettlement;
    handleBackButtonClick: () => void;
    handleBackToDashboardButtonClick: () => void;
};

const InactiveContractsTracking: React.FC = () => {
    useAnalyticsPageViewTracker('earlySettlementSummaryPage');
    return null;
};

export const OfflinePayment: React.FC<OfflinePaymentProps> = withAutoScroll(
    ({ earlySettlementData, handleBackButtonClick, handleBackToDashboardButtonClick }) => {
        const { t } = useTranslation('total-early-settlement');
        const [downloadError, setDownloadError] = useState<boolean | null>(null);

        const prefix = 'offline-payment';
        if (!earlySettlementData?.staticIban && !earlySettlementData?.ibanDisp) {
            return <NoConnectionNotification />;
        }

        const {
            ibanDisp,
            staticIban,
            product,
            contractNumber,
            totalSettlementAmount,
            settlementExpirationDate,
        } = earlySettlementData;
        const getProductType = (product: Product): string => (product === 'CO' ? 'co-products' : 'le-products');

        const definitionListBankItems: DefinitionListItem[] = [
            {
                label: t(`${prefix}.bank`),
                value: t(`${prefix}.bank-value`),
            },
            {
                label: t(`${prefix}.bank-iban`),
                value: product === 'CO' ? ibanDisp : staticIban,
                tooltip: t(`${prefix}.${getProductType(product)}.bank-iban-tooltip`),
            },
            {
                label: t(`${prefix}.contract-type`),
                value: `${t(`${prefix}.contract-static-text`)} ${product} - ${contractNumber}`,
                tooltip: t(`${prefix}.contract-type-tooltip`),
            },
        ];

        const definitionListPaymentItems: DefinitionListItem[] = [
            {
                label: t(`${prefix}.total-settlement`),
                value: formatAsCurrency(totalSettlementAmount),
                tooltip: t(`${prefix}.${getProductType(product)}.total-settlement-tooltip`),
            },
            {
                label: t(`${prefix}.settlement-expiration-date`),
                value: formatAsDate(settlementExpirationDate),
                tooltip: t(`${prefix}.${getProductType(product)}.settlement-expiration-date-tooltip`),
            },
        ];

        return (
            <>
                <InactiveContractsTracking />
                <Card className={'u-text-left'} element="article">
                    <Heading level={3}>{t('offline-payment.headline')}</Heading>
                    <p className={'u-pb'}>{t('offline-payment.text')}</p>
                    <Card className={'u-text-left'} element="article">
                        <Heading level={3}>{t('offline-payment.bank-headline')}</Heading>
                        <Layout className={'o-layout--divider'}>
                            <Layout.Item default="1/2" l="1/1">
                                <DefinitionListHorizontal list={definitionListBankItems} />
                            </Layout.Item>
                            <Layout.Item default="1/2" l="1/1">
                                <DefinitionListHorizontal list={definitionListPaymentItems} />
                            </Layout.Item>
                        </Layout>
                    </Card>
                    <Notification
                        className={'u-mt-large'}
                        status={NotificationStatus.warning}
                        headline={t(`${prefix}.information-box.title`, {
                            SettlementExpirationDate: formatAsDate(settlementExpirationDate),
                        })}
                        text={t(`${prefix}.information-box.text`)}
                    />
                    <p className={'u-pt'}>{t(`${prefix}.${getProductType(product)}.info-text`)}</p>
                    <ButtonContainer center>
                        <Button secondary onClick={handleBackButtonClick} testId="dashboardButton" type="button">
                            {t(`${prefix}.button.back`)}
                        </Button>
                        <Button onClick={handleBackToDashboardButtonClick} testId="offlinePaymentButton" type="button">
                            {t(`${prefix}.button.dashboard`)}
                        </Button>
                    </ButtonContainer>
                    <Layout className={'u-mt-small'}>
                        <Layout.Item className={'u-text-center'} default="1/1">
                            <DownloadLink setDownloadError={setDownloadError} />
                        </Layout.Item>
                    </Layout>
                </Card>
                <Modal
                    shown={!!downloadError}
                    status="info"
                    onClose={(): void => setDownloadError(null)}
                    title={t(`${prefix}.error-modal.headline`)}
                >
                    {t(`${prefix}.error-modal.text`)}
                </Modal>
            </>
        );
    },
    'OfflinePayment',
);
