import React, { useCallback } from 'react';
import { HeroImage } from '@cp-shared-8/frontend-ui';
import { useLogin } from '../../../auth/useLogin';
import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';
import { useGenericErrorPage } from './useGenericErrorPage';

const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

export const GenericErrorPage: React.FC<{ errorType: string | undefined }> = ({ errorType }) => {
    const { cmsContent: errorsContent, isLoading, loadingError } = useGenericErrorPage();

    const login = useLogin();
    const onClick = useCallback(() => {
        login();
    }, [login]);

    const errorContent = errorType === 'CONSENT_DENIED_ERR' ? errorsContent?.consentDenied : errorsContent?.general;

    return (
        <HeroImageWithHandlers
            className={'u-pb-large'}
            isLoading={isLoading}
            hasError={!!loadingError}
            title={errorContent?.title || ''}
            subTitle={errorContent?.subTitle}
            buttonText={errorContent?.buttonText}
            clickHandler={onClick}
        />
    );
};
