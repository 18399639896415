import { Contract, parseDate, VehicleDetails } from '../../../common';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { areContractDetailsLoading } from './utils';

export const useShouldShowExtendedWarranty = (contracts: Contract[] = []): boolean => {
    const vehicleDetails = useSelector((state: RootState) => state.vehicleDetails);
    const isLoadingVehicleDetails = areContractDetailsLoading<VehicleDetails>(vehicleDetails);

    if (!vehicleDetails || isEmpty(vehicleDetails) || isLoadingVehicleDetails) return false;

    const oneAndHalfYearAgo = formatCpDate()
        .subtract(1.5, 'years')
        .toMoment();
    const twoYearsAgo = formatCpDate()
        .subtract(2, 'years')
        .toMoment();

    const extendedWarrantyContract = contracts.find(contract => {
        const date = vehicleDetails[contract.contractId]?.data?.carImmatriculationDate;
        return (
            contract.isActive &&
            !!date &&
            formatCpDate(parseDate(date))
                .toMoment()
                .isBetween(twoYearsAgo, oneAndHalfYearAgo, 'day', '[]')
        );
    });

    return !!extendedWarrantyContract;
};
