import { Contract, FetchContractError, getBundledProductDetailsEndpoint, getContractsEndpoint } from '../../../common';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { Accordion } from '@cp-shared-8/frontend-ui';
import { CpDataApi } from 'cp-xhr';
import React from 'react';

import { ActiveConsumerCreditContractWithAllValues, ActiveLeasingContractWithAllValues } from '../ExampleData';
import { BundledProductsWithMultipleEntriesAndAllValues } from './ExampleData';

const contractMockOptions: MockOptions<FetchContractError> = {
    Success: {
        status: 200,
        responseBody: [ActiveLeasingContractWithAllValues, ActiveConsumerCreditContractWithAllValues],
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

const bundledProductsMockOptions: MockOptions<FetchContractError> = {
    Success: {
        status: 200,
        responseBody: BundledProductsWithMultipleEntriesAndAllValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const ContractsMock: EndpointMockDescription<FetchContractError> = {
    name: 'Contract response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: contractMockOptions,
    url: getContractsEndpoint(),
};

export const LeasingBundledProductsMock: EndpointMockDescription<FetchContractError> = {
    name: 'Bundled Products leasing response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: bundledProductsMockOptions,
    url: getBundledProductDetailsEndpoint(ActiveLeasingContractWithAllValues.contractId),
};

export const ConsumerCreditBundledProductsMock: EndpointMockDescription<FetchContractError> = {
    name: 'Bundled Products consumer credit response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: bundledProductsMockOptions,
    url: getBundledProductDetailsEndpoint(ActiveConsumerCreditContractWithAllValues.contractId),
};

export const BundledProductsResponseMockFactory: (
    endpoint: string,
) => EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = endpoint => ({
    name: 'Contract details response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: bundledProductsMockOptions,
    url: endpoint,
});

export const BundledProductsResponseMockWrapper = (storyFn: () => React.ReactElement) => {
    const mockResponses = [ContractsMock];

    (contractMockOptions['Success'].responseBody as Contract[]).forEach(contract => {
        if (contract._links?.bundledProductDetails) {
            mockResponses.push(BundledProductsResponseMockFactory(contract._links?.bundledProductDetails));
        }
    });

    setupMockResponses(CpDataApi, mockResponses);

    return <Accordion>{storyFn()}</Accordion>;
};
