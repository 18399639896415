import React from 'react';
import { useLegalNotice } from 'components/legal-notice';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { RegistrationUi } from './ui';
import { useProspectData } from 'auth';

const RegistrationWithHandlers = withLoadingAndNoConnectionHandler(RegistrationUi);

export const Registration: React.FC = () => {
    const { isAuthorized: isProspect } = useProspectData();

    const {
        cmsContent: legalNotice,
        isLoading: isLoadingLegalNotice,
        loadingError: loadingErrorLegalNotice,
    } = useLegalNotice();

    useAnalyticsPageViewTracker('confirmIdentity', !!legalNotice);

    return (
        <RegistrationWithHandlers
            isLoading={isLoadingLegalNotice}
            legalNotice={legalNotice}
            isProspect={isProspect}
            hasError={!!loadingErrorLegalNotice}
        />
    );
};
