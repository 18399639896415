import { formatAsIban, GeneralContractDetails } from '../../../../common';
import {
    DataOverview,
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditStatus } from '../EditStatus';
import { EditView } from './edit-view/EditView';

export enum ViewState {
    NON_EDIT_VIEW = 'NON_EDIT_VIEW',
    EDIT_VIEW = 'EDIT_VIEW',
}

export const BankAccountSection: React.FC<{
    contractId: string;
    contractType: string;
    details: GeneralContractDetails;
}> = ({ contractId, contractType, details }) => {
    const { iban } = details;
    const { t } = useTranslation('general-contract-details');

    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const [pageState, setPageState] = useState<ViewState>(ViewState.NON_EDIT_VIEW);
    const { onAction } = useAnalyticsActionTracker('financialDetailsEditIbanSection');

    const mandatoryFieldMissing = !iban;

    const sectionFields: DefinitionListItem[] = [
        {
            label: t('bank-account-section.iban'),
            value: formatAsIban(iban),
        },
    ].filter(item => !!item.value);

    const startEditing = (): void => {
        onAction();
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        setPageState(ViewState.EDIT_VIEW);
    };

    const backToNonEditView = (editStatus?: EditStatus): void => {
        if (editStatus) setLastEditStatus(editStatus);
        setPageState(ViewState.NON_EDIT_VIEW);
    };

    return (
        <>
            {pageState === ViewState.NON_EDIT_VIEW && (
                <>
                    <DataOverview
                        title={t('bank-account-section.title')}
                        buttonLabel={['CO', 'LE'].includes(contractType) ? ' ' : ''}
                        buttonProps={{ onClick: startEditing, testId: 'editButton' }}
                        withoutCardEffect={true}
                    >
                        {mandatoryFieldMissing ? (
                            <Notification
                                status={NotificationStatus.error}
                                headline={t('error.headline')}
                                text={t('error.text')}
                            />
                        ) : (
                            <>
                                <Notification
                                    status={NotificationStatus.info}
                                    text={t('bank-account-section.info-message')}
                                />
                                <br />
                                <DefinitionListHorizontal list={sectionFields} />
                            </>
                        )}
                    </DataOverview>
                    {lastEditStatus === EditStatus.SUCCESS && (
                        <Notification status={NotificationStatus.success}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('bank-account-section.edit-view.success-message'),
                                }}
                            />
                        </Notification>
                    )}
                </>
            )}
            {pageState === ViewState.EDIT_VIEW && (
                <EditView
                    previousIban={details.iban}
                    contractId={contractId}
                    contractType={contractType}
                    backButton={backToNonEditView}
                ></EditView>
            )}
        </>
    );
};
