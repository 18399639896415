import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';

import { ForbiddenErrorPage } from '../components/error-pages/forbidden/ForbiddenErrorPage';

export const ForbiddenPage: React.FC = () => (
    <ContentSection pageWrap pageWrapSize="medium">
        <ForbiddenErrorPage />
    </ContentSection>
);
