import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';

import { withLoadingHandler } from 'components/integration-wrapper';
import { BundledProductsDownloadLinksUi } from './ui';
import { useBundledProductsDownloadLinks } from './useBundledProductsDownloadLinks';

const BundledProductsDownloadLinksWithHandlers = withLoadingHandler(BundledProductsDownloadLinksUi);

type BundledProductsDownloadLinksProps = {
    contractId: string;
};

export const BundledProductsDownloadLinks: React.FC<BundledProductsDownloadLinksProps> = ({ contractId }) => {
    const { data: bundledProductsDownloadLinks, isLoading, loadingError } = useBundledProductsDownloadLinks(contractId);
    const { t } = useTranslation('amortization-table');
    const isBundledProductsLinksEmpty = !loadingError && bundledProductsDownloadLinks?.length === 0;
    return (
        <>
            {loadingError && (
                <Notification
                    status={NotificationStatus.error}
                    headline={t('error.bundled-products.title')}
                    text={t('error.bundled-products.text')}
                    testId={'errorNotification'}
                />
            )}
            {!isBundledProductsLinksEmpty && (
                <BundledProductsDownloadLinksWithHandlers
                    isLoading={isLoading}
                    bundledProductsDownloadLinks={bundledProductsDownloadLinks}
                />
            )}
        </>
    );
};
