import { enrichContracts } from '../../common';
import { transformOnlyDefinedValues, useGetSimpleApiDataWithTransformer } from '@cp-shared-8/frontend-integration';
import { ContractsDataSelector } from './ContractsDataSelector';
import { fetchContracts } from './ContractsSlice';

/**
 * Fetches all the contracts
 */
export function useContracts() {
    return useGetSimpleApiDataWithTransformer(
        fetchContracts,
        ContractsDataSelector,
        transformOnlyDefinedValues(enrichContracts),
    );
}
