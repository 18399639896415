import {
    ContactDetails,
    CustomerType,
    getContactDetailsEndpoint,
    getProspectProfileContactEndpoint,
} from '../../../common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { ContactSectionDataSelector } from './ContactSectionDataSelector';
import { fetchContactDetails } from './ContactSectionSlice';

export function useContactDetails(
    customerType: CustomerType,
    isProspect: boolean,
): AbstractDataState<ContactDetails, DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(fetchContactDetails, ContactSectionDataSelector, false, {
        url: isProspect ? getProspectProfileContactEndpoint() : getContactDetailsEndpoint(customerType),
    });
}
