import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { RequestDocumentUi } from './ui';
import { useContract } from '../contracts';
import { useContactDetailsWithGreeting } from '../my-profile/contact-section/useContactDetailsWithGreeting';
import { inferEmail } from '../request';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';

const RequestDocumentWithHandlers = withLoadingAndNoConnectionHandler(RequestDocumentUi);

export const RequestDocument: React.FC<{ contractId: string }> = ({ contractId }) => {
    const { data: contract, isLoading: isLoadingContract, loadingError: loadingErrorContract } = useContract(
        contractId,
    );
    const {
        data: contactDetails,
        isLoading: isLoadingContactDetails,
        loadingError: loadingErrorContactDetails,
    } = useContactDetailsWithGreeting();
    useAnalyticsPageViewTracker('requestCertificate', !!contactDetails);

    const isLoading = isLoadingContract || isLoadingContactDetails;
    const hasError = !!loadingErrorContract || !!loadingErrorContactDetails;

    const email = contactDetails?.items ? inferEmail(contactDetails.items) : '';

    return <RequestDocumentWithHandlers isLoading={isLoading} contract={contract} email={email} hasError={hasError} />;
};
