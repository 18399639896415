import { EarlySettlementPdf, getEarlySettlementPdfEndpoint } from '../../../../common';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { Button } from '@vwfs-bronson/bronson-react';
import base64ToBlob from 'b64-to-blob';
import { CpDataApi } from 'cp-xhr';
import { saveAs as downloadFileAs } from 'file-saver';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export type DownloadLinkProps = {
    setDownloadError: Function;
};
export const DownloadLink: React.FC<DownloadLinkProps> = ({ setDownloadError }) => {
    const { t } = useTranslation('total-early-settlement');
    const [isDownloading, setIsDownloading] = useState(false);
    const { contractId } = useParams();

    const prefix = 'offline-payment';

    const downloadFile = (): void => {
        setIsDownloading(true);
        CpDataApi.get(getEarlySettlementPdfEndpoint(contractId))
            .then(response => {
                const { filename, base64 }: EarlySettlementPdf = response.data;
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(base64, pdfContentType);
                downloadFileAs(pdfBlob, filename);
                setIsDownloading(false);
                setDownloadError(null);
            })
            .catch(() => {
                setIsDownloading(false);
                setDownloadError(true);
            });
    };

    return isDownloading ? (
        <Spinner center small />
    ) : (
        <Button onClick={downloadFile} element="a" icon="download" testId={'earlySettlementPdfDownloadLink'}>
            {t(`${prefix}.button.download`)}
        </Button>
    );
};
