import React from 'react';
import { ConsumerCreditFinancialDetails, formatAsCurrency, undefinedReturnValueHyphen } from '../../../../../common';
import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';

export const VehiclePriceSection: React.FC<{ details: ConsumerCreditFinancialDetails }> = ({ details }) => {
    const { t } = useTranslation('financial-details');

    const { purchasePriceAmount, downPaymentAmount, vehicleValue } = details;

    const definitionListItems: DefinitionListItem[] = [
        {
            tooltip: t('consumer-credit.vehicle-price-section.vehicle-price.tooltip'),
            label: t('consumer-credit.vehicle-price-section.vehicle-price.label'),
            value: formatAsCurrency(purchasePriceAmount, undefinedReturnValueHyphen),
        },
        {
            tooltip: t('consumer-credit.vehicle-price-section.downpayment.tooltip'),
            label: t('consumer-credit.vehicle-price-section.downpayment.label'),
            value: formatAsCurrency(downPaymentAmount, undefinedReturnValueHyphen),
        },
        {
            tooltip: t('consumer-credit.vehicle-price-section.financed-vehicle-value.tooltip'),
            label: t('consumer-credit.vehicle-price-section.financed-vehicle-value.label'),
            value: formatAsCurrency(vehicleValue, undefinedReturnValueHyphen),
        },
    ];

    return (
        <DataOverview withoutCardEffect={true}>
            <DefinitionListHorizontal list={definitionListItems} />
        </DataOverview>
    );
};
