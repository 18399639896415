import React, { useState } from 'react';
import { CpDataApi } from 'cp-xhr';
import { useTranslation } from 'react-i18next';
import { Layout, Button, SectionHeading, Spinner } from '@vwfs-bronson/bronson-react';
import base64ToBlob from 'b64-to-blob';
import { saveAs as downloadFileAs } from 'file-saver';

import {
    AmortizationTableBundledProductsDownloadLinks,
    BundledProductAmortizationTableQueryParameter,
} from '../../../../../common';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';

export const BundledProductsDownloadLinksUi: React.FC<{
    bundledProductsDownloadLinks?: AmortizationTableBundledProductsDownloadLinks[];
}> = ({ bundledProductsDownloadLinks }) => {
    const { t } = useTranslation('amortization-table');
    const { onAction: onDownloadPdfBundledProducts } = useAnalyticsActionTracker('onBundledProductsDownload');
    const { i18n } = useTranslation();
    const [downloadRequest, setDownloadRequest] = useState<string>('');

    if (!bundledProductsDownloadLinks) {
        return null;
    }

    const handleDownload = (link: AmortizationTableBundledProductsDownloadLinks): void => {
        const { downloadEndpoint, fileName } = link;
        setDownloadRequest(downloadEndpoint);
        const currentLanguage = i18n.languages[0];
        const queryParams: BundledProductAmortizationTableQueryParameter = {
            locale: currentLanguage,
        };
        CpDataApi.get(downloadEndpoint, { params: queryParams })
            .then(response => {
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(response.data, pdfContentType);
                downloadFileAs(pdfBlob, `${fileName}.pdf`);
                onDownloadPdfBundledProducts(`Download bundled products ${fileName}`);
                setDownloadRequest('');
            })
            .catch(() => setDownloadRequest(''));
    };

    return (
        <>
            <SectionHeading level={'5'} subtitle={t('content.bundled-products.description')}>
                {t('content.bundled-products.headline')}
            </SectionHeading>
            <Layout className={'u-mt'}>
                {bundledProductsDownloadLinks.map(link => (
                    <Layout.Item key={link.linkName}>
                        {downloadRequest === link.downloadEndpoint ? (
                            <Spinner small testId={`bundledProductSpinner${link.linkName}`} />
                        ) : (
                            <Button
                                onClick={() => handleDownload(link)}
                                testId={`bundledProductLink${link.linkName}`}
                                element="a"
                                icon="download"
                            >
                                {`${link.linkName}.PDF`}
                            </Button>
                        )}
                    </Layout.Item>
                ))}
            </Layout>
        </>
    );
};
