import React from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';
import { getCOSubCategoryNames, getCOSubcategoryTranslationKey } from './categories';
import { SubCategorySelectProps } from './SubCategorySelectProps';

export const COSubCategorySelect: React.FC<SubCategorySelectProps> = ({ selectedCategory, name }) => {
    const { t } = useTranslation('request');

    if (!selectedCategory) {
        return null;
    }

    const subCategories = getCOSubCategoryNames(selectedCategory);

    const subCategoriesSelectItems: ValidatedSelectItem[] = subCategories.map((subCategory: string) => {
        const subCategoryEntryText = t(getCOSubcategoryTranslationKey(selectedCategory, subCategory));
        return {
            label: subCategoryEntryText,
            value: subCategory,
        };
    });

    return (
        <ValidatedSelect
            label={t('form.sub-category.headline')}
            name={name}
            placeholder={t('form.sub-category.placeholder')}
            selectItems={subCategoriesSelectItems}
            testId={'subCategorySelect'}
            disablePlaceholder={true}
        />
    );
};
