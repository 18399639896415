const commonCategoryIds = [
    '3602820a580aed8de9110902a02561c4',
    'acd1c6efad029c81e911095b332cce9f',
    'acd1c6efad02a481e9110d5bc98ddd3a',
    'acd1c6efad02a581e9110e5ba7653a59',
    'acd1c6efad0259b1e91118aa6113e5b7',
];

export const financeCategoryIds = ['3602820a580aed8de9110902a8c35bc4', ...commonCategoryIds];

export const leasingCategoryIds = [
    '3602820a580aed8de9110902007660c4',
    ...commonCategoryIds,
    '3602820a580aed8de9110902b87d69c4',
    '3602820a580aed8de91109023a2d6ac4',
];
