import { AmortizationTableHeader, FetchContractError } from '../../common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import {
    AbstractDataState,
    hasFetchedSuccessfully,
    useGetContractBasedApiData,
} from '@cp-shared-8/frontend-integration';
import { useMemo } from 'react';
import { useContract } from '../contracts';
import { AmortizationTableHeaderDataSelector } from './AmortizationTableHeaderDataSelector';
import { fetchAmortizationTableHeader } from './AmortizationTableHeaderSlice';

export function useAmortizationTableHeader(
    contractId: string,
): AbstractDataState<AmortizationTableHeader, DefaultBusinessMarketApiErrorCode | FetchContractError> {
    const contractState = useContract(contractId);
    const amortizationTableHeaderState = useGetContractBasedApiData(
        contractId,
        fetchAmortizationTableHeader,
        AmortizationTableHeaderDataSelector,
        contractState.data?._links?.amortizationTableHeader,
    );
    return useMemo(
        () => ({
            isLoading: contractState.isLoading || amortizationTableHeaderState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && amortizationTableHeaderState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? amortizationTableHeaderState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: amortizationTableHeaderState.data,
            loadingError: hasFetchedSuccessfully(contractState)
                ? amortizationTableHeaderState.loadingError
                : contractState.loadingError,
        }),
        [contractState, amortizationTableHeaderState],
    );
}
