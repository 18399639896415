import { LeasingContractDetails } from '../../../../../common';
import {
    DataOverview,
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-8/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React from 'react';

export const PaymentSection: React.FC<{ details: LeasingContractDetails }> = ({ details }) => {
    const { firstInstallmentDate, lastDueDate, typeOfPayment } = details;
    const { t, f } = useTranslationWithFormatting('general-contract-details');

    const mandatoryFieldMissing = !firstInstallmentDate || !lastDueDate || !typeOfPayment;

    const sectionFields: DefinitionListItem[] = [
        {
            label: t('payment-section.first-installment-date.leasing.label'),
            value: f(firstInstallmentDate, TranslationFormat.DATE),
            tooltip: t('payment-section.first-installment-date.leasing.tooltip'),
        },
        {
            // TODO: Check if the last Due Date is really needed to be taken here
            label: t('payment-section.last-installment-date.leasing.label'),
            value: f(lastDueDate, TranslationFormat.DATE),
            tooltip: t('payment-section.last-installment-date.leasing.tooltip'),
        },
        {
            label: t('payment-section.type-of-payment'),
            value: typeOfPayment,
        },
    ].filter(item => !!item.value);

    return (
        <DataOverview withoutCardEffect={true}>
            {mandatoryFieldMissing ? (
                <Notification status={NotificationStatus.error} headline={t('error.headline')} text={t('error.text')} />
            ) : (
                <DefinitionListHorizontal list={sectionFields} />
            )}
        </DataOverview>
    );
};
